import React from 'react'
import { View } from 'react-native'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'

import { useNavigation } from '../../Hooks/navigation'

import { PRIMARY_400, PRIMARY_500 } from '../../const'

const CustomerMoneyAccountBalanceIsZeroPopOverScreen: React.FC = () => {
  const { navigate, back } = useNavigation()

  const topUpMoneyAccount = () => navigate('/customer_home/top_up_money_account', { replace: true })

  return <PopOver
    background='success'
    heading={['Недостаточно', 'средств']}
    body={[
      'На Вашем счету 0 рублей!',
      '\r',
      'Пополните счёт, чтобы',
      'перевести деньги',
      'на терминал!',
    ]}
  >
    <View style={{ gap: 8 }}>
      <Button text='Понятно' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={back} />
      <Button text='Пополнить счёт' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={topUpMoneyAccount} />
    </View>
  </PopOver>
}

export default CustomerMoneyAccountBalanceIsZeroPopOverScreen
