import { useCallback } from 'react'
import { useMutation } from 'urql'
import { PointLocksListItem } from '../utils/types'

import { openLockMutation } from '../utils/mutations'

export const useOpenLock = (refetch: () => void): (lock: PointLocksListItem) => void => {
  const [_, openLock] = useMutation(openLockMutation)

  const handleOpenLock = useCallback((lock: PointLocksListItem) => {
    if (!lock.locked) return;

    const variables = { lockId: lock.id }
    openLock(variables).then(result => {
      if (result.error) {
        alert(`Ошибка!\n\n${result.error.message}`)
        return
      }

      refetch()
    })
  }, [])

  return handleOpenLock
}
