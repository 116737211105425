import React from 'react'
import { View, ScrollView, Text, Image } from 'react-native'
import Show from './Show'

import { useThemeColor } from '../Hooks/useThemeColor'

import { PRIMARY_200, PRIMARY_500 } from '../const'

type PopOverProps = React.PropsWithChildren & {
  heading: string[];
  body: string[];
  caption?: string[];
  onPressNavButton?: () => void;
  background?: 'success' | 'bonus' | 'invalid' | 'warning';
}

const PopOver: React.FC<PopOverProps> = ({ children, heading, body, caption = [], background }) => {
  useThemeColor(PRIMARY_500)

  return (
    <View style={{
      flex: 1,
      backgroundColor: PRIMARY_500,
      paddingVertical: 48,
      paddingHorizontal: 24,
    }}>
      <Show condition={background !== undefined}>
        <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: '24%' }}>
          <Image
            source={require(`../assets/popover-${background || 'success'}-bg.png`)}
            style={{ width: '100%', height: '100%' }}
            resizeMode='contain'
          />
        </View>
      </Show>
      <View style={{
        flex: 1,
        width: '100%',
        gap: 40,
      }}>
        <View style={{ marginLeft: 10, marginTop: 48 }}>
          {heading.map((line, idx) => <Text key={idx} style={{ fontSize: 32, fontWeight: '600', lineHeight: 40, color: 'white' }}>{line}</Text>)}
        </View>
        <ScrollView style={{ marginLeft: 10, height: '100%' }}>
          {body.map((line, idx) => <Text key={idx} style={{ fontSize: 23, fontWeight: '400', lineHeight: 32, color: 'white' }}>{line}</Text>)}
        </ScrollView>
      </View>
      <View style={{ marginBottom: 16 }}>
        {caption.map((line, idx) => <Text key={idx} style={{ fontSize: 14, fontWeight: '400', lineHeight: 16, color: PRIMARY_200 }}>{line}</Text>)}
      </View>
      {children}
    </View>
  )
}

export default PopOver
