import React from 'react'
import { View, Text, Image } from 'react-native'
import Scaffold from '../../Components/Scaffold'
import BusyIndicator from '../../Components/BusyIndicator'
import IconButton, { CircleIconButton } from '../../Components/IconButton'
import { RxReload } from 'react-icons/rx'

import { PointControllersQueryResult } from '../../utils/types'
import { PointControllersQuery } from '../../utils/queries'

import { useQuery } from '../../Hooks/useQuery'
import { useParams } from 'react-router-dom'
import { GREEN_500, NEUTRAL_200, PRIMARY_500 } from '../../const'
import { MobileNavbar } from '../../Components/Navbar'

type ControllerItemProp = {
  name: string;
  location: string;
  isOnline: boolean;
}

const ControllerItem: React.FC<ControllerItemProp> = ({ name, location, isOnline }) => {
  const MARGIN = 20
  const STATUS_COLOR = isOnline ? GREEN_500 : PRIMARY_500

  return (
    <>
      <View style={{ marginTop: MARGIN, marginBottom: MARGIN }}>
        <View style={{ flexDirection: 'row', gap: 8, alignItems: 'center', marginBottom: 8 }}>
          <View style={{ backgroundColor: STATUS_COLOR, width: 8, height: '100%', borderRadius: 100 }} />
          <Text style={{ fontSize: 24 }}>{name}</Text>
        </View>
        <Text style={{ fontSize: 16, opacity: 0.64 }}>{location}</Text>
      </View>
      <View style={{ backgroundColor: 'black', width: '100%', height: 1, opacity: 0.2 }} />
    </>
  )
}

const PointControllersScreen: React.FC = () => {
  const { pointId } = useParams()
  const { result, state, error, refetchResult } = useQuery<PointControllersQueryResult>({
    query: PointControllersQuery,
    variables: { pointId: pointId },
  })

  if (state === 'loading') return (
    <Scaffold
      navTitle='Контроллеры'
    >
      <BusyIndicator />
    </Scaffold>
  )

  if (state === 'error' || result.data == null) {
    return <Scaffold
      navTitle='Точка'
      navCaption='Контроллеры'
    >
      <Text>Произошла ошибка</Text>
      <Text>{error}</Text>
    </Scaffold>
  }


  return <Scaffold showNavBar={false} gap={36} >
    <MobileNavbar label={result.data!.point.shortName} caption='Контроллеры' primaryButton='back'>
      <CircleIconButton color={NEUTRAL_200} onPress={refetchResult} >
        <Image
          source={require(`../../assets/refresh-icon.png`)}
          style={{ width: 20, height: 20, tintColor: 'white' }}
        />
      </CircleIconButton>
    </MobileNavbar>
    <View style={{ flex: 1, width: '100%', height: '100%' }}>
      {result.data!.point.controllers.edges.map(controller => (
        <ControllerItem name={controller.node.name} location={controller.node.location} isOnline={controller.node.isOnline} />
      ))}
    </View>
  </Scaffold>
}

export default PointControllersScreen
