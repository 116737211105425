import  { useEffect } from 'react'
import { Dimensions } from 'react-native'

const { width } = Dimensions.get('window')

const useScreenAdjustment = () => {
  useEffect(() => {
    let pageZoom = '100%'

    if (width < 375)
      pageZoom = '75%'
    
    // @ts-ignore
    document.body.style.zoom = pageZoom
  }, [])
}

export default useScreenAdjustment
