import React from 'react'
import { View } from 'react-native'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'

import { useNavigation } from '../../Hooks/navigation'

import { PRIMARY_500 } from '../../const'

export const AuthenticationHelpPopOverScreen: React.FC = () => {
  const { back } = useNavigation()

  const getHelp = () => window.location.href = 'https://t.me/moite_sami'

  return <PopOver
    background='warning'
    heading={['Нужна\nпомощь во время регистрации?']}
    body={[
      `Если у Вас возникли проблемы,\nошибки или сложности по время\nрегистрации в нашей системе -\nобратитесь к нам в телеграмме\nиспользуя кнопку ниже.`
    ]}
  >
    <View style={{ gap: 8 }}>
      <Button text='Нет, продолжить регистрацию' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      <Button text='Обратиться за помощью' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={getHelp} />
    </View>
  </PopOver>
}

export default AuthenticationHelpPopOverScreen
