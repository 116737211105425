import React, { useState, useEffect, useCallback } from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import Scaffold from '../../Components/Scaffold'
import Button from '../../Components/Button'
import Widget from '../../Components/Widget'
import { CircleIconButton } from '../../Components/IconButton'

import { useAuthentication } from '../../Hooks/authentication'
import { useSession } from '../../Hooks/useSession'
import { navigateHome, useNavigation } from '../../Hooks/navigation'
import { useMoneyAccountBalances } from '../../Hooks/moneyAccounts'

import { PRIMARY_100, PRIMARY_500, PRIMARY_600 } from '../../const'
import { MobileNavbar } from '../../Components/Navbar'
import Show from '../../Components/Show'
import PopOver from '../../Components/PopOver'
import { Error } from '../../utils/types'
import { contactUs } from '../../utils/helperFunctions'

type MoneyAccountItemProps = React.PropsWithChildren & {
  title: string;
  caption: string;
}

const MoneyAccountItem: React.FC<MoneyAccountItemProps> = ({ title, caption, children }) => {
  return (
    <Widget>
      <View style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
        <View>
          <Text style={{ fontSize: 24, fontWeight: '500', lineHeight: 24 }}>{title}</Text>
          <Text style={{ fontSize: 16, fontWeight: '400', lineHeight: 24, opacity: 0.64 }}>{caption}</Text>
        </View>
        <View style={{ alignItems: 'center' }}>
          {children}
        </View>
      </View>
    </Widget>
  )
}

const CustomerHomeScreen: React.FC = () => {
  const { navigate, setCurrentStack } = useNavigation()
  const { viewer, updateResult: fetchUserData } = useAuthentication()
  const { session, fetchSessionState, error: sessionError } = useSession()
  const { moneyAccountBalances, fetchMoneyAccountBalances, error: moneyAccountBalancesError } = useMoneyAccountBalances()
  const { customer, bonusProgramForPoor, bonusProgramForRich, selfServiceCarWashBoxCleaning } = moneyAccountBalances
  const [error, setError] = useState<Error>(null)

  const navigateToStartWash = () => navigate('/customer_home/start_wash')
  const navigateToBonusList = () => navigate('/customer_home/bonus_list')
  const navigateToTopUpMoneyAccount = () => navigate('/customer_home/top_up_money_account')
  const navigateToViewerAccount = () => navigate('/viewer_account')
  const getStartWashButtonText = useCallback((): string => session === null ? 'Начать мойку' : 'Продолжить мойку', [session])

  useEffect(() => {
    fetchUserData()
    fetchSessionState()
    fetchMoneyAccountBalances()

    const sessionDataFetchInterval = setInterval(fetchSessionState, 5000)
    return () => clearInterval(sessionDataFetchInterval)
  }, [])

  useEffect(() => {
    setError(sessionError || moneyAccountBalancesError)
  }, [sessionError, moneyAccountBalancesError])

  const viewerIsStaff = viewer?.isStaff || false
  const handleMenuButtonPressed = useCallback(() => {
    if (!viewerIsStaff) {
      navigateToViewerAccount()
      return
    }

    setCurrentStack(['/employee_home'])
  }, [viewer?.isStaff])

  if (error !== null) {
    return <PopOver
      background='invalid'
      heading={['Ошибка']}
      body={[error || 'Не удалось получить данные о текущей сессии, попробуйте позже!']}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={contactUs} />
        <Button text='Перезагрузить страницу' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={navigateHome} />
      </View>
    </PopOver>
  }

  return (
    <Scaffold showNavBar={false} gap={32}>
      <MobileNavbar
        label='Панель клиента'
        primaryButton={viewerIsStaff ? 'menu' : 'user'}
        primaryButtonColor={PRIMARY_100}
        primaryButtonIconColor={PRIMARY_600}
        primaryButtonOnPress={handleMenuButtonPressed}
      >
        <Show condition={viewerIsStaff}>
          <CircleIconButton color={PRIMARY_100} onPress={navigateToViewerAccount} >
            <Image
              source={require(`../../assets/user-icon.png`)}
              style={{ width: 20, height: 20, tintColor: PRIMARY_600 }}
            />
          </CircleIconButton>
        </Show>
      </MobileNavbar>
      <View style={{ width: '100%', gap: 8 }}>
        <Button text={getStartWashButtonText()} onPress={navigateToStartWash} />
        <Button text='Пополнить счёт' variant='tinted' onPress={navigateToTopUpMoneyAccount} />
        <Button text='Связаться с нами' variant='tinted' onPress={contactUs} />
        <TouchableOpacity style={{ backgroundColor: PRIMARY_500, paddingHorizontal: 24, paddingVertical: 16, borderRadius: 8 }} onPress={navigateToBonusList}>
          <Text style={{ fontSize: 16, fontWeight: '500', lineHeight: 24, color: 'white' }}>Каждая вторая мойка бесплатна!</Text>
          <Text style={{ fontSize: 14, fontWeight: '400', lineHeight: 16, color: 'white' }}>Нажмите, чтобы узнать подробнее</Text>
        </TouchableOpacity>
      </View>
      <View style={{ width: '100%', justifyContent: 'flex-start', gap: 16 }}>
        <Text style={{ fontSize: 32, fontWeight: '500', lineHeight: 32 }}>Ваши счета</Text>
        <MoneyAccountItem title='Остаток' caption='Его Вы пополняете'>
          <Text style={{ fontSize: 20, fontWeight: '400', lineHeight: 24, textAlign: 'center' }}>{customer} руб.</Text>
        </MoneyAccountItem>
        <MoneyAccountItem title='Бонусы' caption='"Для нищебродов"'>
          <Text style={{ fontSize: 20, fontWeight: '400', lineHeight: 24, textAlign: 'center' }}>{bonusProgramForPoor || 0} руб.</Text>
        </MoneyAccountItem>
        <MoneyAccountItem title='Бонусы' caption='"Для богатеев"'>
          <Text style={{ fontSize: 20, fontWeight: '400', lineHeight: 24, textAlign: 'center' }}>{bonusProgramForRich || 0} руб.</Text>
        </MoneyAccountItem>
        <Show condition={viewer?.isStaff || false}>
          <MoneyAccountItem title='Фантики' caption='На мойку постов'>
            <Text style={{ fontSize: 20, fontWeight: '400', lineHeight: 24, textAlign: 'center' }}>{selfServiceCarWashBoxCleaning || 0} руб.</Text>
          </MoneyAccountItem>
        </Show>
      </View>
    </Scaffold>
  )
}

export default CustomerHomeScreen
