import React, { useState } from 'react'
import { View, Text } from 'react-native'
import MobileNavbar from '../../Components/Navbar/MobileNavbar'
import Selector from '../../Components/Selector'
import Button from '../../Components/Button'

import { useQuery } from '../../Hooks/useQuery'
import { useNavigation } from '../../Hooks/navigation'

import { PointsForSessionQuery } from '../../utils/queries'
import { PointsForSessionQueryResult } from '../../utils/types'
import { CONTACT_US_PAGE } from '../../const'
import BusyIndicator from '../../Components/BusyIndicator'

const ChoosePointScreen: React.FC = () => {
  const { navigate } = useNavigation()
  const [selectedPointId, setSelectedPointId] = useState<string | null>(null)
  const { result, state } = useQuery<PointsForSessionQueryResult>({ query: PointsForSessionQuery })
  const submit = () => navigate(`/customer_home/start_wash/${selectedPointId}/choose_box`, { replace: true })

  const handleContactUs = () => {
    window.location.href = CONTACT_US_PAGE
  }

  if (state === 'loading') {
    return <View style={{
      flex: 1,
      backgroundColor: 'white',
      paddingVertical: 48,
      paddingHorizontal: 24,
      gap: 128,
    }}>
      <MobileNavbar label='Мойка' caption='Выберите мойку' primaryButton='close' />
      <View style={{ gap: 24 }}>
        <BusyIndicator />
        <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Загрузка</Text>
      </View>
    </View>
  }

  if (result.data === null || result.data === undefined) {
    return <View style={{
      flex: 1,
      backgroundColor: 'white',
      paddingVertical: 48,
      paddingHorizontal: 24,
      gap: 128,
    }}>
      <MobileNavbar label='Мойка' caption='Выберите мойку' primaryButton='close' />
      <View>
        <Text style={{ fontSize: 64, lineHeight: 72, fontWeight: '800', textAlign: 'center', marginBottom: 8 }}>500</Text>
        <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Произошла ошибка!</Text>
      </View>
      <Button text="Связаться с нами" onPress={handleContactUs} style={{ marginTop: 'auto' }} />
    </View>
  }

  const formatPointName = (name: string): string => {
    return `Мойте сами\n${name}`
  }
    
  return (
    <View style={{
      flex: 1,
      backgroundColor: 'white',
      paddingVertical: 48,
      paddingHorizontal: 24,
      gap: 40,
    }}>
      <MobileNavbar label='Мойка' caption='Выберите мойку' primaryButton='close' />
      <Selector
        items={result.data.points.edges.map(({ node: point }) => {
          return { id: point.id, label: formatPointName(point.name), caption: point.address }
        })}
        selectedItemIdxChanged={setSelectedPointId}
      />
      <Button text='Подтвердить' style={{ marginTop: 'auto' }} onPress={submit} isActive={selectedPointId !== null}/>
    </View>
  )
}

export default ChoosePointScreen
