import React, { useEffect } from 'react'
import { View, Text, Dimensions } from 'react-native'
import BusyIndicator from '../../Components/BusyIndicator'
import Button from '../../Components/Button'
import { MobileNavbar } from '../../Components/Navbar'
import PopOver from '../../Components/PopOver'
import Scaffold from '../../Components/Scaffold'
import Widget from '../../Components/Widget'
import { PRIMARY_300, PRIMARY_500 } from '../../const'

import { useQuery } from '../../Hooks/useQuery'
import { useNavigation } from '../../Hooks/navigation'

import { MoneyAccountsAnalyticsQuery } from '../../utils/queries'
import { MoneyAccountAnalytics, MoneyAccountAnalyticsScreenQueryResult as MoneyAccountAnalyticsQueryResult } from '../../utils/types'
import { contactUs } from '../../utils/helperFunctions'

const { width } = Dimensions.get('window')

type MoneyAccountsAnalyticsWidgetProps = {
  label: string;
  yesterdayAnalytics: MoneyAccountAnalytics;
  todayAnalytics: MoneyAccountAnalytics;
}

const MoneyAccountsAnalyticsWidget: React.FC<MoneyAccountsAnalyticsWidgetProps> = ({ label, yesterdayAnalytics, todayAnalytics }) => {
  return (
    <Widget>
      <Text style={{ fontSize: 22, lineHeight: 24, fontWeight: '500' }}>{label}</Text>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '500' }}>За сегодня:</Text>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>Сумма: {todayAnalytics.totalAmount || 0} р.</Text>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>Кол-во: {todayAnalytics.totalCount || 0} шт.</Text>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>С/А: {todayAnalytics.averageAmount || 0} р.</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '500' }}>За вчера:</Text>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>Сумма: {yesterdayAnalytics.totalAmount || 0} р.</Text>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>Кол-во: {yesterdayAnalytics.totalCount || 0} шт.</Text>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>С/А: {yesterdayAnalytics.averageAmount || 0} р.</Text>
        </View>
      </View>
    </Widget>
  )
}

const MoneyAccountsAnalyticsScreen: React.FC = () => {
  const { back } = useNavigation()
  const getToday = () => new Date().toISOString().slice(0, 10)
  const getYesterday = () => {
    const result = new Date()
    result.setDate(result.getDate() - 1)
    return result.toISOString().slice(0, 10)
  }

  const { state, error, result, refetchResult } = useQuery<MoneyAccountAnalyticsQueryResult>({
    query: MoneyAccountsAnalyticsQuery,
    variables: {
      yesterdayTimestamp_Gte: getYesterday() + 'T00:00:00+03:00',
      yesterdayTimestamp_Lte: getYesterday() + 'T23:59:59+03:00',
      todayTimestamp_Gte: getToday() + 'T00:00:00+03:00',
      todayTimestamp_Lte: getToday() + 'T23:59:59+03:00',
    }
  })

  if (error !== null) {
    return <PopOver
      background='invalid'
      heading={['Не удалось загрузить аналитику!']}
      body={[error]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={contactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  if (state === 'success' && (result.data === undefined || result.data === null)) {
    return <PopOver
      background='invalid'
      heading={['Не удалось загрузить аналитику!']}
      body={['Получен некорректный ответ от сервера!']}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={contactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  if (state === 'loading' || result.data === undefined || result.data === null) {
    return <PopOver
      heading={[]}
      body={[]}
    >
      <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', zIndex: -1 }}>
        <BusyIndicator size={width * 1.5} color={PRIMARY_300} />
      </View>
    </PopOver>
  }

  return (
    <Scaffold showNavBar={false} gap={32} >
      <MobileNavbar label='Аналитика' caption={'Денежных\nсчетов'} primaryButton='back' />
      <View style={{ flex: 1, width: '100%', gap: 32 }}>
        <Text style={{ fontSize: 24, lineHeight: 24, fontWeight: '500' }}>Основной счёт</Text>
        <View style={{ gap: 16 }}>
          <MoneyAccountsAnalyticsWidget label='Начисления' todayAnalytics={result.data.todayCustomerCreditStat} yesterdayAnalytics={result.data.yesterdayCustomerCreditStat} />
          <MoneyAccountsAnalyticsWidget label='Перечисления на терминал' todayAnalytics={result.data.todayCustomerUseStat} yesterdayAnalytics={result.data.yesterdayCustomerUseStat} />
        </View>
        <Text style={{ fontSize: 24, lineHeight: 24, fontWeight: '500' }}>Бонусы "Для нищебродов"</Text>
        <View style={{ gap: 16 }}>
          <MoneyAccountsAnalyticsWidget label='Начисления' todayAnalytics={result.data.todayBonusProgramForPoorCreditStat} yesterdayAnalytics={result.data.yesterdayBonusProgramForPoorCreditStat} />
          <MoneyAccountsAnalyticsWidget label='Перечисления на терминал' todayAnalytics={result.data.todayBonusProgramForPoorUseStat} yesterdayAnalytics={result.data.yesterdayBonusProgramForPoorUseStat} />
        </View>
        <Text style={{ fontSize: 24, lineHeight: 24, fontWeight: '500' }}>Бонусы "Для богатеев"</Text>
        <View style={{ gap: 16 }}>
          <MoneyAccountsAnalyticsWidget label='Начисления' todayAnalytics={result.data.todayBonusProgramForRichCreditStat} yesterdayAnalytics={result.data.yesterdayBonusProgramForRichCreditStat} />
          <MoneyAccountsAnalyticsWidget label='Перечисления на терминал' todayAnalytics={result.data.todayBonusProgramForRichUseStat} yesterdayAnalytics={result.data.yesterdayBonusProgramForRichUseStat} />
        </View>
      </View>
    </Scaffold>
  )
}

export default MoneyAccountsAnalyticsScreen
