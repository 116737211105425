import React, { useState, useCallback, useEffect } from 'react'
import { View, Text, Image } from 'react-native'
import Scaffold from '../../Components/Scaffold'
import ToggleItem from '../../Components/ToggleItem'
import BusyIndicator from '../../Components/BusyIndicator'
import IconButton, { CircleIconButton } from '../../Components/IconButton'
import { RxReload } from 'react-icons/rx'

import { useMutation } from 'urql'
import { useParams } from 'react-router-dom'
import { useQuery } from '../../Hooks/useQuery'

import { updatePointMutation } from '../../utils/mutations'
import { UpdatePointMutationResult } from '../../utils/types'
import { PointDetails } from '../../utils/types'
import { PointDetailsQuery } from '../../utils/queries'
import { MobileNavbar } from '../../Components/Navbar'
import { NEUTRAL_200 } from '../../const'

const PointSettingsScreen: React.FC = () => {
  const { pointId } = useParams()
  const [qrCodeState, setQrCodeState] = useState<boolean>(false)
  const [qrCodeToggleActive, setQrCodeToggleActive] = useState<boolean>(false)
  const [_updatePointResult, updatePoint] = useMutation<UpdatePointMutationResult>(updatePointMutation)
  const { result, state, error, refetchResult } = useQuery<{ point: PointDetails }>({
    query: PointDetailsQuery,
    variables: { id: pointId },
  })

  const actions: any = <IconButton onPress={() => refetchResult({ requestPolicy: 'network-only' })}><RxReload size={24} /></IconButton>

  useEffect(() => {
    if (state !== 'success' || result.data === undefined || result.error !== undefined)
      return

    setQrCodeState(result.data!.point.isCashlessPaymentActive)
    setQrCodeToggleActive(true)
  }, [state, result])

  const updateQrCodeState = useCallback(() => {
    setQrCodeToggleActive(false)
    const newState = !qrCodeState

    updatePoint({ id: pointId, data: { isCashlessPaymentActive: newState } }).then(result => {
      console.log(result)
      if (result.data === undefined) {
        // Show error message
        return
      }

      setQrCodeState(result.data.updatePoint.point.isCashlessPaymentActive)
      setQrCodeToggleActive(true)
    })
  }, [qrCodeState, setQrCodeState])

  if (state === 'loading') return (
    <Scaffold
      navTitle='Точка'
      navCaption='Настройки'
      actions={actions}
    >
      <BusyIndicator />
    </Scaffold>
  )

  if (state === 'error' || result.data == null) return (
    <Scaffold
      navTitle='Точка'
      navCaption='Настройки'
      actions={actions}
    >
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text>Произошла ошибка</Text>
        <Text>{error}</Text>
      </View>
    </Scaffold>
  )

  return <Scaffold showNavBar={false} gap={36} >
    <MobileNavbar label={result.data.point.shortName} caption='Контроллеры' primaryButton='back'>
      <CircleIconButton color={NEUTRAL_200} onPress={refetchResult} >
        <Image
          source={require(`../../assets/refresh-icon.png`)}
          style={{ width: 20, height: 20, tintColor: 'white' }}
        />
      </CircleIconButton>
    </MobileNavbar>
    <View style={{ flex: 1, width: '100%' }}>
      <Text style={{ fontSize: 28, lineHeight: 32, fontWeight: '600', marginBottom: 16 }}>Способы оплаты</Text>
      <ToggleItem label='QR-Код' state={qrCodeState} onPress={updateQrCodeState} active={qrCodeToggleActive} />
    </View>
  </Scaffold>
}

export default PointSettingsScreen
