import React, { useState, useEffect } from 'react'
import { View, ScrollView, Text } from 'react-native'
import MobileNavbar from '../../Components/Navbar/MobileNavbar'
import Button from '../../Components/Button'
import SessionAction from '../../Components/SessionAction'

import { useNavigation } from '../../Hooks/navigation'
import { useBonusProgramsConfiguration, useMoneyAccountBalances } from '../../Hooks/moneyAccounts'
import { useSession } from '../../Hooks/useSession'

import { NEUTRAL_100 } from '../../const'

const SessionScreen: React.FC = () => {
  const { navigate, setCurrentStack } = useNavigation()
  const { session, fetchSessionState, state: sessionFetchingState } = useSession()
  const { moneyAccountBalances } = useMoneyAccountBalances()
  const { customer, bonusProgramForRich, bonusProgramForPoor, selfServiceCarWashBoxCleaning } = moneyAccountBalances
  const { bonusProgramsConfiguration } = useBonusProgramsConfiguration()

  const [followSession, setFollowSession] = useState<boolean>(false)

  useEffect(() => {
    fetchSessionState()
    setTimeout(() => {
      setFollowSession(true)
      fetchSessionState()
    }, 1000)

    const sessionDataFetchInterval = setInterval(fetchSessionState, 5000)
    return () => clearInterval(sessionDataFetchInterval)
  }, [])

  useEffect(() => {
    if (!followSession) return

    if (sessionFetchingState === 'success' && session === null)
      setCurrentStack(['/customer_home', '/popovers/wash_session_closed'])
  }, [session, sessionFetchingState])

  const topUpMoneyAccount = () => navigate('/customer_home/top_up_money_account')

  const transferMoneyToCarWashTerminal = () => navigate('/customer_home/start_wash/transfer_money_to_car_wash_terminal/CUSTOMER')

  const washSelfServiceCarWashBox = () => navigate('/customer_home/start_wash/transfer_money_to_car_wash_terminal/SELF_SERVICE_CAR_WASH_BOX_CLEANING')

  const cashCarWashTerminal = () => navigate('/popovers/cash_car_wash_terminal')


  const canUseBonusProgramForPoor = (): boolean => {
    if (bonusProgramsConfiguration === undefined) return false

    const currentHour = new Date().getHours()
    const activeFrom = bonusProgramsConfiguration.bonusProgramForPoor.activeFrom
    const activeTo = bonusProgramsConfiguration.bonusProgramForPoor.activeTo

    if (activeFrom > activeTo)
      return currentHour >= activeFrom || currentHour < activeTo
    else
      return currentHour >= activeFrom && currentHour < activeTo
  }

  const useBonusesForPoor = () => {
    if (bonusProgramsConfiguration === undefined) return

    if (canUseBonusProgramForPoor())
      navigate('/customer_home/start_wash/transfer_money_to_car_wash_terminal/BONUS_PROGRAM_FOR_POOR')
    else
      navigate('/popovers/bonus_program_for_poor_only_at_night')
  }

  const useBonusesForRich = () => navigate('/customer_home/start_wash/transfer_money_to_car_wash_terminal/BONUS_PROGRAM_FOR_RICH')

  const closeWashSession = () => navigate('/popovers/close_wash_session_submition')

  return (
    <View style={{
      flex: 1,
      backgroundColor: 'white',
    }}>
      <View style={{ paddingHorizontal: 24, marginTop: 48, marginBottom: 32 }}>
        <MobileNavbar label='Мойка' primaryButton='back' />
      </View>
      <ScrollView>
        <View style={{ paddingHorizontal: 24, gap: 32 }}>
          <View style={{ gap: 16 }}>
            <Text style={{ fontSize: 28, fontWeight: '500', lineHeight: 32 }}>Пополнить счёт</Text>
            <SessionAction label='Карта' caption='Начисляются бонусы' image='card-icon.png' onPress={topUpMoneyAccount} />
          </View>
          <View style={{ gap: 16 }}>
            <Text style={{ fontSize: 28, fontWeight: '500', lineHeight: 32 }}>Перевести на терминал</Text>
            <SessionAction label='Наличные' image='cash-icon.png' onPress={cashCarWashTerminal} />
            <SessionAction label='Остаток' image='wallet-icon.png' onPress={transferMoneyToCarWashTerminal}>
              <Text style={{ fontSize: 20, fontWeight: '400', lineHeight: 24 }}>{customer || 0} руб.</Text>
            </SessionAction>
            <SessionAction label='Бонусы' caption='"Для богатеев"' image='bonus-icon.png' onPress={useBonusesForRich}>
              <Text style={{ fontSize: 20, fontWeight: '400', lineHeight: 24, textAlign: 'center' }}>{bonusProgramForRich || 0} руб.</Text>
            </SessionAction>
            <SessionAction label='Бонусы' caption='"Для нищебродов"' image='bonus-icon.png' onPress={useBonusesForPoor}>
              <Text style={{ fontSize: 20, fontWeight: '400', lineHeight: 24, textAlign: 'center' }}>{bonusProgramForPoor || 0} руб.</Text>
            </SessionAction>
            <SessionAction label='Фантики' caption='На мойку постов' image='bay-wash-icon.png' onPress={washSelfServiceCarWashBox}>
              <Text style={{ fontSize: 20, fontWeight: '400', lineHeight: 24, textAlign: 'center' }}>{selfServiceCarWashBoxCleaning || 0} руб.</Text>
            </SessionAction>
            <View style={{ height: 16 }} />
          </View>
        </View>
      </ScrollView>
      <View style={{
        paddingHorizontal: 24,
        paddingBottom: 48,
        paddingTop: 8,
      }}>
        <Button text='Закончить мойку' style={{ marginTop: 'auto' }} backgroundColor={NEUTRAL_100} foregroundColor='black' onPress={closeWashSession} />
      </View>
    </View>
  )
}

export default SessionScreen
