import {
  createClient,
  mapExchange,
  dedupExchange,
  cacheExchange,
  fetchExchange,
  makeOperation,
} from "urql"
import { authExchange } from '@urql/exchange-auth'
import { closeUserAuthorizationSessionMutation, refreshAccessTokenMutation } from "./mutations"
import { changeCurrentStack, navigateToNoConnectionScreen } from '../Hooks/navigation'
import { CloseUserAuthorizationSessionMutationResult } from "./types"

export const logout = () => {
  console.log('logout function')
  client.mutation<CloseUserAuthorizationSessionMutationResult>(closeUserAuthorizationSessionMutation, {}).toPromise().then(_ => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    changeCurrentStack(['/authentication/phone_number/'])
  })
}

type AuthState = {
  accessToken: string;
  refreshToken: string;
}

export const client = createClient({
  url: process.env.REACT_APP_HOZYAIN_API_URL || 'none',
  exchanges: [
    dedupExchange,
    cacheExchange,
    mapExchange({
      onError: (error, _operation) => {
        const IsAuthError = error.graphQLErrors.some(e => e.extensions.code == 401)
        if (IsAuthError) {
          logout()
        }
      }
    }),
    authExchange<AuthState>({
      addAuthToOperation: ({ authState, operation }) => {
        if (!authState || !authState.accessToken) {
          return operation
        }

        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {}

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              "Authorization": authState.accessToken,
            },
          },
        })
      },
      getAuth: async ({ authState, mutate }) => {
        if (!authState) {
          const accessToken = localStorage.getItem('accessToken')
          const refreshToken = localStorage.getItem('refreshToken')
          if (accessToken && refreshToken) return { accessToken, refreshToken }
          return null;
        }

        const result = await mutate(refreshAccessTokenMutation, {
          refreshToken: authState.refreshToken
        })
        if (result.data?.refreshAccessToken) {
          localStorage.setItem('accessToken', result.data.refreshAccessToken.accessToken)
          return {
            accessToken: result.data.refreshAccessToken.accessToken,
            refreshToken: authState.refreshToken,
          }
        }

        localStorage.clear();
        logout()

        return null;
      },
      didAuthError: ({ error }) => {
        return error.graphQLErrors.some(e => e.extensions.code == 401)
      },
    }),
    fetchExchange
  ]
})

