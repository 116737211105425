import React from 'react'
import { ActivityIndicator } from 'react-native'
import { PRIMARY_500 } from '../const'

type BusyIndicatorProps = {
  size?: number;
  color?: string;
}

const BusyIndicator: React.FC<BusyIndicatorProps> = ({ size = 80, color = PRIMARY_500 }) => {
  return <ActivityIndicator size={size} color={color} />
}

export default BusyIndicator
