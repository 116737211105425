import React from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'
import { MobileNavbar } from './Navbar'
import Show from './Show'

type ScaffoldProps = React.PropsWithChildren & {
  navTitle?: string;
  navCaption?: string;
  navPrimaryButton?: 'back' | 'close' | undefined;
  showNavBar?: boolean;
  actions?: React.ReactNode;
  gap?: number;
  backgroundColor?: string;
  paddingHorizontal?: number;
  paddingVertical?: number;
}

const Scaffold: React.FC<ScaffoldProps> = ({ children, navTitle, navCaption, actions, gap, showNavBar = true, backgroundColor, navPrimaryButton, paddingHorizontal = 24, paddingVertical = 48 }) => {
  backgroundColor = backgroundColor || 'white'

  return (
    <View style={[styles.container, { backgroundColor: backgroundColor }]}>
      <ScrollView style={{
        flex: 1,
        width: '100%',
        paddingVertical,
        paddingHorizontal,
      }}>
        <Show condition={showNavBar}>
          <MobileNavbar
            label={navTitle || ''}
            caption={navCaption}
            primaryButton={navPrimaryButton}
          >{actions}</MobileNavbar>
        </Show>
        <View style={[styles.innerContainer, { gap: gap }]}>
          {children}
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
  },
  innerContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
})

export default Scaffold
