import React from 'react'
import { View, Text, Image } from 'react-native'
import IconButton from '../../Components/IconButton'
import { RxReload } from 'react-icons/rx'
import BusyIndicator from '../../Components/BusyIndicator'
import Scaffold from '../../Components/Scaffold'
import Show from '../../Components/Show'
import ListItem from '../../Components/ListItem'
import Button from '../../Components/Button'
import { CircleIconButton } from '../../Components/IconButton'
import { MobileNavbar } from '../../Components/Navbar'

import { PointDetails } from '../../utils/types'
import { PointDetailsQuery } from '../../utils/queries'

import { NEUTRAL_200 } from '../../const'
import { useQuery } from '../../Hooks/useQuery'
import { useParams } from 'react-router-dom'
import { useNavigation } from '../../Hooks/navigation'

const PointDetailsScreen: React.FC = () => {
  const { navigate } = useNavigation()
  const { pointId } = useParams()
  const { result, state, error, refetchResult } = useQuery<{ point: PointDetails }>({
    query: PointDetailsQuery,
    variables: { id: pointId },
  })

  const refresh = () => refetchResult({ requestPolicy: 'network-only' })
  const navTitle = result.data?.point.shortName || 'Точка'
  const actions: any = <IconButton onPress={() => refresh()}><RxReload size={24} /></IconButton>

  if (state === 'loading') return (
    <Scaffold
      navTitle={navTitle}
      actions={actions}
    >
      <BusyIndicator />
    </Scaffold>
  )

  if (state === 'error' || result.data == undefined) {
    return <Scaffold showNavBar={false} gap={32}>
      <MobileNavbar label={navTitle} primaryButton='back' />
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text>Произошла ошибка.</Text>
        <Text>{error}</Text>
      </View>
    </Scaffold>
  }

  const showLocks = result.data.point.canViewerOpenLock
  const showCreditCarWashTerminalAction = result.data.point.canViewerCreditCarWashTerminal
  const showControllers = true
  const showSettings = result.data.point.canViewerUpdateThisPoint

  return <Scaffold showNavBar={false} gap={36} >
    <MobileNavbar label={navTitle} primaryButton='back'>
      <CircleIconButton color={NEUTRAL_200} onPress={refresh} >
        <Image
          source={require(`../../assets/refresh-icon.png`)}
          style={{ width: 20, height: 20, tintColor: 'white' }}
        />
      </CircleIconButton>
    </MobileNavbar>
    <Show condition={!showLocks && !showControllers && !showCreditCarWashTerminalAction}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ fontSize: 30, textAlign: 'center' }}>На данной точке Вам</Text>
        <Text style={{ fontSize: 30, textAlign: 'center' }}>ничего не доступно</Text>
      </View>
    </Show>
    <Show condition={showCreditCarWashTerminalAction}>
      <View style={{ width: '100%' }}>
        <Text style={{ fontSize: 28, lineHeight: 32, fontWeight: '600', marginBottom: 16 }}>Действия</Text>
        <Button text='Начислить фантики на пульт' onPress={() => navigate(`/employee_home/points/${pointId}/credit_car_wash_terminal_action`)} />
      </View>
    </Show>
    <Show condition={showLocks || showControllers}>
      <View style={{ width: '100%', gap: 8 }}>
        <Text style={{ fontSize: 28, lineHeight: 32, fontWeight: '600' }}>Разделы</Text>
        <View>
          <Show condition={showLocks}>
            <ListItem label="Замки" onPress={() => navigate(`/employee_home/points/${pointId}/locks`)} />
          </Show>
          <Show condition={showControllers}>
            <ListItem label="Контроллеры" onPress={() => navigate(`/employee_home/points/${pointId}/controllers`)} />
          </Show>
          <Show condition={showSettings}>
            <ListItem label="Найстройки" onPress={() => navigate(`/employee_home/points/${pointId}/settings`)} />
          </Show>
        </View>
      </View>
    </Show>
  </Scaffold >
}

export default PointDetailsScreen
