import React, { useState, useContext } from "react";
import { View, Text, StyleSheet } from "react-native";
import CategoryItem from "../CategoryItem";
import { Sheet } from "react-modal-sheet";

import Button from "../Button";
import PeriodCalendarSheet from "./PeriodCalendar";
import { CardPaymentContext } from "../../Widgets/CardPaymentReportWidget";
import {
  getToday,
  getFirstDayOfMonth,
  getFirstDayOfYear,
  getMonday,
} from "../../utils/helperFunctions";
import { PRIMARY_100_OPACITY, PRIMARY_100, PRIMARY_600 } from "../../const";
import "../../assets/css/calendar-styles.css";

const PeriodSheet: React.FC = () => {
  const [periodCalendarSheetOpen, setPeriodCalendarSheetOpen] =
    useState<boolean>(false);

  const data: any = useContext(CardPaymentContext);
  const { periodSheetOpen, setPeriodSheetOpen, date, setDate } = data;
  const closeSheet = () => setPeriodSheetOpen(false);

  const initalDate = {
    endDate: getToday(),
    startTime: "T00:00:00+03:00",
    endTime: "T23:59:59+03:00",
  };
  return (
    <>
      <PeriodCalendarSheet
        periodCalendarSheetOpen={periodCalendarSheetOpen}
        setPeriodCalendarSheetOpen={setPeriodCalendarSheetOpen}
      />
      <Sheet
        isOpen={periodSheetOpen}
        onClose={() => setPeriodSheetOpen(false)}
        snapPoints={[300]}
        initialSnap={0}
        disableScrollLocking={true}
      >
        <Sheet.Container style={styles.sheetContainer}>
          <Sheet.Content style={styles.sheetContent}>
            <Text style={styles.sheetHeader}>Период отчёта</Text>
            <View style={styles.categoriesContainer}>
              <View style={styles.categoryContainer}>
                <CategoryItem
                  text="За этот день"
                  onPress={() => {
                    setDate({ ...initalDate, startDate: getToday(), type: 0 });
                    closeSheet();
                  }}
                />
                <CategoryItem
                  text="За эту неделю"
                  onPress={() => {
                    setDate({ ...initalDate, startDate: getMonday(), type: 1 });
                    closeSheet();
                  }}
                />
              </View>
              <View style={styles.categoryContainer}>
                <CategoryItem
                  text="За этот месяц"
                  onPress={() => {
                    setDate({
                      ...initalDate,
                      startDate: getFirstDayOfMonth(),
                      type: 2,
                    });
                    closeSheet();
                  }}
                />
                <CategoryItem
                  text="За этот год"
                  onPress={() => {
                    setDate({
                      ...initalDate,
                      startDate: getFirstDayOfYear(),
                      type: 3,
                    });
                    closeSheet();
                  }}
                />
              </View>
              <View style={styles.categoryContainer}>
                <CategoryItem
                  text="Свой период"
                  onPress={() => {
                    closeSheet();
                    setPeriodCalendarSheetOpen(true);
                  }}
                />
              </View>
            </View>
            <Button
              text="Отменить"
              onPress={closeSheet}
              foregroundColor={PRIMARY_600}
              backgroundColor={PRIMARY_100}
              style={styles.button}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop style={styles.backDrop} />
      </Sheet>
    </>
  );
};

const styles = StyleSheet.create({
  sheetContainer: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  sheetContent: {
    padding: "24px",
  },
  sheetHeader: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: 24,
  },
  backDrop: {
    backgroundColor: PRIMARY_100_OPACITY,
  },
  categoriesContainer: {
    flexDirection: "column",
    paddingTop: 24,
    height: 150,
  },
  categoryContainer: {
    flex: 1,
    flexDirection: "row",
    gap: 10,
  },
  button: {
    marginTop: 24,
  },
});

export default PeriodSheet;
