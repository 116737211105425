import React, { useState, useEffect } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useNavigation } from '../../Hooks/navigation'
import { useQuery } from '../../Hooks/useQuery'
import { useMutation } from 'urql'
import { startUserAuthorizationMutation, requestOTPMutation } from '../../utils/mutations'
import { UserAuthorizationInfoQuery, ViewerQuery } from '../../utils/queries'
import { StartUserAuthorizationMutationResult, UserAuthorizationInfoQueryResult as UserAuthorizationInfoQueryResult } from '../../utils/types'

import Button from '../../Components/Button'
import PhoneTextField from '../../Components/PhoneTextField'
import { PRIMARY_500, NEUTRAL_90, PRIMARY_100 } from '../../const'
import BusyIndicator from '../../Components/BusyIndicator'

type AUTHORIZATION_STEP = 'WaitingForInput' | 'WaitingForInfo'

const PhoneNumberScreen: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [_, startUserAuthorization] = useMutation<StartUserAuthorizationMutationResult>(startUserAuthorizationMutation)

  const { navigate } = useNavigation()

  useEffect(() => {
    localStorage.removeItem('phoneNumber')
    localStorage.removeItem('userAuthorizationSessionId')
  }, [])

  const onPhoneNumberChange = (newPhoneNumber: string) => {
    setPhoneNumber(newPhoneNumber)
  }

  const verifyPhoneNumber = (): boolean => {
    const phoneNumberRegex = /^\+[0-9]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/
    return phoneNumberRegex.test(phoneNumber)
  }

  const needHelp = () => navigate('/popovers/authentication_help')

  const submit = () => {
    if (!verifyPhoneNumber()) {
      setErrorMessage('Пожалуйста, введите корректный номер телефона')
      return
    }

    startUserAuthorization({ phoneNumber: phoneNumber }).then(result => {
      console.log('Start user authorization query result:')
      console.log(result)
      if (result.error) {
        if (result.error.graphQLErrors.some(e => e.extensions.code == 400)) {
          setErrorMessage('На данный момент для регистрации можно использовать только номер зарегистрированный в РФ.')
          return
        }

        if (result.error.networkError != undefined) {
          setErrorMessage('Не удаётся подключиться к серверу. Проверьте подключение к интернету, попробуйте перезагрузить страницу или обратитесь к нам за помощью используя кнопку ниже.')
          return
        }

        return 
      }

      if (result.data?.startUserAuthorization.userAuthorizationSessionId === undefined) {
        setErrorMessage('Произошла ошибка при входе в аккаунт. Пожалуйста, обратитесь к нам за помощью!')
      }

      localStorage.setItem('phoneNumber', phoneNumber)
      localStorage.setItem('userAuthorizationSessionId', result.data!.startUserAuthorization.userAuthorizationSessionId)
      navigate('/authentication/push', { replace: true })
    })
  }

  return (
    <View style={styles.container}>
      <View style={{ gap: 16 }}>
        <View>
          <Text style={styles.title}>Введите</Text>
          <Text style={styles.title}>номер телефона</Text>
        </View>
        <View>
          <Text style={styles.subtitle}>Чтобы войти в Личный Кабинет</Text>
          <Text style={styles.subtitle}>"Мойте сами - ЧИСТОСТРАНА"</Text>
        </View>
      </View>
      <View style={styles.form}>
        <PhoneTextField onPhoneNumberChange={onPhoneNumberChange} />
        {
          errorMessage !== null
            ? <Text style={styles.errorMessage}>{errorMessage}</Text>
            : <></>
        }
        <Button text="Войти в ЛК" onPress={submit} />
        <Button text="Мне нужна помощь!" backgroundColor={PRIMARY_100} foregroundColor={PRIMARY_500} onPress={needHelp} />
        <View style={{ alignItems: 'center' }}>
          <Text style={styles.caption}>Вход производиться через сервис Мобильный ID.</Text>
          <Text style={styles.caption}>Ваши данные защищены шифрованием.</Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 96,
    gap: 24,
  },
  title: {
    fontSize: 40,
    lineHeight: 40,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 20,
    lineHeight: 25,
    weight: 300,
    color: NEUTRAL_90,
  },
  form: {
    gap: 8,
  },
  errorMessage: {
    color: PRIMARY_500,
    fontSize: 16,
    lineHeight: 20,
    alignText: 'center',
  },
  caption: {
    fontSize: 12,
    lineHeight: 16,
    color: NEUTRAL_90
  }
})

export default PhoneNumberScreen
