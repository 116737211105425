import React from 'react'
import { View, Text } from 'react-native'
import { styles } from '.'

const SuccessScreen: React.FC = () => {
  return (
    <View style={[styles.textPart, { flex: 1 }]}>
      <Text style={styles.title}>Въезд открыт!</Text>
      <Text style={styles.subtitle}>Наша нейросеть распознала Вас, как клиента нашей мойки. Мы будем рады предоставить Вам наши услуги! Заезжайте!</Text>
    </View>
  )
}

export default SuccessScreen
