import React from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'

type WidgetProps = React.ComponentProps<typeof View> & {
  onPress?: () => void;
}

const Widget: React.FC<WidgetProps> = ({ children, onPress, style }) => {
  if (onPress !== undefined) return (
    <TouchableOpacity style={[styles.widget, style]} onPress={onPress}>
      {children}
    </TouchableOpacity>
  )

  return (
    <View style={[styles.widget, style]}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  widget: {
    backgroundColor: 'white',
    padding: 24,
    borderRadius: 24,
    gap: 8,
    flex: 1,
    shadowRadius: 20,
    shadowOpacity: 0.1,
  }
})

export default Widget
