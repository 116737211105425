import React from 'react'
import DeepLink from '../Components/DeepLink'

import { useParams } from 'react-router-dom'

const PayForCarWashDeepLink = () => {
  const { carWashTerminalId } = useParams()

  return <DeepLink stack={[`/pay_for_car_wash/${carWashTerminalId}`]} />
}

export default PayForCarWashDeepLink
