import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useAuthentication } from '../Hooks/authentication'
import { useNavigation } from '../Hooks/navigation'

const RequireAuth: React.FC = () => {
  const [navigateToStack, setNavigateToStack] = useState<boolean>(false)

  const { authenticated } = useAuthentication()
  const { setCurrentStack } = useNavigation({ navigateAutomatically: navigateToStack })

  useEffect(() => {
    if (!authenticated) {
      setCurrentStack(['/authentication/phone_number'])
      setNavigateToStack(true)
    }
  }, [authenticated])

  return authenticated
    ? <Outlet />
    : <></>
}

export default RequireAuth
