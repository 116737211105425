import React, { useState } from 'react'
import { View, Text, Image } from 'react-native'
import Widget from './Widget'
import Show from './Show'

import { NEUTRAL_100, PRIMARY_500 } from '../const'

export type SelectorItemProps = {
  id: string;
  label: string;
  caption?: string;
  onPress?: () => void;
  selected?: boolean;
}

export const SelectorItem: React.FC<SelectorItemProps> = ({ label, caption, onPress, selected = false }) => {
  const ICON_SIZE = 32

  return (
    <Widget onPress={onPress}>
      <View style={{ flexDirection: 'row', alignItems: 'center', gap: 24 }}>
        <Show condition={selected}>
          <View style={{ width: ICON_SIZE, height: ICON_SIZE, borderRadius: ICON_SIZE, backgroundColor: PRIMARY_500 }}>
            <Image
              source={require('../assets/check-icon.png')}
              style={{
                width: ICON_SIZE,
                height: ICON_SIZE,
              }}
            />
          </View>
        </Show>
        <Show condition={!selected}>
          <View style={{ width: ICON_SIZE, height: ICON_SIZE, borderRadius: ICON_SIZE, backgroundColor: NEUTRAL_100 }} />
        </Show>
        <View>
          <Text style={{ fontSize: 24, fontWeight: '500', lineHeight: 24 }}>{label}</Text>
          <Text style={{ fontSize: 14, fontWeight: '400', lineHeight: 24, opacity: 0.64 }}>{caption}</Text>
        </View>
      </View>
    </Widget>
  )
}

type SelectorProps = {
  items: SelectorItemProps[];
  selectedItemIdxChanged: (newIdx: string | null) => void;
}

const Selector: React.FC<SelectorProps> = ({ items, selectedItemIdxChanged }) => {
  const [selectedItemIdx, setSelectedItemIdx] = useState<string | null>(null)

  const changeSelectedItemIdx = (idx: string | null) => {
    setSelectedItemIdx(idx)
    selectedItemIdxChanged(idx)
  }

  return (
    <View style={{ gap: 16 }}>
      {items.map(item => (
        <SelectorItem 
          {...item} 
          key={item.id}
          onPress={() => changeSelectedItemIdx(item.id)} 
          selected={selectedItemIdx === item.id}
        />
      ))}
    </View>
  )
}

export default Selector
