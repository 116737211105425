import React from 'react'
import { View } from 'react-native'
import { MobileNavbar } from '../../Components/Navbar'
import Scaffold from '../../Components/Scaffold'

const SessionAnalyticsScreen: React.FC = () => {
  return (
    <Scaffold showNavBar={false} gap={32} >
      <MobileNavbar label='Аналитика' caption='Сессий' primaryButton='back' />
      <View style={{ flex: 1, width: '100%', gap: 32 }}>
        <View style={{ gap: 16 }}>
        </View>
      </View>
    </Scaffold>
  )
}

export default SessionAnalyticsScreen
