import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { View, Text, Image, PanResponder, Dimensions } from 'react-native'
import { CircleIconButton } from './IconButton'

import { PRIMARY_300, PRIMARY_400, PRIMARY_500 } from '../const'

const { width } = Dimensions.get('window')
const SLIDER_WIDTH = width - 24 * 2

let lastX = 0

type MoneyAmountSelectorProps = {
  defaultValue?: number;
  minAmount?: number;
  maxAmount?: number;
  setMoneyAmount?: Dispatch<SetStateAction<number>>
}

const MoneyAmountSelector: React.FC<MoneyAmountSelectorProps> = ({ defaultValue = 200, minAmount = 10, maxAmount = 3000, setMoneyAmount }) => {
  const [selectedAmount, setSelectedAmount] = useState<number>(defaultValue);
  const [selectedAmountPercent, setSelectedAmountPercent] = useState<number>(() => Math.abs((selectedAmount / maxAmount * 100) - 100));

  const calculateSelectedAmountPercant = (newAmount: number): number => {
    let newPercent = newAmount / maxAmount * 100
    newPercent = Math.abs(newPercent - 100)
    newPercent = Math.min(newPercent, 100)
    newPercent = Math.max(newPercent, 0)
    return newPercent
  }

  const changeAmount = (by: number) => {
    let newAmount = selectedAmount + by
    newAmount = Math.min(newAmount, maxAmount)
    newAmount = Math.max(newAmount, minAmount)
    newAmount = 10 * Math.round(newAmount / 10)
    setSelectedAmount(newAmount)
    setSelectedAmountPercent(calculateSelectedAmountPercant(newAmount))
  }

  useEffect(() => {
    if (setMoneyAmount !== undefined)
      setMoneyAmount(selectedAmount)
  }, [selectedAmount])

  const panResponder = React.useRef(PanResponder.create({}));

  useEffect(() => {
    const panResponderValue = PanResponder.create({
      onStartShouldSetPanResponder: (_event, _gestureState) => true,
      onMoveShouldSetPanResponder: (_event, _gestureState) => true,
      onPanResponderGrant: (_event, gestureState) => { lastX = gestureState.dx },
      onPanResponderMove: (_event, gestureState) => {
        const deltaX = gestureState.dx - lastX
        const offset = (deltaX / SLIDER_WIDTH) * 100

        let newPercent = 0

        setSelectedAmountPercent(current => {
          let result = current - offset
          result = Math.min(result, 100)
          result = Math.max(result, 0)
          newPercent = result
          return result
        })

        const revercedPercent = Math.abs(newPercent - 100)
        let newAmount = (((maxAmount - minAmount) / 100) * revercedPercent) + minAmount
        newAmount = Math.min(newAmount, maxAmount)
        newAmount = Math.max(newAmount, minAmount)
        newAmount = 10 * Math.round(newAmount / 10)
        setSelectedAmount(newAmount)

        lastX = gestureState.dx
      }
    })

    panResponder.current = panResponderValue
    changeAmount(selectedAmount)
  }, [maxAmount, minAmount])

  return (
    <View style={{ gap: 16 }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', overflow: 'hidden' }}>
        <CircleIconButton color={PRIMARY_500} containerStyle={{ width: 48, height: 48 }} onPress={() => changeAmount(-10)}>
          <Image
            source={require('../assets/minus-icon.png')}
            style={{ width: 24, height: 24, tintColor: 'white' }}
          />
        </CircleIconButton>
        <Text style={{ fontSize: 48, fontWeight: '600', lineHeight: 48, color: 'white' }}>{selectedAmount}р.</Text>
        <CircleIconButton color={PRIMARY_500} containerStyle={{ width: 48, height: 48 }} onPress={() => changeAmount(10)}>
          <Image
            source={require('../assets/plus-icon.png')}
            style={{ width: 24, height: 24, tintColor: 'white' }}
          />
        </CircleIconButton>
      </View>
      <View
        {...panResponder.current.panHandlers}
        style={{
          height: 37,
          backgroundColor: PRIMARY_400,
          borderRadius: 8,
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <Text style={{ position: 'absolute', left: 12, fontSize: 12, fontWeight: '600', lineHeight: 12, color: PRIMARY_300 }}>{minAmount}р.</Text>
        <Text style={{ position: 'absolute', right: 12, fontSize: 12, fontWeight: '600', lineHeight: 12, color: PRIMARY_300 }}>{maxAmount}р.</Text>
        <View style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: `${selectedAmountPercent}%`, backgroundColor: 'white', zIndex: -1 }} />
      </View>
    </View>
  )
}

export default MoneyAmountSelector
