import React, { useState, useEffect } from 'react'

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Provider as URQLProvider } from 'urql'
import { client } from './utils/client';

import RequireAuth from './Components/RequireAuth';
import RequireStaff from './Components/RequireStaff';

import { PhoneNumberScreen, PushScreen, SmsOtpScreen, OtpCodeScreen } from './Screens/Authentication';

import UnknownScreen from './Screens/Unknown';
import UnlockPassageScreen from './Screens/UnlockPassage';
import { HomeScreenRouter, EmployeeHomeScreen, CustomerHomeScreen } from './Screens/Home';
import {
  PointsListScreen,
  PointDetailsScreen,
  PointLocksScreen,
  PointControllersScreen,
  PointCreditCarWashTerminalActionScreen,
  PointCreditCarWashTerminalActionSelectTerminalScreen,
  PointSettingsScreen,
} from './Screens/Points'
import PaymentScreen from './Screens/Payment'
import ViewerAccountScreen from './Screens/ViewerAccount'
import ConfigurationScreen from './Screens/Configuration'
import { AnalyticsScreen, MoneyAccountsAnalyticsScreen, SessionAnalyticsScreen } from './Screens/Analytics'

import {
  BonusesPopOverScreen,
  BonusProgramForPoorPopOverScreen,
  BonusesForPoorOnlyAtNightPopOverScreen,
} from './Screens/PopOver/Bonuses'
import WashStartedPopOverScreen from './Screens/PopOver/WashStarted'
import NoRefundPolicyPopOverScreen from './Screens/PopOver/NoRefundPolicy'
import CustomerMoneyAccountCreditedPopOverScreen from './Screens/PopOver/CustomerMoneyAccountCredited'
import CashCarWashTerminalPopOverScreen from './Screens/PopOver/CashCarWashTerminal'
import CarWashTerminalCreditedPopOverScreen from './Screens/PopOver/CarWashTerminalCredited'
import CustomerMoneyAccountBalanceIsZeroPopOverScreen from './Screens/PopOver/CustomerMoneyAccountIsZero'
import ViewerBonusesPopOverScreen from './Screens/PopOver/ViewerBonuses'
import NoConnectionPopOverScreen from './Screens/PopOver/NoConnection'
import AuthenticationHelpPopOverScreen from './Screens/PopOver/AuthenticationHelp'
import ContactUsPopOverScreen from './Screens/PopOver/ContactUs'

import {
  CloseWashSessionSubmitionPopOverScreen,
  WashSessionClosedPopOverScreen,
} from './Screens/PopOver/CloseWashSession'

import BonusListScreen from './Screens/Bonuses/BonusList'
import BonusDetailsScreen from './Screens/Bonuses/BonusDetails'
import {
  ChoosePointScreen,
  ChooseBoxScreen,
  SessionScreen,
  TransferMoneyToCarWashTerminalScreen,
  StartWashRouter,
} from './Screens/StartWash'

import TopUpMoneyAccountScreen from './Screens/TopUpMoneyAccount'

import CustomerMoneyAccountCreditedDeepLink from './DeepLinks/CustomerMoneyAccountCredited';
import PayForCarWashDeepLink from './DeepLinks/PayForCarWash';

import useScreenAdjustment from './Hooks/useScreenAdjustment'
import { useNavigation } from './Hooks/navigation'

const NavigationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [navigateToStack, setNavigateToStack] = useState<boolean>(false)
  const { navigateHome } = useNavigation({ navigateAutomatically: navigateToStack })

  useEffect(() => {
    /// Handle home route
    if (window.location.pathname === '/')
      navigateHome(true)

    /// If current page is not a deep link, activate automatic navigation to stack
    if (!window.location.pathname.startsWith('/deeplink'))
      setNavigateToStack(true)
  }, [])

  return <>{children}</>
}

const App: React.FC = () => {
  useScreenAdjustment()

  return (
    <URQLProvider value={client}>
      <BrowserRouter>
        <NavigationProvider>

          <Routes>
            {/* Authentication */}
            <Route path='/authentication/phone_number' element={<PhoneNumberScreen />} />
            <Route path='/authentication/push' element={<PushScreen />} />
            <Route path='/authentication/sms_otp' element={<SmsOtpScreen />} />
            {/* Old Authentication */}
            <Route path='/authentication/otp_code' element={<OtpCodeScreen />} /> 

            {/* Public */}
            <Route path='/unlock_passage/:gateId' element={<UnlockPassageScreen />} />
            <Route path='/pay_for_car_wash/:carWashTerminalId' element={<PaymentScreen />} />

            <Route path='/deeplink/pay_for_car_wash/:carWashTerminalId' element={<PayForCarWashDeepLink />} />

            <Route path='/popovers/authentication_help' element={<AuthenticationHelpPopOverScreen />} />
            <Route path='/popovers/contact_us' element={<ContactUsPopOverScreen />} />

            {/* Private */}
            <Route element={<RequireAuth />}>
              <Route path='/' element={<HomeScreenRouter />} />
              <Route path='/viewer_account' element={<ViewerAccountScreen />} />

              <Route path='/customer_home' element={<CustomerHomeScreen />} />
              <Route path='/customer_home/bonus_list' element={<BonusListScreen />} />
              <Route path='/customer_home/bonus_list/:bonusIdx' element={<BonusDetailsScreen />} />

              <Route path='/customer_home/top_up_money_account' element={<TopUpMoneyAccountScreen />} />

              <Route path='/customer_home/start_wash' element={<StartWashRouter />} />
              <Route path='/customer_home/start_wash/choose_point' element={<ChoosePointScreen />} />
              <Route path='/customer_home/start_wash/:selectedPointId/choose_box' element={<ChooseBoxScreen />} />
              <Route path='/customer_home/start_wash/session' element={<SessionScreen />} />
              <Route path='/customer_home/start_wash/transfer_money_to_car_wash_terminal/:transferSource' element={<TransferMoneyToCarWashTerminalScreen />} />

              <Route path='/popovers/bonuses' element={<BonusesPopOverScreen />} />
              <Route path='/popovers/bonus_program_for_poor' element={<BonusProgramForPoorPopOverScreen />} />
              <Route path='/popovers/bonus_program_for_poor_only_at_night' element={<BonusesForPoorOnlyAtNightPopOverScreen />} />

              <Route path='/popovers/close_wash_session_submition' element={<CloseWashSessionSubmitionPopOverScreen />} />
              <Route path='/popovers/wash_session_closed' element={<WashSessionClosedPopOverScreen />} />

              <Route path='/popovers/customer_money_account_credited/:fail/:moneyAmount' element={<CustomerMoneyAccountCreditedPopOverScreen />} />
              <Route path='/popovers/cash_car_wash_terminal' element={<CashCarWashTerminalPopOverScreen />} />
              <Route path='/popovers/car_wash_terminal_credited' element={<CarWashTerminalCreditedPopOverScreen />} />
              <Route path='/popovers/customer_money_account_balance_is_zero' element={<CustomerMoneyAccountBalanceIsZeroPopOverScreen />} />

              <Route path='/popovers/wash_started' element={<WashStartedPopOverScreen />} />
              <Route path='/popovers/no_refund_policy/:destination' element={<NoRefundPolicyPopOverScreen />} />
              <Route path='/popovers/viewer_bonuses/:navigateTo' element={<ViewerBonusesPopOverScreen />} />
              <Route path='/popovers/no_connection' element={<NoConnectionPopOverScreen />} />

              <Route path='/deeplink/customer_money_account_credited/:fail/:moneyAmount' element={<CustomerMoneyAccountCreditedDeepLink />} />

              <Route element={<RequireStaff />}>
                <Route path='/employee_home' element={<EmployeeHomeScreen />} />

                <Route path='/employee_home/configuration' element={<ConfigurationScreen />} />
                <Route path='/employee_home/analytics' element={<AnalyticsScreen />} />
                <Route path='/employee_home/analytics/money_accounts' element={<MoneyAccountsAnalyticsScreen />} />
                <Route path='/employee_home/analytics/session' element={<SessionAnalyticsScreen />} />

                <Route path='/employee_home/points' element={<PointsListScreen />} />
                <Route path='/employee_home/points/:pointId' element={<PointDetailsScreen />} />
                <Route path='/employee_home/points/:pointId/locks' element={<PointLocksScreen />} />
                <Route path='/employee_home/points/:pointId/controllers' element={<PointControllersScreen />} />
                <Route path='/employee_home/points/:pointId/credit_car_wash_terminal_action' element={<PointCreditCarWashTerminalActionSelectTerminalScreen />} />
                <Route path='/employee_home/points/:pointId/credit_car_wash_terminal_action/:carWashTerminalId' element={<PointCreditCarWashTerminalActionScreen />} />
                <Route path='/employee_home/points/:pointId/settings' element={<PointSettingsScreen />} />
              </Route>
            </Route>

            {/* Unknown page */}
            <Route path='*' element={<UnknownScreen />} />
          </Routes>
        </NavigationProvider>
      </BrowserRouter>
    </URQLProvider>
  );
}

export default App;
