import React, { useState } from 'react'
import { View, Text, Dimensions } from 'react-native'
import MobileNavbar from '../../Components/Navbar/MobileNavbar'
import SessionAction from '../../Components/SessionAction'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'
import MoneyAmountSelector from '../../Components/MoneyAmountSelector'
import BusyIndicator from '../../Components/BusyIndicator'

import { useConfiguration } from '../../Hooks/configuration'
import { useNavigation } from '../../Hooks/navigation'
import { useCreditCustomerMoneyAccount } from '../../Hooks/moneyAccounts'

import { PRIMARY_400, PRIMARY_500, CONTACT_US_PAGE, PRIMARY_300 } from '../../const'
import Show from '../../Components/Show'

const { width } = Dimensions.get('window')

const TopUpMoneyAccountScreen: React.FC = () => {
  const { navigate, back } = useNavigation()
  const [creditCustomerMoneyAccount, busy, error] = useCreditCustomerMoneyAccount()
  const { configuration, state } = useConfiguration()

  const navigateToBonusProgramList = () => navigate('/customer_home/bonus_list')
  const navigateBack = () => back()

  const [moneyAmount, setMoneyAmount] = useState<number>(200)

  const handleContactUs = () => {
    window.location.href = CONTACT_US_PAGE
  }

  if (state === 'loading') {
    return <PopOver
      heading={[]}
      body={[]}
    >
      <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', zIndex: -1 }}>
        <BusyIndicator size={width * 1.5} color={PRIMARY_300} />
      </View>
    </PopOver>
  }

  if (error !== null) {
    return <PopOver
      background='invalid'
      heading={['Не удалось', 'пополнить счёт!']}
      body={[error || 'Произошла ошибка, попробуйте позже или обратитесь в тех. поддержку!']}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={handleContactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={navigateBack} />
      </View>
    </PopOver>
  }

  return (
    <View style={{
      flex: 1,
      backgroundColor: 'white',
    }}>
      <View style={{ flex: 1, paddingVertical: 48, paddingHorizontal: 24, gap: 32, overflow: 'scroll' }}>
        <MobileNavbar label='Пополнить счёт' primaryButton='back' />
        <View style={{ gap: 16 }}>
          <Text style={{ fontSize: 28, fontWeight: '500', lineHeight: 32 }}>Источник</Text>
          <SessionAction label='Карта' caption='Начисляются бонусы' image='card-icon.png' />
          <Show condition={!configuration?.canCreditBonusProgramMoneyAccount || false}>
            <Text style={{ fontSize: 28, fontWeight: '500', lineHeight: 32 }}>Объявления</Text>
            <SessionAction label='Бонусы отключены' caption={'Система бонусных программ\nвременно отключена, проводятся\nтехнические работы'} image='bonus-icon.png' />
          </Show>
        </View>
      </View>
      <View style={{ backgroundColor: PRIMARY_500, padding: 24, paddingBottom: 48, gap: 16,
      shadowOffset: { width: 100, height: 100}, shadowColor: '#000000' }}>
        <MoneyAmountSelector setMoneyAmount={setMoneyAmount} />
        <View style={{ gap: 8 }}>
          <Button text='Назад' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={navigateBack} />
          <Show condition={configuration?.canCreditBonusProgramMoneyAccount || false}>
            <Button text='Бонусные программы' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={navigateToBonusProgramList} />
          </Show>
          <Button text='Пополнить счёт' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={() => creditCustomerMoneyAccount(moneyAmount)} busy={busy} />
        </View>
      </View>
    </View>
  )
}

export default TopUpMoneyAccountScreen
