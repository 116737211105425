import React, { useEffect, useState } from 'react'
import { Text, Image } from 'react-native'
import Scaffold from '../../Components/Scaffold'
import { RxReload } from 'react-icons/rx'
import BusyIndicator from '../../Components/BusyIndicator'
import LocksList from '../../Components/LocksList'
import IconButton from '../../Components/IconButton'
import { CircleIconButton } from '../../Components/IconButton'
import { MobileNavbar } from '../../Components/Navbar'

import { PointLocks, PointLocksListItem } from '../../utils/types'
import { PointLocksQuery } from '../../utils/queries'

import { useQuery } from '../../Hooks/useQuery'
import { useOpenLock } from '../../Hooks/useOpenLock'
import { useParams } from 'react-router-dom'
import { NEUTRAL_200 } from '../../const'
import Show from '../../Components/Show'

const PointLocksScreen: React.FC = () => {
  const { pointId } = useParams()
  const { result, refetchResult, state, error } = useQuery<PointLocks>({
    query: PointLocksQuery,
    variables: { id: pointId },
  })
  const [locks, setLocks] = useState<PointLocks['point']['locks'] | null>(null)

  useEffect(() => {
    if (result.data == null) {
      setLocks(null)
      return
    }

    setLocks(result.data.point.locks)
  }, [result.data])

  useEffect(() => {
    const sessionDataFetchInterval = setInterval(() => {
      console.log("update")
      refetchResult({ 'requestPolicy': 'network-only' })
    }, 500)
    return () => clearInterval(sessionDataFetchInterval)
  }, [])

  const openLock = useOpenLock(() => refetchResult({ requestPolicy: 'network-only' }));

  const navTitle = result.data?.point.shortName || 'Точка'
  const navCaption = 'Замки'
  const actions: any = <IconButton onPress={() => refetchResult({ requestPolicy: 'network-only' })}><RxReload size={24} /></IconButton>

  const compareLockIndex = (a: PointLocksListItem, b: PointLocksListItem) => {
    if (a.id < b.id) { return -1; }
    if (a.id > b.id) { return 1; }
    return 0;
  }

  // if (state === 'loading') {
  if (false) {
    return <Scaffold showNavBar={false}>
      <MobileNavbar label={navTitle} caption='Контроллеры' primaryButton='back'>
        <CircleIconButton color={NEUTRAL_200} onPress={refetchResult} >
          <Image
            source={require(`../../assets/refresh-icon.png`)}
            style={{ width: 20, height: 20, tintColor: 'white' }}
          />
        </CircleIconButton>
      </MobileNavbar>
      <BusyIndicator />
    </Scaffold>
  }

  if (state === 'error' || result.data == null) {
    return <Scaffold
      navTitle={navTitle}
      navCaption={navCaption}
      actions={actions}
    >
      <Text>Произошла ошибка</Text>
      <Text>{error}</Text>
    </Scaffold>
  }

  return <Scaffold showNavBar={false} gap={36} >
    <MobileNavbar label={navTitle} caption='Контроллеры' primaryButton='back'>
      <CircleIconButton color={NEUTRAL_200} onPress={() => refetchResult({ 'requestPolicy': 'network-only' })} >
        <Image
          source={require(`../../assets/refresh-icon.png`)}
          style={{ width: 20, height: 20, tintColor: 'white' }}
        />
      </CircleIconButton>
    </MobileNavbar>
    <Show condition={locks == null}>
      <BusyIndicator />
    </Show>
    <Show condition={locks != null}>
      <LocksList
        locks={locks?.edges.map(edge => edge.node).sort(compareLockIndex) || []}
        handleOpenLock={lock => openLock(lock)}
      />
    </Show>
  </Scaffold>
}

export default PointLocksScreen
