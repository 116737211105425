import React from 'react'
import { View } from 'react-native'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'

import { useNavigation } from '../../Hooks/navigation'

import { PRIMARY_500 } from '../../const'

export const NoConnectionPopOverScreen: React.FC = () => {
  const { back } = useNavigation()

  return <PopOver
    background='invalid'
    heading={['Не удаётся', 'подключиться к', 'серверам!']}
    body={[
      'Либо проблема в Вашем',
      'подключении к интернету',
      'либо у наши сервера',
      'временно недоступны.',
      '\n',
      'В случае нашей',
      'ошибки, просим',
      'прощения за', 
      'неудобство!',
    ]}
  >
    <View style={{ gap: 8 }}>
      <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
    </View>
  </PopOver>
}

export default NoConnectionPopOverScreen
