import React, { useEffect } from 'react'
import { View } from 'react-native'
import Button from '../../Components/Button'
import Show from '../../Components/Show'
import PopOver from '../../Components/PopOver'

import { useParams, Navigate } from 'react-router-dom'
import { useNavigation } from '../../Hooks/navigation'
import { useSession } from '../../Hooks/useSession'

import { PRIMARY_500, CONTACT_US_PAGE, PRIMARY_400 } from '../../const'
import BusyIndicator from '../../Components/BusyIndicator'
import { useMoneyAccountBalances } from '../../Hooks/moneyAccounts'

export const CustomerMoneyAccountCreditedPopOverScreen: React.FC = () => {
  const { setCurrentStack } = useNavigation()
  const { fail, moneyAmount } = useParams()
  const { fetchMoneyAccountBalances } = useMoneyAccountBalances()
  const { session, state } = useSession()

  useEffect(() => {
    fetchMoneyAccountBalances()
  }, [])

  const handleContactUs = () => {
    window.location.href = CONTACT_US_PAGE
  }

  const back = () => {
    if (session !== null)
      setCurrentStack(['/customer_home', '/customer_home/start_wash'])
    else
      setCurrentStack(['/customer_home'])
  }

  const showViewerBonuses = (afterAction: 'navigateBack' | 'transferMoneyToCarWashTerminal' | 'navigateHome' | 'startWash') => {
    setCurrentStack([`/popovers/viewer_bonuses/${afterAction}`])
  }

  if (state === 'loading') {
    return <PopOver
      heading={[]}
      body={[]}
    >
      <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', zIndex: -1 }}>
        <BusyIndicator size={48} color='white' />
      </View>
    </PopOver>
  }

  if (fail === 'fail') {
    return <PopOver
      background='invalid'
      heading={['Ошибка', 'пополнения счёта']}
      body={[
        'Пожалуйста, обратитесь',
        'в техническую поддержку',
      ]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={handleContactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }
  else if (fail === 'success') {
    const heading = ['Успех!']
    const sessionBody = moneyAmount === undefined
      ? ['Давайте переведём', 'начисленные средства', 'на терминал!']
      : ['Ваш счёт пополнен', `на ${moneyAmount} рублей!`, '\n', 'Переведите деньги', 'со счёта на терминал,', 'чтобы начать мыть', 'свой прекрасный', 'автомобиль!']
    const noSessionBody = moneyAmount === undefined
      ? ['Ваш счёт пополнен!', '\n', 'Чтобы воспользоваться', 'деньгами на Вашем счету', 'начните мойку!']
      : ['Ваш счёт пополнен', `на ${moneyAmount} рублей!`, '\n', 'Чтобы воспользоваться', 'деньгами на Вашем счету', 'начните мойку!']

    return <PopOver
      background='success'
      heading={heading}
      body={session !== null ? sessionBody : noSessionBody}
    >
      <View style={{ gap: 8 }}>
        <Show condition={session !== null}>
          <Button text='Вернуться в панель клиента' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={() => showViewerBonuses('navigateHome')} />
          <Button text='Вернуться на страницу мойки' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={() => showViewerBonuses('navigateBack')} />
          <Button text='Перевести деньги на терминал' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={() => showViewerBonuses('transferMoneyToCarWashTerminal')} />
        </Show>
        <Show condition={session === null}>
          <Button text='Вернуться в панель клиента' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={() => showViewerBonuses('navigateHome')} />
          <Button text='Начать мойку' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={() => showViewerBonuses('startWash')} />
        </Show>
      </View>
    </PopOver>
  }

  return <Navigate to='/*' />
}

export default CustomerMoneyAccountCreditedPopOverScreen
