import React, { useState, useContext } from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { Sheet, SheetRef } from "react-modal-sheet";
import Calendar from "react-calendar";
// import TimeInput from "material-ui-time-picker";
// import TimePicker from "react-time-picker";

import CategoryItem from "../CategoryItem";
import Button from "../Button";
import { dateToISO, getToday } from "../../utils/helperFunctions";
import "react-calendar/dist/Calendar.css";
import TimeInput from "material-ui-time-picker";
import {
  PRIMARY_100_OPACITY,
  PRIMARY_600,
  PRIMARY_100,
  MONTHS_NORMALIZE,
  CALENDAR_WEEKS,
  WHITE,
} from "../../const";
import { CardPaymentContext } from "../../Widgets/CardPaymentReportWidget";

type PeriodCalendarSheetProps = {
  periodCalendarSheetOpen: boolean;
  setPeriodCalendarSheetOpen: (isOpen: boolean) => void;
};

const TimeInputStartComponent = (params: any) => (
  <input style={styles.timePicker} {...params} value={`От ${params.value}`} />
);

const TimeInputEndComponent = (params: any) => (
  <input style={styles.timePicker} {...params} value={`До ${params.value}`} />
);

type dates = {
  startDate: string;
  endDate: string;
  type: number;
};

type time = {
  startTime: Date;
  endTime: Date;
};

const PeriodCalendarSheet: React.FC<PeriodCalendarSheetProps> = ({
  periodCalendarSheetOpen,
  setPeriodCalendarSheetOpen,
}) => {
  const [currentDate, setCurrentDate] = useState<dates>({
    startDate: getToday(),
    endDate: getToday(),
    type: 0,
  });
  const [currentTime, setCurrentTime] = useState<time>({
    startTime: new Date(),
    endTime: new Date(),
  });
  const [calendarEnd, setCalendarEnd] = useState(new Date());

  const data: any = useContext(CardPaymentContext);
  const { date, setDate } = data;

  const closeSheet = () => setPeriodCalendarSheetOpen(false);

  const hoursWithZeros = (time: Date) => {
    let hours = String(time.getHours());
    return ("0" + hours).slice(-2);
  };

  const minutesWithZeros = (time: Date) => {
    let hours = String(time.getMinutes());
    return ("0" + hours).slice(-2);
  };

  const handleConfirm = () => {
    setDate({
      ...currentDate,
      startTime: `T${hoursWithZeros(currentTime.startTime)}:${minutesWithZeros(
        currentTime.startTime
      )}:00+03:00`,
      endTime: `T${hoursWithZeros(currentTime.endTime)}:${minutesWithZeros(
        currentTime.endTime
      )}:00+03:00`,
      type: 4,
    });
    closeSheet();
  };

  return (
    <Sheet
      isOpen={periodCalendarSheetOpen}
      onClose={() => setPeriodCalendarSheetOpen(false)}
      snapPoints={[700]}
      initialSnap={0}
      style={styles.sheet}
      disableScrollLocking={true}
    >
      <Sheet.Container style={styles.sheetContainer}>
        <Sheet.Content style={styles.sheetContent}>
          <Text style={styles.sheetHeader}>Период отчёта</Text>
          <View>
            <Text style={styles.title}>Дата:</Text>
            <Calendar
              allowPartialRange={true}
              selectRange={true}
              goToRangeStartOnSelect={false}
              defaultActiveStartDate={calendarEnd}
              next2Label={null}
              prev2Label={null}
              returnValue="range"
              formatMonth={(locale, date) => {
                return MONTHS_NORMALIZE[date.getMonth()];
              }}
              formatShortWeekday={(locale, date) => {
                return CALENDAR_WEEKS[date.getDay()];
              }}
              formatMonthYear={(locale, date) => {
                return MONTHS_NORMALIZE[date.getMonth()];
              }}
              calendarType="gregory"
              onChange={(e) => {
                if (e) {
                  const start = Object.values(e)[0];
                  const end = Object.values(e)[1];
                  setCalendarEnd(end);
                  setCurrentDate({
                    ...currentDate,
                    startDate: dateToISO(new Date(start)),
                    endDate: dateToISO(new Date(end)),
                  });
                }
              }}
              tileContent={({ date, view }) => {
                if (view === "year" && date.getMonth() === 0) {
                }
                if (view === "month") {
                }
                return "";
              }}
            />
            <Text style={styles.title}>Время:</Text>
            <View style={styles.timePickersContent}>
              <TimeInput
                mode="24h"
                value={currentTime.startTime}
                onChange={(time: any) =>
                  setCurrentTime({ ...currentTime, startTime: time })
                }
                inputComponent={TimeInputStartComponent}
                classes={{ header: "time-header", body: "time-body" }}
              />
              <TimeInput
                mode="24h"
                value={currentTime.endTime}
                onChange={(time: any) =>
                  setCurrentTime({ ...currentTime, endTime: time })
                }
                inputComponent={TimeInputEndComponent}
                classes={{ header: "time-header", body: "time-body" }}
              />
            </View>
          </View>

          <Button
            text="Подтвердить"
            onPress={handleConfirm}
            style={styles.confirmButton}
          />
          <Button
            text="Отменить"
            onPress={closeSheet}
            foregroundColor={PRIMARY_600}
            backgroundColor={PRIMARY_100}
          />
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop style={styles.backDrop} />
    </Sheet>
  );
};

const styles = StyleSheet.create({
  sheetContainer: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  sheetContent: {
    padding: "24px",
  },
  sheetHeader: {
    fontSize: 32,
    fontWeight: "600",
    lineHeight: 32,
  },
  backDrop: {
    backgroundColor: PRIMARY_100_OPACITY,
  },
  timePickersContent: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 16,
    marginBottom: 24,
  },
  title: {
    fontSize: 24,
    lineHeight: 24,
    fontWeight: "500",
    marginTop: 24,
  },
  confirmButton: {
    backgroundColor: "#D90023",
    marginBottom: 8,
  },
  sheet: {
    zIndex: 10,
  },
  timePicker: {
    backgroundColor: PRIMARY_100,
    outline: "none",
    border: "none",
    boxShadow: "none",
    padding: 16,
    borderRadius: 8,
    textAlign: "center",
    color: PRIMARY_600,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    width: "calc(50% - 8px)",
    boxSizing: "border-box",
  },
});

export default PeriodCalendarSheet;
