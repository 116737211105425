import React, { useCallback } from 'react'
import { View, Text, Pressable } from 'react-native'

import { NEUTRAL_200, PRIMARY_200, PRIMARY_500, WHITE } from '../const'

const TOGGLE_KNOB_SIZE = 32
const TOGGLE_ICON_WIDTH = 3
const TOGGLE_ICON_SIZE = (TOGGLE_KNOB_SIZE / 2) + TOGGLE_ICON_WIDTH
const TOGGLE_PADDING = 4
const TOGGLE_BODY_WIDTH = TOGGLE_KNOB_SIZE * 2 + TOGGLE_PADDING * 2
const TOGGLE_BODY_HEIGHT = TOGGLE_KNOB_SIZE + TOGGLE_PADDING * 2

const FALSE_STATE_BODY_COLOR = NEUTRAL_200
const KNOB_COLOR = WHITE

type ToggleItemProps = {
  label: string;
  state: boolean;
  active?: boolean;
  onPress?: () => void;
}

const ToggleItem: React.FC<ToggleItemProps> = ({ label, state, onPress, active = true }) => {
  const primaryColor = active ? PRIMARY_500 : PRIMARY_200

  const handleOnPress = useCallback(() => {
    if (active && onPress !== undefined)
      onPress()
  }, [active, onPress])

  return (
    <Pressable style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 16 }} onPress={handleOnPress}>
      <Text style={{ fontSize: 24, lineHeight: 24, fontWeight: '400' }}>{label}</Text>
      <View style={{
          width: TOGGLE_BODY_WIDTH,
          height: TOGGLE_BODY_HEIGHT,
          borderRadius: 8,
          padding: TOGGLE_PADDING,
          backgroundColor: state ? primaryColor : FALSE_STATE_BODY_COLOR,
          flexDirection: 'row',
          justifyContent: state ? 'flex-end' : 'flex-start',
        }}>
        <View style={{
          height: TOGGLE_KNOB_SIZE,
          width: TOGGLE_KNOB_SIZE,
          borderRadius: 4,
          backgroundColor: KNOB_COLOR,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <View style={{
            borderRadius: TOGGLE_ICON_SIZE / 2,
            width: state ? 0 : TOGGLE_ICON_SIZE,
            height: TOGGLE_ICON_SIZE,
            borderWidth: state ? TOGGLE_ICON_WIDTH / 2 : TOGGLE_ICON_WIDTH,
            borderColor: primaryColor,
          }}></View>
        </View>
      </View>
    </Pressable>
  )
}

export default ToggleItem
