import { useEffect } from "react";
import { UseQueryState, AnyVariables, useQuery, TypedDocumentNode } from "urql";
import { DocumentNode } from "graphql";

const useRefreshingQuery = <ResponseData>(query: string | DocumentNode | TypedDocumentNode<ResponseData, AnyVariables>, variables: object, delayInSec: number, pause: boolean): UseQueryState<ResponseData, AnyVariables> => {
    const [result, reexecuteQuery] = useQuery({
        query: query,
        variables: variables,
        pause: pause,
    });
    useEffect(() => {
        if (result.fetching) {
            return;
        }
        const timerId = setTimeout(() => {
            reexecuteQuery({ requestPolicy: "network-only" });
        }, delayInSec * 1000);

        return () => clearTimeout(timerId);
    }, [result.fetching, reexecuteQuery, variables]);
    return result;
};

export default useRefreshingQuery;
