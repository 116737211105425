import React, { useState, useEffect } from 'react'
import { View, Text } from 'react-native'
import BusyIndicator from '../../Components/BusyIndicator'
import Scaffold from '../../Components/Scaffold'
import Button from '../../Components/Button'
import TextInput from '../../Components/TextField'
import MoneyAmountSelector from '../../Components/MoneyAmountSelector'
import Show from '../../Components/Show'
import SessionAction from '../../Components/SessionAction'

import { initializePaymentForCarWash } from '../../utils/mutations'
import { useQuery } from '../../Hooks/useQuery'
import { useMutation } from 'urql'
import { useParams } from 'react-router-dom'
import { useConfiguration } from '../../Hooks/configuration'
import { useNavigation } from '../../Hooks/navigation'
import { currentlyAuthenticated } from '../../Hooks/authentication'

import { PRIMARY_500 } from '../../const'
import { CarWashTerminalQuery } from '../../utils/queries'
import { CarWashTerminalQueryResult, InitializePaymentForCarWashMutationResponse } from '../../utils/types'

const PaymentScreen: React.FC = () => {
  const { carWashTerminalId } = useParams()
  const { configuration } = useConfiguration()
  const { setCurrentStack, navigateHome } = useNavigation()

  const [busy, setBusy] = useState<boolean>(false)
  const [creditAmount, setCreditAmount] = useState<number>(200)
  const [error, setError] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)

  const { result, state } = useQuery<{ carWashTerminal: CarWashTerminalQueryResult }>({
    query: CarWashTerminalQuery,
    variables: { id: carWashTerminalId },
  })
  const [_requestOtpResult, initializePayment] = useMutation<InitializePaymentForCarWashMutationResponse>(initializePaymentForCarWash)

  const navTitle = 'Оплата картой'
  const paymentAmountStep = 10

  useEffect(() => {
    if (currentlyAuthenticated() && (configuration?.advertiseBonusProgramsInCarWashPaymentForm || false))
      navigateHome()
  }, [configuration])

  const payForCarWash = () => {
    if (busy) return

    setError(null)
    setBusy(true)
    const emailRegex: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-\.]+$/
    if (email !== null && email !== '' && !emailRegex.test(email)) {
      setError('Введите корректную почту или не вводите совсем!')
      setBusy(false)
      return
    }

    if (creditAmount < paymentAmountStep) {
      setError(`Минимальная сумма оплаты: ${paymentAmountStep} рублей!`)
      setBusy(false)
      return
    }

    initializePayment({ carWashTerminalId, amount: creditAmount, email: email?.trim() }).then(result => {
      if (result.error) {
        setBusy(false)
        if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503) {
          return setError(result.error.graphQLErrors[0].message)
        }
        return setError('Произошла ошибка, попробуйте перезагрузить страницу!')
      }

      window.location.href = result.data!.initializePaymentForCarwash.paymentFormUrl
      setBusy(false)
    })
  }

  if (state === 'loading') return (
    <Scaffold navTitle={navTitle}>
      <BusyIndicator />
    </Scaffold>
  )

  if (state === 'error' || result.data == null || result.data.carWashTerminal == null) return (
    <Scaffold navTitle={navTitle}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ fontSize: 30, textAlign: 'center' }}>Пост не существует</Text>
        <Text style={{ fontSize: 30, textAlign: 'center' }}>Попробуйте ещё раз</Text>
      </View>
    </Scaffold>
  )

  const showBonus: boolean = configuration?.advertiseBonusProgramsInCarWashPaymentForm || false
  const authenticate = () => {
    if (currentlyAuthenticated())
      navigateHome(true)
    else
      setCurrentStack(['/authentication/phone_number'])
  }

  return <Scaffold navTitle={navTitle} gap={36} paddingHorizontal={0}>
    <View style={{ width: '100%', gap: 24, flex: 1 }}>
      <Show condition={showBonus}>
        <View style={{ paddingHorizontal: 24, marginTop: 24 }}>
          <SessionAction label={'Каждая вторая\nмойка - бесплатна!'} caption={'Нажмите, чтобы получить\nбесплатную мойку'} image='bonus-icon.png' onPress={authenticate} />
        </View>
      </Show>
      <View style={{ paddingHorizontal: 24, marginTop: showBonus ? 8 : 24 }}>
        <Text style={{ fontSize: 28, lineHeight: 32, fontWeight: '600' }}>Пост {result.data.carWashTerminal.boxNumber}</Text>
        <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400', opacity: 0.64 }}>{result.data.carWashTerminal.point.address}</Text>
      </View>
      <View style={{ backgroundColor: PRIMARY_500, padding: 24 }}>
        <MoneyAmountSelector setMoneyAmount={setCreditAmount} maxAmount={1500} defaultValue={200} />
      </View>
      <View style={{ paddingHorizontal: 24, gap: 8 }}>
        <TextInput label='Почта для отправки чека' placeholder='youremail@gmail.com' onChangeText={setEmail} />
        <Button text='Оплатить' onPress={payForCarWash} busy={busy} />
        <Show condition={error !== null}>
          <Text style={{ color: PRIMARY_500, fontSize: 16, lineHeight: 20 }}>{error}</Text>
        </Show>
      </View>
    </View>
  </Scaffold >
}

export default PaymentScreen
