import React from 'react'
import { View, Text, Image } from 'react-native'
import Widget from './Widget'

type SessionActionProps = React.PropsWithChildren & {
  label: string;
  caption?: string;
  image: string;
  onPress?: () => void;
}

const SessionAction: React.FC<SessionActionProps> = ({ label, caption, image, onPress, children }) => {
  return (
    <Widget onPress={onPress} style={{ flexDirection: 'row', alignItems: 'center', gap: 24 }}>
      <Image
        source={require(`../assets/${image}`)}
        style={{
          height: 32,
          width: 32,
        }}
      />
      <View>
        <Text style={{ fontSize: 24, fontWeight: '500', lineHeight: 24 }}>{label}</Text>
        <Text style={{ fontSize: 16, fontWeight: '400', lineHeight: 16, opacity: 0.64 }}>{caption}</Text>
      </View>
      <View style={{ marginLeft: 'auto' }}>
        {children}
      </View>
    </Widget>
  )
}

export default SessionAction
