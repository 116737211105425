import React, { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { useUnlockPasssage } from '../../Hooks/unlockPasssage'
import Button from '../../Components/Button'
import { CONTACT_US_PAGE } from '../../const'

import ErrorScreen from './ErrorScreen'
import SuccessScreen from './SuccessScreen'
import AuthorizingScreen from './AuthorizingScreen'

const Wrapper: React.FC<{ children: JSX.Element}> = ({ children }) => {
  const handleContactUs = useCallback(() => {
    window.location.href = CONTACT_US_PAGE
  }, [])

  return (
    <View style={styles.body}>
      <View style={styles.container}>
        {children}
        <Button text="Связаться с нами" onPress={handleContactUs} />
      </View>
    </View>
  )
}

const UnlockPassageScreen: React.FC = () => {
  const { state, error } = useUnlockPasssage()

  if (state === 'error') {
    return (
      <Wrapper>
        <ErrorScreen title={error!.title} subtitle={error!.subtitle} />
      </Wrapper>
    )
  }

  if (state === 'success') {
    return (
      <Wrapper>
        <SuccessScreen />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <AuthorizingScreen />
    </Wrapper>
  )
}

export const styles = StyleSheet.create({
  body: {
    flex: 1,
    justifyContent: 'center', 
    alignItems: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingVertical: 48,
    maxWidth: 1000,
  },
  textPart: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16
  },
  title: {
    fontSize: 36,
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: 40,
  },
  subtitle: {
    fontSize: 24,
    fontWeight: '400',
    textAlign: 'center',
    opacity: 0.64,
    lineHeight: 32,
  }
})

export default UnlockPassageScreen
