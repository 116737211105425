import React from 'react'
import { View } from 'react-native'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'

import { useNavigation } from '../../Hooks/navigation'

import { PRIMARY_500 } from '../../const'

const WashStartedPopOverScreen: React.FC = () => {
  const { navigate } = useNavigation()

  const navigateToRefundPolicy = () => navigate('/popovers/no_refund_policy/session', { replace: true })

  return <PopOver
    background='success'
    heading={['Мойка началась!']}
    body={[
      'Для оплаты мойки Вы',
      'можете:',
      '\n',
      '- внести наличные',
      'деньги в терминал или',
      '- положить их на Ваш',
      'счёт в приложении.',
      '\n',
      'Бонусные программы',
      'работают только при',
      'пополнении счёта в',
      'приложении.',
    ]}
  >
    <View style={{ gap: 8 }}>
      <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={navigateToRefundPolicy}/>
    </View>
  </PopOver>
}

export default WashStartedPopOverScreen
