import React from 'react'
import { StyleProp, ViewStyle, TouchableOpacity } from 'react-native'

type IconButtonProps = {
  children: React.ReactNode;
  onPress?: () => void;
  containerStyle?: StyleProp<ViewStyle> | undefined;
}

const IconButton: React.FC<IconButtonProps> = ({ children, onPress, containerStyle }) => {
  return (
    <TouchableOpacity style={[{ justifyContent: 'center', alignItems: 'center'}, containerStyle ]} onPress={onPress}>
      {children}
    </TouchableOpacity>
  )
}

type CircleIconButtonProps = IconButtonProps & {
  color: string;
}

const CircleIconButton: React.FC<CircleIconButtonProps> = ({ children, onPress, color, containerStyle }) => {
  return <IconButton onPress={onPress} containerStyle={[{
    backgroundColor: color,
    borderRadius: 32,
    height: 40,
    width: 48,
    justifyContent: 'center',
    alignItems: 'center',
  }, containerStyle ]}>
    {children}
  </IconButton>
}

export { CircleIconButton }
export default IconButton
