import React from 'react'
import { View, Text } from 'react-native'
import { styles } from '.'
import BusyIndicator from '../../Components/BusyIndicator'

const AuthorizingScreen: React.FC = () => {
  return (
    <View style={{ flex: 1, justifyContent: 'center', gap: 56 }}>
      <View style={styles.textPart}>
        <Text style={styles.title}>Подождите секундочку</Text>
        <Text style={styles.subtitle}>Пока наш менеджер санкционирует открытие въезда</Text>
      </View>
      <BusyIndicator />
    </View>
  )
}

export default AuthorizingScreen
