import React, { useState, useEffect } from 'react'
import { Dimensions } from 'react-native'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'

import { useNavigation } from '../../Hooks/navigation'
import { useSession } from '../../Hooks/useSession'
import { useBonusProgramsState, useMoneyAccounts, useMoneyAccountBalances, useBonusProgramsConfiguration } from '../../Hooks/moneyAccounts'
import { useParams } from 'react-router-dom'
import { useMutation } from 'urql'

import { PRIMARY_300, PRIMARY_500 } from '../../const'
import { SetUserNotifiedAboutMoneyAccountChangesMutationResult, ViewerMoneyAccountsQueryMoneyAccount } from '../../utils/types'
import { SetUserNotifiedAboutMoneyAccountChangesMutation } from '../../utils/mutations'
import BusyIndicator from '../../Components/BusyIndicator'

const { width } = Dimensions.get('window')

const ViewerBonusesPopOverScreen: React.FC = () => {
  const { session } = useSession()
  const { setCurrentStack, navigateHome } = useNavigation()
  const { navigateTo } = useParams()
  const { moneyAccounts, fetchMoneyAccounts, state: moneyAccountsFetchingState } = useMoneyAccounts()
  const { moneyAccountBalances, fetchMoneyAccountBalances, state: moneyAccountBalancesFetchingState } = useMoneyAccountBalances()
  const { bonusProgramsState, fetchBonusProgramsState, state: bonusProgramsStateFetchingState } = useBonusProgramsState()
  const { bonusProgramsConfiguration, fetchBonusProgramsConfiguration, state: bonusProgramsConfigurationFetchingState } = useBonusProgramsConfiguration()

  const [bonusMoneyAccounts, setBonusMoneyAccounts] = useState<ViewerMoneyAccountsQueryMoneyAccount[]>([])
  const [currentBonusProgramShowingIndex, setCurrentBonusProgramShowingIndex] = useState<number>(0)
  const [state, setState] = useState<'loading' | 'showingBonusPrograms'>('loading')

  const [heading, setHeading] = useState<string[]>([])
  const [body, setBody] = useState<string[]>([])
  const [caption, setCaption] = useState<string[] | undefined>()

  const [_, setUserNotifiedMoneyAccountChanges] = useMutation<SetUserNotifiedAboutMoneyAccountChangesMutationResult>(SetUserNotifiedAboutMoneyAccountChangesMutation)

  const startWash = () => setCurrentStack(['/customer_home', '/customer_home/start_wash'])

  const navigateBack = () => {
    if (session !== null)
      setCurrentStack(['/customer_home', '/customer_home/start_wash'])
    else
      setCurrentStack(['/customer_home'])
  }

  const transferMoneyToCarWashTerminal = () => {
    if (session !== null)
      setCurrentStack(['/customer_home', '/customer_home/start_wash/session', '/customer_home/start_wash/transfer_money_to_car_wash_terminal/CUSTOMER'])
    else
      setCurrentStack(['/customer_home'])
  }

  const handleSubmit = () => {
    if (currentBonusProgramShowingIndex < bonusMoneyAccounts.length - 1) {
      setCurrentBonusProgramShowingIndex(current => current + 1)
      return
    }

    setUserNotifiedMoneyAccountChanges({ moneyAccountIds: moneyAccounts.map(moneyAccounts => moneyAccounts.id) })

    if (navigateTo === 'transferMoneyToCarWashTerminal')
      transferMoneyToCarWashTerminal()
    else if (navigateTo === 'navigateBack')
      navigateBack()
    else if (navigateTo === 'startWash')
      startWash()
    else
      navigateHome()
  }

  useEffect(() => {
    fetchBonusProgramsConfiguration()
    fetchBonusProgramsState()
    fetchMoneyAccountBalances()
    fetchMoneyAccounts()
  }, [])

  useEffect(() => {
    console.log('moneyAccounts', moneyAccounts)
    if (moneyAccounts.length === 0)
      return

    const bonusProgramMoneyAccounts = moneyAccounts.filter(moneyAccount => moneyAccount.bonusAccount)
    console.log('bonusProgramMoneyAccounts', bonusProgramMoneyAccounts)
    setState('showingBonusPrograms')
    if (bonusProgramMoneyAccounts.length === 0) {
      handleSubmit()
      return
    }

    setBonusMoneyAccounts(moneyAccounts.filter(moneyAccount => moneyAccount.bonusAccount))
  }, [moneyAccounts])

  useEffect(() => {
    if (
      moneyAccountsFetchingState === 'success'
      && moneyAccountBalancesFetchingState === 'success'
      && bonusProgramsStateFetchingState === 'success'
      && bonusProgramsConfigurationFetchingState === 'success'
    ) {
      setState('showingBonusPrograms')
    }
  }, [moneyAccountsFetchingState, moneyAccountBalancesFetchingState, bonusProgramsStateFetchingState, bonusProgramsConfigurationFetchingState])

  useEffect(() => {
    console.log('bonusProgramsConfiguration ===', bonusProgramsConfiguration)
  }, [bonusProgramsConfiguration])

  useEffect(() => {
    if (state === 'loading') return

    const currentBonusProgramShowing = bonusMoneyAccounts.at(currentBonusProgramShowingIndex)
    console.log('currentBonusProgramShowingIndex', currentBonusProgramShowingIndex)
    console.log('currentBonusProgramShowing', currentBonusProgramShowing)
    console.log('bonusProgramsConfiguration', bonusProgramsConfiguration)
    console.log('bonusProgramsState', bonusProgramsState)
    if (
      currentBonusProgramShowing === undefined
      || moneyAccounts === undefined
      || moneyAccountBalances === undefined
      || bonusProgramsState === undefined
      || bonusProgramsConfiguration === undefined
    ) return

    if (currentBonusProgramShowing.type === 'BONUS_PROGRAM_FOR_RICH') {
      setHeading([])
      setBody([])
      setCaption([])

      if (bonusProgramsState?.bonusProgramForRich.bonusesCredited === 0)
        setHeading(['Получите 100% -', 'не годовых, а секундных!'])
      else
        setHeading(['Вы получили', `${bonusProgramsState?.bonusProgramForRich.currentStepBonusPercent}% секундных -`, `${bonusProgramsState?.bonusProgramForRich.bonusesCredited} бонусных рублей!`])

      if (bonusProgramsState.bonusProgramForRich.bonusesCredited !== 0) {
        setBody([
          `За сегодня Вы внесли\nна счёт ${bonusProgramsState.bonusProgramForRich.moneyCredited} рублей и\nполучили ${bonusProgramsState.bonusProgramForRich.bonusesCredited} бонусных\nрублей по бонусной\nпрограмме "Для богатеев".\n\n`,
        ])
      }
      if (bonusProgramsState?.bonusProgramForRich.nextStepBonusPercent !== null && bonusProgramsState?.bonusProgramForRich.nextStepMoneyAmountDistance !== null) {
        setBody(body => [...body,
        `Внесите ещё ${bonusProgramsState?.bonusProgramForRich.nextStepMoneyAmountDistance} рублей,`,
          'чтобы перейти на новую',
        `ступеньку - ${bonusProgramsState?.bonusProgramForRich.nextStepBonusPercent}% секундных`,
          'и сразу же получите',
        `${((bonusProgramsState?.bonusProgramForRich.moneyCredited || 0) + (bonusProgramsState?.bonusProgramForRich.nextStepMoneyAmountDistance || 0)) * (bonusProgramsState?.bonusProgramForRich.nextStepBonusPercent || 0) / 100} бонусных рублей!`
        ])
      }
      else {
        setBody(body => [...body,
          'Продолжайте вносить',
          'деньги на счёт, чтобы',
          `получать ${bonusProgramsState?.bonusProgramForRich.currentStepBonusPercent}% бонусных рублей секундных!`,
          '\n',
          'После полуночи',
          'Вы перейдёте обратно',
          'на первую ступеньку!'
        ])
      }
    }

    if (currentBonusProgramShowing.type === 'BONUS_PROGRAM_FOR_POOR') {
      setHeading([])
      setBody([])
      setCaption([])

      // if ((moneyAccountBalances.customer || 0) === (bonusProgramsConfiguration?.bonusProgramForPoor.initialCreditAmount || 0)) {
      if (bonusProgramsState.bonusProgramForPoor.hasBonusCarWash && bonusProgramsState.bonusProgramForPoor.stepMoneyAmountDistance === bonusProgramsConfiguration.bonusProgramForPoor.initialCreditAmount) {
        setHeading(['Вы получили', 'бесплатную мойку!'])
        setBody([
          'Приезжайте уже этой',
          'ночью и помойте свою',
          'машину совершенно',
          'бесплатно!',
          '\n',
          'Для бесплатной мойки',
          'на терминал начисляется',
          `${moneyAccountBalances.bonusProgramForPoor} рублей.`,
          '\n',
          'Пополняйте счёт ещё на',
          `${bonusProgramsState?.bonusProgramForPoor.stepMoneyAmountDistance} рублей, чтобы увеличивать`,
          'сумму, начисляемую на терминал на 50 рублей!',
        ])
      }
      else if ((moneyAccountBalances.customer || 0) > (bonusProgramsConfiguration?.bonusProgramForPoor.initialCreditAmount || 0)) {
        setHeading(['У Вас есть', 'бесплатная ночная', 'мойка!'])
        setBody([
          'Приезжайте уже этой',
          'ночью и помойте свою',
          'машину совершенно',
          'бесплатно!',
          '\n',
          'Для бесплатной мойки',
          'на терминал начисляется',
          `${moneyAccountBalances.bonusProgramForPoor} рублей.`,
          '\n',
          'Пополняйте счёт ещё на',
          `${bonusProgramsState?.bonusProgramForPoor.stepMoneyAmountDistance} рублей, чтобы увеличивать`,
          'сумму, начисляемую на терминал на 50 рублей!',
        ])
      }
      else {
        setHeading(['Каждая вторая', 'мойка бесплатна!'])
        setBody([
          'Пополните счёт до конца', `суток ещё на ${bonusProgramsState?.bonusProgramForPoor.stepMoneyAmountDistance} рублей,`, 'чтобы получить бесплатную ночную мойку!',
        ])
      }
    }
  }, [currentBonusProgramShowingIndex, state, moneyAccounts, moneyAccountBalances, bonusProgramsState, bonusProgramsConfiguration])

  if (state === 'loading') {
    return (
      <PopOver
        background='bonus'
        heading={[]}
        body={[]}
      >
        <BusyIndicator size={width * 1.5} color={PRIMARY_300} />
      </PopOver>
    )
  }

  return (
    <PopOver
      background='bonus'
      heading={heading}
      body={body}
      caption={caption}
    >
      <Button
        text='Понятно'
        onPress={handleSubmit}
        backgroundColor='white'
        foregroundColor={PRIMARY_500}
      />
    </PopOver>
  )
}

export default ViewerBonusesPopOverScreen
