import { useState, useEffect } from 'react'
import { useAuthentication } from './authentication'
import { useMutation } from 'urql'
import { useQuery } from './useQuery'

import { useForLoop } from '../utils/helperFunctions'

import { TransferMoneyToCarWashTerminalMutation, CreditCustomerMoneyAccountMutation } from '../utils/mutations'
import { BonusProgramsConfigurationQuery, CarWashTerminalCreditStateQuery, ViewerBonusProgramsStateQuery, ViewerMoneyAccountsQuery } from '../utils/queries'
import {
  TransferMoneyToCarWashTerminalMutationResult,
  CarWashTerminalCreditStateQueryResult,
  CreditCustomerMoneyAccountMutationResult,
  ViewerMoneyAccountsQueryResult,
  MoneyAccountType,
  ViewerMoneyAccountsQueryMoneyAccount,
  ViewerBonusProgramsStateQueryResult,
  BonusProgramsState,
  BonusProgramsConfigurationQueryResult,
  RequestState,
  Error,
} from '../utils/types'

export type UseTransferMoneyToCarWashTerminalResult = [
  (moneyAmount: number, carWashTerminalId: string) => void,
  boolean,
  Error,
]

export const useTransferMoneyToCarWashTerminal = (
  handleResult: (result: TransferMoneyToCarWashTerminalMutationResult) => void,
  moneyAccountType?: MoneyAccountType,
): UseTransferMoneyToCarWashTerminalResult => {
  const { viewer } = useAuthentication()
  const [busy, setBusy] = useState<boolean>(false)
  const [error, setError] = useState<Error>(null)
  const [_, sendMutation] = useMutation<TransferMoneyToCarWashTerminalMutationResult>(TransferMoneyToCarWashTerminalMutation)

  const execute = (moneyAmount: number, carWashTerminalId: string) => {
    const moneyAccountId = viewer?.moneyAccounts.edges.find(edge => edge.node.type === moneyAccountType)?.node.id
    if (moneyAccountId === undefined) {
      setError('Ошибка авторизации, попробуйте ещё раз')
      return
    }

    setBusy(true)
    sendMutation({
      moneyAccountId,
      amount: moneyAmount,
      carWashTerminalId,
    }).then(result => {
      if (result.error) {
        setBusy(false)
        if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503 || result.error.graphQLErrors[0].extensions.code == 403)
          return setError(result.error.graphQLErrors[0].message)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }
      else if (result.data === undefined) {
        setBusy(false)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }
      setBusy(false)

      handleResult(result.data)
    })
  }

  return [execute, busy, error]
}

export type UseEnsureCarWashTerminalCreditSucceededState = 'inProgress' | 'success' | 'error'

export type UseEnsureCarWashTerminalCreditSucceededResult = [
  () => void,
  UseEnsureCarWashTerminalCreditSucceededState,
  Error,
]

export const useEnsureCarWashTerminalCreditSucceeded = (carWashTerminalCreditId?: string, maxCheckCount: number = 120, intervalMs: number = 1000): UseEnsureCarWashTerminalCreditSucceededResult => {
  const { result, refetchResult } = useQuery<CarWashTerminalCreditStateQueryResult>({
    query: CarWashTerminalCreditStateQuery,
    pause: true,
    variables: { carWashTerminalCreditId }
  })
  const { fetchMoneyAccountBalances } = useMoneyAccountBalances()
  const [state, setState] = useState<UseEnsureCarWashTerminalCreditSucceededState>('inProgress')
  const [error, setError] = useState<Error>(null)
  const [executeLoop, breakLoop, loopExecuted] = useForLoop({
    iterCount: maxCheckCount,
    timeout: intervalMs,
    handler: () => {
      refetchResult({ requestPolicy: 'network-only' })
    },
    afterLoopHandler: () => {
      if (state !== 'inProgress')
        return

      setError('Не удалось подтвердить статус перевода.')
      setState('error')
    },
  })

  useEffect(() => {
    if (result.fetching)
      return

    if (result.error !== undefined) {
      breakLoop()
      if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503 || result.error.graphQLErrors[0].extensions.code == 403)
        return setError(result.error.graphQLErrors[0].message)
      return setError('Неизвестная ошибка, попробуйте ещё раз!')
    }
    else if (result.data === undefined && result.operation !== undefined) {
      breakLoop()
      return setError('Получен непонятный ответ от сервера, обратитесь в техническую поддержку!')
    }
    else if (result.data === undefined)
      return

    if (error !== null) {
      breakLoop()
      setState('error')
      return
    }

    if (result.data?.carWashTerminalCredit.status === 'CANCELED') {
      breakLoop()
      setError('Операция была отменена, попробуйте ещё раз!')
      setState('error')
      return
    }
    else if (result.data?.carWashTerminalCredit.status === 'ACCEPTED' || result.data?.carWashTerminalCredit.status === 'COMPLETED') {
      breakLoop()
      setState('success')
    }

  }, [result, result.fetching])

  useEffect(() => {
    fetchMoneyAccountBalances()
  }, [state])

  const execute = () => {
    if (!loopExecuted)
      executeLoop()
  }

  return [execute, state, error]
}

export type UseCreditCustomerMoneyAccountResult = [
  (amount: number) => void,
  boolean,
  Error,
]

export const useCreditCustomerMoneyAccount = (): UseCreditCustomerMoneyAccountResult => {
  const [busy, setBusy] = useState<boolean>(false)
  const [error, setError] = useState<Error>(null)
  const [_, sendMutation] = useMutation<CreditCustomerMoneyAccountMutationResult>(CreditCustomerMoneyAccountMutation)

  const execute = (amount: number) => {
    setBusy(true)
    const successUrl = `${window.location.origin}/deeplink/customer_money_account_credited/success/${amount}`
    const failUrl = `${window.location.origin}/deeplink/customer_money_account_credited/fail/${amount}`

    sendMutation({ amount, successUrl, failUrl }).then(result => {
      if (result.error) {
        setBusy(false)
        if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503 || result.error.graphQLErrors[0].extensions.code == 403)
          return setError(result.error.graphQLErrors[0].message)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }
      else if (result.data === undefined) {
        setBusy(false)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }

      window.location.href = result.data.creditCustomerMoneyAccount.paymentFormUrl
    })

  }

  return [execute, busy, error]
}

export type UseMoneyAccountsResult = {
  moneyAccounts: ViewerMoneyAccountsQueryMoneyAccount[],
  fetchMoneyAccounts: () => void,
  state: RequestState,
  error: Error,
}

export const useMoneyAccounts = (): UseMoneyAccountsResult => {
  const { result, refetchResult, state, error } = useQuery<ViewerMoneyAccountsQueryResult>({ query: ViewerMoneyAccountsQuery })

  const [moneyAccounts, setMoneyAccounts] = useState<ViewerMoneyAccountsQueryMoneyAccount[]>([])

  const getMoneyAccount = (moneyAccountType: MoneyAccountType): ViewerMoneyAccountsQueryMoneyAccount | undefined => {
    return result.data?.viewer.moneyAccounts.edges.find(edge => edge.node.type === moneyAccountType)?.node || undefined
  }

  useEffect(() => {
    if (state !== 'success') return

    setMoneyAccounts(() => {
      const result: ViewerMoneyAccountsQueryMoneyAccount[] = []

      const bonusProgramForPoorMoneyAccount = getMoneyAccount('BONUS_PROGRAM_FOR_POOR')
      if (bonusProgramForPoorMoneyAccount !== undefined)
        result.push(bonusProgramForPoorMoneyAccount)

      const bonusProgramForRichMoneyAccount = getMoneyAccount('BONUS_PROGRAM_FOR_RICH')
      if (bonusProgramForRichMoneyAccount !== undefined)
        result.push(bonusProgramForRichMoneyAccount)

      return result
    })
  }, [result, result.fetching])

  return {
    moneyAccounts,
    fetchMoneyAccounts: () => refetchResult({ requestPolicy: 'network-only' }),
    error,
    state
  }
}

export type MoneyAccountBalances = { customer?: number; bonusProgramForPoor?: number; bonusProgramForRich?: number; washer?: number; selfServiceCarWashBoxCleaning?: number; };

export type UseMoneyAccountBalancesResult = {
  moneyAccountBalances: MoneyAccountBalances,
  fetchMoneyAccountBalances: () => void,
  state: RequestState,
  error: Error,
}

export const useMoneyAccountBalances = (): UseMoneyAccountBalancesResult => {
  const { result, refetchResult, state, error } = useQuery<ViewerMoneyAccountsQueryResult>({ query: ViewerMoneyAccountsQuery })

  const [moneyAccountBalances, setMoneyAccountBalances] = useState<MoneyAccountBalances>({})

  const getMoneyAccountBalance = (moneyAccountType: MoneyAccountType): number | undefined => {
    return result.data?.viewer.moneyAccounts.edges.find(edge => edge.node.type === moneyAccountType)?.node.balance || undefined
  }

  useEffect(() => {
    if (state !== 'success') return
    console.log(result.data)

    setMoneyAccountBalances({
      customer: getMoneyAccountBalance('CUSTOMER') || 0,
      bonusProgramForPoor: getMoneyAccountBalance('BONUS_PROGRAM_FOR_POOR') || 0,
      bonusProgramForRich: getMoneyAccountBalance('BONUS_PROGRAM_FOR_RICH') || 0,
      washer: getMoneyAccountBalance('WASHER') || 0,
      selfServiceCarWashBoxCleaning: getMoneyAccountBalance('SELF_SERVICE_CAR_WASH_BOX_CLEANING') || 0,
    })
  }, [state, result])

  return {
    moneyAccountBalances,
    fetchMoneyAccountBalances: () => refetchResult({ requestPolicy: 'network-only' }),
    state,
    error,
  }
}

export type UseBonusProgramsStateResult = {
  bonusProgramsState: BonusProgramsState | undefined,
  fetchBonusProgramsState: () => void,
  state: RequestState,
  error: Error,
}

export const useBonusProgramsState = (): UseBonusProgramsStateResult => {
  const { result, refetchResult, state, error } = useQuery<ViewerBonusProgramsStateQueryResult>({ query: ViewerBonusProgramsStateQuery })

  return {
    bonusProgramsState: result.data?.viewer.bonusProgramsState || undefined,
    fetchBonusProgramsState: () => refetchResult({ requestPolicy: 'network-only' }),
    state,
    error,
  }
}

export type UseBonusProgramsConfigurationResult = {
  bonusProgramsConfiguration: BonusProgramsConfigurationQueryResult['bonusProgramsConfiguration'] | undefined,
  fetchBonusProgramsConfiguration: () => void,
  state: RequestState,
  error: Error,
}

export const useBonusProgramsConfiguration = (): UseBonusProgramsConfigurationResult => {
  const { result, refetchResult, state, error } = useQuery<BonusProgramsConfigurationQueryResult>({ query: BonusProgramsConfigurationQuery })

  return {
    bonusProgramsConfiguration: result.data?.bonusProgramsConfiguration || undefined,
    fetchBonusProgramsConfiguration: () => refetchResult({ requestPolicy: 'network-only' }),
    state,
    error,
  }
}
