import React from 'react'
import { View } from 'react-native'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'

import { useParams } from 'react-router-dom'
import { useNavigation } from '../../Hooks/navigation'

import { PRIMARY_500 } from '../../const'

const NoRefundPolicyPopOverScreen: React.FC = () => {
  const { navigate, back } = useNavigation()
  const { destination } = useParams()

  const navigateToSessionScreen = () => {
    if (destination === 'session')
      navigate('/customer_home/start_wash/session', { replace: true })
    else
      back()
  }

  return <PopOver
    background='bonus'
    heading={['Возврат на счёт', 'не предусмотрен!']}
    body={[
      'После внесения денег',
      'на Ваш счёт в приложении',
      'Вы можете перевести их',
      'на терминал.',
      '\n',
      'Перевести деньги',
      'обратно на счёт нельзя -',
      'это односторонняя',
      'транзакция!',
    ]}
  >
    <View style={{ gap: 8 }}>
      <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={navigateToSessionScreen}/>
    </View>
  </PopOver>
}

export default NoRefundPolicyPopOverScreen
