import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { BiLock, BiLockOpen } from 'react-icons/bi'
import { GREEN_500, PRIMARY_500 } from '../const'

type LockListItemProps = {
  label: string;
  locked: boolean;
  onPress?: () => void;
}

const LockItem: React.FC<LockListItemProps> = ({ label, onPress, locked }) => {
  const lockedBackgroundColor = GREEN_500
  const notLockedBackgroundColor = PRIMARY_500
  const lockIconSize = 24
  const lockIconColor = 'white'

  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Text style={styles.label}>{label}</Text>
      <View
        style={[
          styles.lockIconContainer,
          { backgroundColor: locked ? lockedBackgroundColor : notLockedBackgroundColor }
        ]}
      >
        {locked
          ? <BiLock size={lockIconSize} color={lockIconColor} />
          : <BiLockOpen size={lockIconSize} color={lockIconColor} />}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 16,
  },
  caption: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '500',
    opacity: 0.64,
  },
  label: {
    fontSize: 24,
    lineHeight: 24,
    fontWeight: '500',
  },
  lockIconContainer: {
    padding: 8,
    borderRadius: 100,
  },
})

export default LockItem
