import React from 'react'
import { View, Text, Image, ScrollView } from 'react-native'
import Button from '../../Components/Button'

import { useParams, Navigate } from 'react-router-dom'
import { useNavigation } from '../../Hooks/navigation'

import { BONUS_PROGRAMS } from '../../const'

const BonusDetailsScreen: React.FC = () => {
  const { bonusIdx } = useParams()
  const { navigate, back } = useNavigation()

  const onPressStartWashButton = () => navigate('/customer_home/start_wash')

  const bonusProgram = BONUS_PROGRAMS[+(bonusIdx || 0)]
  if (bonusProgram === undefined) {
    return <Navigate to='/unknown' />
  }

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          backgroundColor: 'white',
        }}
      >
        <Image
          source={bonusProgram.image}
          style={{
            width: '100%',
            height: 256,
          }}
        />
        <View style={{
          gap: 32,
          paddingVertical: 48,
          paddingHorizontal: 24,
          flex: 1,
          height: '100%',
        }}>
          <View>
            {bonusProgram.label.map(line => <Text style={{ fontSize: 28, fontWeight: '800', lineHeight: 32 }}>{line}</Text>)}
            {bonusProgram.caption.map(line => <Text style={{ fontSize: 16, fontWeight: '400', lineHeight: 24, opacity: 0.64 }}>{line}</Text>)}
          </View>
          <View>
            {bonusProgram.body.map(line => {
              if (line.length === 0)
                return <View style={{ height: 32 }} />
              return <Text style={{ fontSize: 18, fontWeight: '400', lineHeight: 28 }}>{line}</Text>
            })}
          </View>
        </View>
      </ScrollView>
      <View style={{
        marginTop: 'auto',
        gap: 8,
        paddingBottom: 48,
        paddingHorizontal: 24,
      }}>
        <Button text='Назад' variant='tinted' onPress={back} />
        <Button text='Начать мойку' onPress={onPressStartWashButton} />
      </View>
    </>
  )
}

export default BonusDetailsScreen
