import { useEffect } from 'react'

export const useThemeColor = (constructColor: string, destructColor: string = "#ffffff") => {
  useEffect(() => {
    const obj = document.querySelector('meta[name="theme-color"]')
    if (obj === null) return
    obj.setAttribute("content", constructColor);

    return () => {
      obj.setAttribute("content", destructColor)
    }
  }, [])
}
