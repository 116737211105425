import React from 'react'
import { View, Text, Image } from 'react-native'
import Scaffold from '../../Components/Scaffold'
import Button from '../../Components/Button'
import MobileNavbar from '../../Components/Navbar/MobileNavbar'
import { Icon } from '../../utils/types'

import { useAuthentication } from '../../Hooks/authentication'

import { logout } from '../../utils/client'
import { contactUs } from '../../utils/helperFunctions'

import { NEUTRAL_200, NEUTRAL_300 } from '../../const'

type ProfileItemProps = {
  icon: Icon;
  text: string;
}

const ProfileItem: React.FC<ProfileItemProps> = ({ icon, text }) => {
  return (
    <View>
      <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', gap: 8, shadowColor: 'black', paddingVertical: 16 }}>
        <View style={{ padding: 8, borderRadius: 100 }}>
          <Image
            source={require(`../../assets/${icon || 'close'}-icon.png`)}
            style={{ width: 24, height: 24, tintColor: NEUTRAL_300 }}
          />
        </View>
        <Text style={{ fontSize: 16, fontWeight: '500', lineHeight: 16 }}>{text}</Text>
      </View>
      <View style={{ width: '100%', height: 1, backgroundColor: NEUTRAL_200, borderRadius: 10 }} />
    </View>
  )
}

const ViewerAccountScreen: React.FC = () => {
  const { viewer } = useAuthentication()

  return (
    <Scaffold showNavBar={false} gap={16}>
      <MobileNavbar label='Ваш профиль' primaryButton='back' />
      <View style={{ width: '100%' }}>
        <ProfileItem icon='phone' text={viewer?.phoneNumber || 'unknown'} />
      </View>
      <View style={{ width: '100%', gap: 8 }}>
        <Button text='Связаться с нами' style={{ width: '100%' }} onPress={contactUs} />
        <Button text='Выйти из аккаунта' style={{ width: '100%' }} variant='tinted' onPress={logout} />
      </View>
    </Scaffold>
  )
}

export default ViewerAccountScreen
