import React from 'react'
import { View } from 'react-native'
import Scaffold from '../../Components/Scaffold'
import MobileNavbar from '../../Components/Navbar/MobileNavbar'

import { useNavigation } from '../../Hooks/navigation'
import ListItem from '../../Components/ListItem'

const AnalyticsScreen: React.FC = () => {
  const { navigate } = useNavigation()

  return (
    <Scaffold showNavBar={false} gap={32} >
      <MobileNavbar label='Аналитика' primaryButton='back' />
      <View style={{ flex: 1, width: '100%' }}>
        <ListItem label='Денежные счета' onPress={() => navigate('/employee_home/analytics/money_accounts')} />
      </View>
    </Scaffold>
  )
}

export default AnalyticsScreen
