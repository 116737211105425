import React from 'react'
import { View } from 'react-native'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'

import { useNavigation } from '../../Hooks/navigation'

import { PRIMARY_500 } from '../../const'

export const CarWashTerminalCreditedPopOverScreen: React.FC = () => {
  const { back } = useNavigation()

  return <PopOver
    background='success'
    heading={['Деньги зачислены']}
    body={[
      'В размере 100 рублей',
      'на терминал автомойки!',
    ]}
  >
    <View style={{ gap: 8 }}>
      <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
    </View>
  </PopOver>
}

export default CarWashTerminalCreditedPopOverScreen
