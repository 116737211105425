import React, { useState, useEffect } from 'react'
import { useNavigation, NavigationStack } from '../Hooks/navigation'

export type DeepLinkProps = {
  stack: NavigationStack;
}

const DeepLink: React.FC<DeepLinkProps> = ({ stack }) => {
  const [navigateToStack, setNavigateToStack] = useState<boolean>(false)
  const { setCurrentStack } = useNavigation({ navigateAutomatically: navigateToStack })

  useEffect(() => {
    setCurrentStack(stack)
    setNavigateToStack(true)
  }, [])

  return <></>
}

export default DeepLink
