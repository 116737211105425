import React, { useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import Button from '../../Components/Button'
import TextInput from '../../Components/TextField'

import { useNavigation } from '../../Hooks/navigation'
import { useMutation } from 'urql'
import { verifyOTPMutation } from '../../utils/mutations'

import { PRIMARY_500 } from '../../const'

const OtpCodeScreen: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [otp, setOtp] = useState<string>('')
  const [_verifyOtpResult, verifyOtp] = useMutation(verifyOTPMutation)

  const { navigate, back } = useNavigation()

  const needHelp = () => navigate('/popovers/authentication_help')

  const submit = () => {
    if (otp.length === 0) {
      setErrorMessage('Пожалуйста, введите код')
      return
    }

    if (otp.length < 4) {
      setErrorMessage('Длина кода - 4 цифры')
      return
    }

    const phoneNumber = localStorage.getItem('phoneNumber')
    verifyOtp({ phoneNumber, otp }).then(result => {
      console.log(result)
      if (result.error) {
        if (result.error.graphQLErrors.some(e => e.extensions.code == 500)) {
          setErrorMessage('Ошибка сервера. Попробуйте позже')
          return
        }
        if (result.error.graphQLErrors.some(e => e.extensions.code == 400)) {
          setErrorMessage('Неверный код пароль')
          return
        }
      } else {
        localStorage.setItem('accessToken', result.data.verifyOtp.accessToken)
        localStorage.setItem('refreshToken', result.data.verifyOtp.refreshToken)
        back()
      }
    })
  }

  return (
    <View style={styles.container}>
      <View style={{ gap: 8 }}>
        <Text style={styles.title}>Введите код</Text>
        <View>
          <Text style={styles.subtitle}>Вам на телефон пришёл звонок,</Text>
          <Text style={styles.subtitle}>введите последние четыре цифры</Text>
          <Text style={styles.subtitle}>звонящего номера.</Text>
        </View>
      </View>
      <View style={styles.form}>
        <TextInput
          label='Код'
          placeholder='1234'
          keyboardType='decimal-pad'
          maxLength={4}
          onChangeText={setOtp}
        />
        {
        errorMessage !== null
          ? <Text style={styles.errorMessage}>{errorMessage}</Text>
          : <></>
        }
        <Button text='Войти' onPress={submit} />
        <Button text="Есть проблема или недопонимание?" backgroundColor='rgba(0,0,0,0)' foregroundColor={PRIMARY_500} onPress={needHelp} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 96,
    gap: 24,
  },
  title: {
    fontSize: 40,
    lineHeight: 48,
    weight: 800,
  },
  subtitle: {
    fontSize: 20,
    lineHeight: 25,
    weight: 300,
    opacity: 0.64
  },
  form: {
    gap: 8,
  },
  errorMessage: {
    color: PRIMARY_500,
    fontSize: 16,
    lineHeight: 24,
    alignText: 'center',
  }
})

export default OtpCodeScreen
