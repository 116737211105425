import { useState, useEffect } from 'react'
import { useQuery as useUrqlQuery, UseQueryArgs, AnyVariables } from 'urql'

import { QueryResponse, RequestState, Error } from '../utils/types'

export const useQuery = <ResponseData>(args: UseQueryArgs<AnyVariables, ResponseData>): QueryResponse<ResponseData | null> => {
  const [state, setState] = useState<RequestState>('loading')
  const [error, setError] = useState<Error>(null)
  const [response, refetchResult] = useUrqlQuery<ResponseData>(args)

  useEffect(() => {
    if (response.fetching) {
      setState('loading')
      return
    }

    if (response.error !== undefined) {
      if (response.error?.graphQLErrors[0]?.extensions !== undefined && (response.error.graphQLErrors[0].extensions.code == 400 || response.error.graphQLErrors[0].extensions.code == 503)) {
        setError(response.error.graphQLErrors[0].message)
        return
      }
      else {
        setError('Неизвестная ошибка, попробуйте ещё раз!')
        return
      }
    }
    else if (response.data === undefined && response.operation !== undefined) {
      setError('Получен непонятный ответ от сервера, обратитесь в техническую поддержку!')
      return
    }

    if (response.data === undefined)
      setError('Произошла ошибка при попытке связаться с сервером.')

    if (error !== null)
      setState('error')
    else
      setState('success')

  }, [response, response.fetching])

  return {
    state,
    error,
    result: response,
    refetchResult
  }
}
