import React, {
  useState,
  createContext,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ImageBackground,
  Image,
} from "react-native";

import { useQuery } from "../Hooks/useQuery";
import { CardPaymentReportResult } from "../utils/types";
import { CardPaymentReport } from "../utils/queries";
import {
  NEUTRAL_200,
  WHITE,
  PRIMARY_100,
  PRIMARY_600,
  MONTHS,
  PERIOD_TYPES,
} from "../const";
import { dateToISO } from "../utils/helperFunctions";
import Button from "../Components/Button";
import BusyIndicator from "../Components/BusyIndicator";
import PeriodSheet from "../Components/Modals/Period";
import PeriodCalendarSheet from "../Components/Modals/PeriodCalendar";
import { getToday, getMonday } from "../utils/helperFunctions";
import { relative } from "path";
import { CircleIconButton } from "../Components/IconButton";

export const CardPaymentContext = createContext<object | null>({});

type dates = {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  type: number;
};

const CardPaymentReportComponent = forwardRef<
  { triggerFunction: () => void },
  {}
>((props, ref) => {
  const svgDataUri =
    "data:image/svg+xml;base64,ICA8c3ZnCiAgICB3aWR0aD0iMzAyIgogICAgaGVpZ2h0PSI3NiIKICAgIHZpZXdCb3g9IjAgMCAzMDIgNzYiCiAgICBmaWxsPSJub25lIgogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogID4KICAgIDxyZWN0IHdpZHRoPSIzMDIiIGhlaWdodD0iNzYiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl82NjdfNDczKSIgLz4KICAgIDxkZWZzPgogICAgICA8bGluZWFyR3JhZGllbnQKICAgICAgICBpZD0icGFpbnQwX2xpbmVhcl82NjdfNDczIgogICAgICAgIHgxPSIxNTEiCiAgICAgICAgeTE9IjAiCiAgICAgICAgeDI9IjE1MSIKICAgICAgICB5Mj0iNzYiCiAgICAgICAgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiCiAgICAgID4KICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjRkZFRkYyIiAvPgogICAgICAgIDxzdG9wIG9mZnNldD0iMC4zNTUiIHN0b3AtY29sb3I9IiNGRkVGRjIiIHN0b3Atb3BhY2l0eT0iMC41MDUiIC8+CiAgICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRkZFRkYyIiBzdG9wLW9wYWNpdHk9IjAiIC8+CiAgICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgICA8L2RlZnM+CiAgPC9zdmc+";
  const [date, setDate] = useState<dates>({
    startDate: getMonday(),
    endDate: getToday(),
    startTime: "T00:00:00+03:00",
    endTime: "T23:59:59+03:00",
    type: 1,
  });

  let newStart: string = '0';
  let newEnd: string = '0';
  const [periodSheetOpen, setPeriodSheetOpen] = useState<boolean>(false);

  const { state, error, result, refetchResult } =
    useQuery<CardPaymentReportResult>({
      query: CardPaymentReport,
      variables: {
        periodTimestamp_Gte: date.startDate + date.startTime,
        periodTimestamp_Lte: date.endDate + date.endTime,
      },
    });

  const refresh = () => {
    refetchResult({ requestPolicy: "network-only" });
  };

  const normalizeNumber = (number: number) => {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  useImperativeHandle(ref, () => ({
    triggerFunction: refresh,
  }));

  if (date.type === 4) {
    let newStartDate = new Date(date.startDate);
    let newEndDate = new Date(date.endDate);
    newStart = `${newStartDate.getDate()} ${MONTHS[newStartDate.getMonth()]}`;
    newEnd = `${newEndDate.getDate()} ${MONTHS[newStartDate.getMonth()]}`;
  }

  if (state === "loading") {
    return (
      <View style={styles.periodContent}>
        <Text
          style={{ fontSize: 24, lineHeight: 24, fontWeight: "500" }}
        ></Text>
        <BusyIndicator />
      </View>
    );
  }

  if (state === "error" || result.data == null) {
    return (
      <View style={styles.periodContent}>
        <Text style={{ fontSize: 24, lineHeight: 24, fontWeight: "500" }}>
          Оплата картой
        </Text>
        <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: "300" }}>
          Не удалось загрузить данные
        </Text>
        <Button text="Попробовать ещё раз" onPress={refetchResult} />
      </View>
    );
  }

  const toTerminals = result.data.ToTerminals.totalAmount || 0;
  const toMoneyAccounts = result.data.ToMoneyAccounts.totalAmount || 0;
  const sumAmount = toTerminals + toMoneyAccounts;

  return (
    <CardPaymentContext.Provider
      value={{
        date,
        setDate,
        setPeriodSheetOpen,
        periodSheetOpen,
      }}
    >
      <PeriodSheet />
      <TouchableOpacity
        style={styles.periodContent}
        onPress={() => setPeriodSheetOpen(true)}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <View>
            <Text style={{ fontSize: 24, lineHeight: 24, fontWeight: '500' }}>Оплата картой</Text>
            <Text style={styles.period}>
              {date.type === 4
                ? `${newStart} - ${newEnd}`
                : PERIOD_TYPES[date.type]}
            </Text>
            {date.type === 4 && (
              <Text style={styles.period}>
                {date.startTime.split("T")[1].substring(0, 5)} -{" "}
                {date.endTime.split("T")[1].substring(0, 5)}
              </Text>
            )}
          </View>
          <CircleIconButton color={NEUTRAL_200} onPress={refresh} >
            <Image
              source={require(`../assets/refresh-icon.png`)}
              style={{ width: 20, height: 20, tintColor: 'white' }}
            />
          </CircleIconButton>
        </View>
        <View style={styles.periodCostContent}>
          <ImageBackground
            source={{ uri: svgDataUri }}
            resizeMode="cover"
            style={styles.periodBg}
          >
            <Text style={styles.periodTitle}>
              {normalizeNumber(toTerminals)} ₽
            </Text>
            <Text style={styles.periodSubtitle}>На терминалы</Text>
            <View style={styles.periodMargin}>
              <Text style={styles.periodTitle}>
                {normalizeNumber(toMoneyAccounts)} ₽
              </Text>
              <Text style={styles.periodSubtitle}>На ЛК</Text>
            </View>
            <View style={styles.periodMargin}>
              <Text style={styles.periodTitle}>
                {normalizeNumber(sumAmount)} ₽
              </Text>
              <Text style={styles.periodSubtitle}>Общая</Text>
            </View>
          </ImageBackground>
        </View>
      </TouchableOpacity>
    </CardPaymentContext.Provider>
  );
});

const styles = StyleSheet.create({
  periodContent: {
    borderRadius: 24,
    width: "100%",
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.1)",
    padding: 20,
  },
  periodTitle: { fontWeight: "500", fontSize: 24, lineHeight: 24 },
  period: {
    color: PRIMARY_600,
    marginTop: "8px",
    fontSize: 20,
    fontWeight: "400",
    lineHeight: 24,
  },
  periodCostContent: {
    marginTop: 16,
    flex: 1,
  },
  periodSubtitle: {
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "400",
  },
  periodBg: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
    padding: 16,
    borderRadius: 16,
    overflow: "hidden",
  },
  periodMargin: {
    marginTop: 20,
  },
});

export default CardPaymentReportComponent;
