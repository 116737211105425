import React from 'react'
import { View } from 'react-native'
import LockItem from './LockItem'

import { PointLocksListItem } from '../utils/types'

type LocksListProps = {
  locks: PointLocksListItem[];
  handleOpenLock: (lock: PointLocksListItem) => void;
}

const LocksList: React.FC<LocksListProps> = ({ locks, handleOpenLock }) => {
  return <View style={{ width: '100%', gap: 0 }}>
    {locks.map(lock => <LockItem
      key={lock.id}
      label={lock.name}
      locked={lock.locked}
      onPress={() => handleOpenLock(lock)}
    />)}
  </View>
}

export default LocksList
