import { useState, useEffect } from "react";
import { CONTACT_US_PAGE } from "../const";
import { changeCurrentStack } from "../Hooks/navigation";
import { MoneyAccountType } from "./types";

export type UseForLoopResult = [() => void, () => void, boolean];

export const useForLoop = (opts: {
  iterCount: number;
  handler: () => void;
  afterLoopHandler?: () => void;
  timeout?: number;
}): UseForLoopResult => {
  const [breakLoop, setBreakLoop] = useState<boolean>(false);
  const [idx, setIdx] = useState<number>(0);
  const [loopExecuted, setLoopExecuted] = useState<boolean>(false);
  const [loopStarted, setLoopStarted] = useState<boolean>(false);

  const loop = () => {
    setLoopStarted(true);
    setTimeout(() => {
      opts.handler();
      setIdx((current) => current + 1);
    }, opts.timeout || 0);
  };

  useEffect(() => {
    if (!loopStarted) return;

    if (idx < opts.iterCount && !breakLoop) {
      loop();
      return;
    }

    if (opts.afterLoopHandler !== undefined) opts.afterLoopHandler();

    setLoopExecuted(true);
  }, [idx]);

  return [loop, () => setBreakLoop(true), loopExecuted];
};

// export const contactUs = () => window.location.href = CONTACT_US_PAGE
export const contactUs = () => changeCurrentStack(["/popovers/contact_us"]);

export const isMoneyAccountType = (value: string): value is MoneyAccountType =>
  [
    "CUSTOMER",
    "BONUS_PROGRAM_FOR_RICH",
    "BONUS_PROGRAM_FOR_POOR",
    "SELF_SERVICE_CAR_WASH_BOX_CLEANING",
  ].includes(value);

export const dateToISO = (date: Date): string => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString(
    "en-US",
    {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }
  )}-${date.getDate().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
};

export const getToday = () => dateToISO(new Date());

export const getMonday = () => {
  let d = new Date();
  let day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  let res = new Date(d.setDate(diff));
  return dateToISO(res);
};

export const getFirstDayOfMonth = () => {
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return dateToISO(firstDay);
};

export const getFirstDayOfYear = () => {
  let d = new Date(new Date().getFullYear(), 0, 1);
  return dateToISO(d);
};
