import React, { useEffect } from 'react'
import EmployeeHomeScreen from './EmployeeHomeScreen'
import CustomerHomeScreen from './CustomerHomeScreen'

// import { useNavigation } from '../../Hooks/navigation'

const HomeScreenRouter: React.FC = () => {
  // const { navigateHome } = useNavigation()

  // useEffect(() => {
  //   navigateHome()
  // }, [])

  return <></>
}

export {
  HomeScreenRouter,
  EmployeeHomeScreen,
  CustomerHomeScreen,
}
