import React, { useEffect } from 'react'
import { View, Text } from 'react-native'
import Scaffold from './Scaffold'
import Button from './Button'

import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { useAuthentication, useLogout } from '../Hooks/authentication'
import { useNavigation } from '../Hooks/navigation'

const RequireStaff: React.FC = () => {
  const { authenticated, viewer } = useAuthentication()
  const { setCurrentStack } = useNavigation()
  const phoneNumber = localStorage.getItem('phoneNumber')
  const location = useLocation()
  const logout = useLogout()

  useEffect(() => {
    if (!authenticated)
      setCurrentStack(['/authentication/phone_number'])
  }, [authenticated])

  if (!authenticated)
    return <></>

  else if (viewer !== null && !viewer.isStaff)
    return (
      <Scaffold navTitle=''>
        <Text style={{ fontSize: 64, lineHeight: 72, fontWeight: '800', textAlign: 'center', marginBottom: 8 }}>401</Text>
        <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>У вас нет доступа к это странице!</Text>
        <View style={{ width: '100%', gap: 8, marginTop: 'auto' }}>
          <View>
            <Text style={{ fontSize: 16, lineHeight: 24, opacity: 0.64, textAlign: 'center' }}>Вы вошли под номером</Text>
            <Text style={{ fontSize: 16, lineHeight: 24, opacity: 0.64, textAlign: 'center' }}>{phoneNumber}</Text>
          </View>
          <Button text="Выйти из аккаунта" onPress={() => logout()} style={{ width: '100%' }} />
        </View>
      </Scaffold>
    )
  else if (viewer === undefined)
    return (
      <Scaffold navTitle=''>
        <Text style={{ fontSize: 64, lineHeight: 72, fontWeight: '800', textAlign: 'center', marginBottom: 8 }}>Ошибка!</Text>
        <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Не удалось запросить информацию о пользователе</Text>
        <View style={{ width: '100%', gap: 8, marginTop: 'auto' }}>
          <View>
            <Text style={{ fontSize: 16, lineHeight: 24, opacity: 0.64, textAlign: 'center' }}>Вы вошли под номером</Text>
            <Text style={{ fontSize: 16, lineHeight: 24, opacity: 0.64, textAlign: 'center' }}>{phoneNumber}</Text>
          </View>
          <Button text="Выйти из аккаунта" onPress={() => logout()} style={{ width: '100%' }} />
        </View>
      </Scaffold>
    )
    
  return <Outlet />
}

export default RequireStaff
