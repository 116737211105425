import React from 'react'
import { TextInput as RNTextInput, StyleSheet, View, Text } from 'react-native'

type TextInputProps = React.ComponentProps<typeof RNTextInput> & {
  label: string;
  labelColor?: string;
}

const TextInput: React.FC<TextInputProps> = ({ label, labelColor, ...rest }) => {
  return (
    <View style={styles.container}>
      <Text style={[styles.label, { color: labelColor }]}>{label}</Text>
      <RNTextInput
        style={styles.input}
        placeholderTextColor='rgba(0,0,0,0.64)'
        {...rest}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
  input: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    backgroundColor: '#eaeaea',
    borderRadius: 8,
    fontSize: 16,
    lineHeight: 24,
    borderWidth: 0,
  },
  label: {
    fontSize: 16,
    lineHeight: 24,
  }
})

export default TextInput

