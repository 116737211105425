import React from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import { FiChevronRight } from 'react-icons/fi'

type ListItemProps = {
  label: string;
  caption?: string;
  onPress?: () => void;
}

const ListItem: React.FC<ListItemProps> = ({ label, caption, onPress }) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <View style={{ flexDirection: 'column' }}>
        <Text style={styles.label}>{label}</Text>
        <Text style={styles.caption}>{caption}</Text>
      </View>
      <FiChevronRight size={32} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 16,
  },
  caption: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '500',
    opacity: 0.64,
  },
  label: {
    fontSize: 24,
    lineHeight: 24,
    fontWeight: '500',
  },
})

export default ListItem
