import React from 'react'

type ShowProps = {
  children: React.ReactNode;
  condition: boolean;
}

const Show: React.FC<ShowProps> = ({ children, condition }) => {
  return condition ? <>{children}</> : <></>
}

export default Show
