import React from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";
import { NEUTRAL_100 } from "../const";

type CategoryItemProps = {
  onPress: () => void;
  text: String;
};

const CategoryItem: React.FC<CategoryItemProps> = ({ onPress, text }) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.itemContainer}>
      <Text style={styles.itemText}>{text}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    backgroundColor: NEUTRAL_100,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 8,
    alignSelf: "flex-start",
  },
  itemText: {
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 16,
  },
});

export default CategoryItem;
