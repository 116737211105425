import React from 'react'
import DeepLink from '../Components/DeepLink'

import { useParams } from 'react-router-dom'

const CustomerMoneyAccountCreditedDeepLink = () => {
  const { fail, moneyAmount } = useParams()

  return <DeepLink stack={[`/popovers/customer_money_account_credited/${fail}/${moneyAmount}`]} />
}

export default CustomerMoneyAccountCreditedDeepLink
