import React from 'react'
import { View, Text, Image } from 'react-native'
import MobileNavbar from '../../Components/Navbar/MobileNavbar'
import Widget from '../../Components/Widget'

import { useNavigation } from '../../Hooks/navigation'

import { BONUS_PROGRAMS, NEUTRAL_200 } from '../../const'

type BonusCardItemProps = {
  image: any;
  label: string[];
  caption: string[];
  onPress: () => void;
}

const BonusCardItem: React.FC<BonusCardItemProps> = ({ image, label, caption, onPress }) => {
  return (
    <Widget style={{ gap: 0, padding: 0, overflow: 'hidden' }} onPress={onPress}>
      <Image
        source={image}
        style={{
          width: '100%',
          height: 160,
        }}
      />
      <View style={{ padding: 24 }}>
        {label.map((line, idx) => <Text key={idx} style={{ fontSize: 24, fontWeight: '500', lineHeight: 24 }}>{line}</Text>)}
        {caption.map((line, idx) => <Text key={idx} style={{ fontSize: 15, fontWeight: '400', lineHeight: 24, opacity: 0.64 }}>{line}</Text>)}
      </View>
    </Widget>
  )
}

const BonusListScreen: React.FC = () => {
  const { navigate } = useNavigation()
  const navigateBonusDetails = (bonusIdx: number) => navigate(`${window.location.pathname}/${bonusIdx}`)

  return (
    <View style={{
      flex: 1,
      backgroundColor: 'white',
      paddingVertical: 48,
      paddingHorizontal: 24,
      gap: 40,
    }}>
      <MobileNavbar label='Бонусные программы' primaryButton='back' />
      <View style={{ gap: 24 }}>
        {BONUS_PROGRAMS.map((program, idx) => (
          <BonusCardItem
            key={idx}
            label={program.label}
            caption={program.caption}
            image={program.image}
            onPress={() => navigateBonusDetails(idx)}
          />
        ))}
      </View>
    </View>
  )
}

export default BonusListScreen
