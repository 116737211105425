import { ConfigurationQuery } from "../utils/queries"
import { Configuration, Error, RequestState } from "../utils/types"
import { useQuery } from "./useQuery"


export type UseConfigurationResult = {
  configuration: Configuration['configuration'] | undefined,
  fetchConfiguration: () => void,
  state: RequestState,
  error: Error,
}

export const useConfiguration = (): UseConfigurationResult => {
  const { result, refetchResult, state, error } = useQuery<Configuration>({ query: ConfigurationQuery, requestPolicy: 'cache-and-network' })

  return {
    configuration: result.data?.configuration || undefined,
    fetchConfiguration: () => refetchResult({ requestPolicy: 'network-only' }),
    state,
    error,
  }
}
