import React, { useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useNavigation } from '../../Hooks/navigation'

import Button from '../../Components/Button'
import TextInput from '../../Components/TextField'
import { PRIMARY_500, NEUTRAL_90, PRIMARY_100 } from '../../const'
import { useClient, useMutation } from 'urql'
import { ActivateUserAuthorizationSessionMutationResult, UserAuthorizationInfoQueryResult, VerifySMSOTPMutationResult } from '../../utils/types'
import { activateUserAuthorizationSessionMutation, verifySMSOTPMutation } from '../../utils/mutations'
import { UserAuthorizationInfoQuery } from '../../utils/queries'

const CONNECTION_ERROR_MSG = `
Не удаётся
подключиться к серверу.

Проверьте подключение
к интернету, попробуйте
перезагрузить страницу
или обратитесь к нам
за помощью, используя
кнопку ниже!`

const UNKNOWN_ERROR_MSG = `
Произошла неожиданная
для нас ошибка!

Попробуйте перезагрузить
страницу - обычно это
помогает. Либо обратитесь
к нам за помощью
по кнопке ниже!`


const STRANGE_RESPONSE_ERROR_MSG = `
Получен неожиданный
ответ от сервера!

Попробуйте перезагрузить
страницу - обычно это
помогает. Либо обратитесь
к нам за помощью
по кнопке ниже!`

const SmsOtpScreen: React.FC = () => {
  const { navigate, back } = useNavigation()
  const urqlClient = useClient()

  const [error, setError] = useState<string | null>(null)
  const [busy, setBusy] = useState<boolean>(false)

  const [_verifySmsOtpResult, verifySmsOtp] = useMutation<VerifySMSOTPMutationResult>(verifySMSOTPMutation)
  const [_activateUserAuthorizationSessionResult, activateUserAuthorizationSession] = useMutation<ActivateUserAuthorizationSessionMutationResult>(activateUserAuthorizationSessionMutation)

  const handleSmsOtpInput = (input: string) => {
    if (input.length === 4)
      submit(input)
  }

  const submit = (smsOtp: string) => {
    setBusy(true)
    verifySmsOtp({ sessionId: localStorage.getItem('userAuthorizationSessionId'), otp: smsOtp }).then(result => {
      if (result.error?.graphQLErrors[0].message === 'invalid otp code') {
        setError('Неверный код')
        return
      }
      if (result.error?.graphQLErrors[0].message === 'sms session not found') {
        navigate('/authentication/phone_number', { replace: true })
        return
      }
      
      if (result.data?.verifySmsOtp.success) {
        tryToActivateSession()
      }
    })
  }

  const tryToActivateSession = () => {
    urqlClient.query<UserAuthorizationInfoQueryResult>(UserAuthorizationInfoQuery, { sessionId: localStorage.getItem('userAuthorizationSessionId') }, { requestPolicy: 'network-only' }).toPromise().then(result => {
      console.log('tryToActivateSession result')
      console.log(result)
      if (result.data?.userAuthorizationInfo.status != 'VERIFICATED') {
        setTimeout(tryToActivateSession, 250)
        return
      }

      activateSession()
      tryToProceedAuthorization()
    })
  }

  const tryToProceedAuthorization = () => {
    urqlClient.query<UserAuthorizationInfoQueryResult>(UserAuthorizationInfoQuery, { sessionId: localStorage.getItem('userAuthorizationSessionId') }, { requestPolicy: 'network-only' }).toPromise().then(result => {
      console.log('tryToProceedAuthorization result')
      console.log(result)

      if (result.data?.userAuthorizationInfo.status != 'ACTIVE') {
        setTimeout(tryToActivateSession, 250)
        return
      }

      setBusy(false)
      back()
    })

  }

  const activateSession = () => {
    activateUserAuthorizationSession({ sessionId: localStorage.getItem('userAuthorizationSessionId') }).then(result => {
      if (result.error) {
        if (result.error.networkError != undefined) {
          setError(CONNECTION_ERROR_MSG)
          return
        }

        setError(UNKNOWN_ERROR_MSG)
        return 
      }

      if (result.data === undefined || result.data.activateUserAuthorizationSession.accessToken === undefined || result.data?.activateUserAuthorizationSession.refreshToken === undefined) {
        setError(STRANGE_RESPONSE_ERROR_MSG)
        return 
      }

      localStorage.setItem('accessToken', result.data!.activateUserAuthorizationSession.accessToken)
      localStorage.setItem('refreshToken', result.data!.activateUserAuthorizationSession.refreshToken)

    })
  }

  const needHelp = () => navigate('/popovers/authentication_help')

  return (
    <View style={styles.container}>
      <View style={{ gap: 16 }}>
        <View>
          <Text style={styles.title}>Проверьте</Text>
          <Text style={styles.title}>уведомления</Text>
        </View>
        <View>
          <Text style={styles.subtitle}>Мы прислали Вам СМС с кодом</Text>
          <Text>
            <Text style={styles.subtitle}>для входа в ЛК </Text>
            <Text style={[styles.subtitle, { fontWeight: "bold" }]}>"Мойте сами - ЧИСТОСТРАНА"</Text>
          </Text>
          <View style={{ height: 25 }} />
          <Text style={styles.subtitle}>Мы отправили сообщение на</Text>
          <Text>
            <Text style={styles.subtitle}>номер </Text>
            <Text style={[styles.subtitle, { fontWeight: "bold" }]}>{localStorage.getItem('phoneNumber')}</Text>
          </Text>
        </View>
      </View>
      <View style={styles.form}>
        <TextInput
          label='Код'
          placeholder='1234'
          keyboardType='decimal-pad'
          maxLength={4}
          onChangeText={handleSmsOtpInput}
        />
        <Button text="Мне нужна помощь!" backgroundColor={PRIMARY_100} foregroundColor={PRIMARY_500} onPress={needHelp} busy={busy} />
        {
          error !== null
            ? <Text style={styles.errorMessage}>{error}</Text>
            : <></>
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 96,
    gap: 24,
  },
  title: {
    fontSize: 40,
    lineHeight: 40,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 20,
    lineHeight: 25,
    weight: 300,
    color: NEUTRAL_90,
  },
  form: {
    gap: 8,
  },
  errorMessage: {
    color: PRIMARY_500,
    fontSize: 16,
    lineHeight: 20,
    alignText: 'center',
  },
  caption: {
    fontSize: 12,
    lineHeight: 16,
    color: NEUTRAL_90
  }
})

export default SmsOtpScreen
