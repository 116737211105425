import React, { useState, useEffect, useMemo } from 'react'
import { View, Text, Dimensions } from 'react-native'
import MobileNavbar from '../../Components/Navbar/MobileNavbar'
import SessionAction from '../../Components/SessionAction'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'
import BusyIndicator from '../../Components/BusyIndicator'
import MoneyAmountSelector from '../../Components/MoneyAmountSelector'

import { useNavigation } from '../../Hooks/navigation'
import { useTransferMoneyToCarWashTerminal } from '../../Hooks/moneyAccounts'
import { useSession } from '../../Hooks/useSession'
import { useMoneyAccountBalances, useEnsureCarWashTerminalCreditSucceeded } from '../../Hooks/moneyAccounts'

import { PRIMARY_300, PRIMARY_400, PRIMARY_500, CONTACT_US_PAGE } from '../../const'
import { useParams } from 'react-router-dom'
import { Error, MoneyAccountType } from '../../utils/types'
import { isMoneyAccountType } from '../../utils/helperFunctions'
import Show from '../../Components/Show'

const { width } = Dimensions.get('window')

const TransferMoneyToCarWashTerminalScreen: React.FC = () => {
  const { navigate, back, setCurrentStack } = useNavigation()
  const { transferSource } = useParams()
  const { moneyAccountBalances, fetchMoneyAccountBalances, state: moneyAccountBalancesFetchingState, error: moneyAccountBalancesFetchingError } = useMoneyAccountBalances()
  const { customer, bonusProgramForRich, bonusProgramForPoor, selfServiceCarWashBoxCleaning } = moneyAccountBalances
  const {
    session,
    fetchSessionState,
    state: sessionState,
    error: sessionFetchingError,
  } = useSession()
  const carWashTerminalId = session?.carWashTerminal.id

  const [moneyAmount, setMoneyAmount] = useState<number>(200);
  const [carWashTerminalCreditId, setCarWashTerminalCreditId] = useState<string | undefined>()
  const [state, setState] = useState<'choosingAmount' | 'notEnoughMoney' | 'loading' | 'fetchingState' | 'error' | 'success'>('loading')
  const [error, setError] = useState<Error>(null)

  const [ensureCarWashTerminalCreditSucceeded, creditEnsureState, ensureError] = useEnsureCarWashTerminalCreditSucceeded(carWashTerminalCreditId)
  const [execute, busy, transferError] = useTransferMoneyToCarWashTerminal((result) => {
    setCarWashTerminalCreditId(result.transferMoneyToCarWashTerminal.carWashTerminalCredit.id)
  }, transferSource as MoneyAccountType)

  useEffect(() => {
    fetchMoneyAccountBalances()
    fetchSessionState()
  }, [])

  useEffect(() => {
    if (moneyAccountBalancesFetchingState === 'loading' || sessionState === 'loading')
      return

    let transferSourceBalance: number | undefined = undefined
    if (transferSource === 'CUSTOMER')
      transferSourceBalance = moneyAccountBalances.customer
    if (transferSource === 'BONUS_PROGRAM_FOR_POOR')
      transferSourceBalance = moneyAccountBalances.bonusProgramForPoor
    if (transferSource === 'BONUS_PROGRAM_FOR_RICH')
      transferSourceBalance = moneyAccountBalances.bonusProgramForRich
    if (transferSource === 'SELF_SERVICE_CAR_WASH_BOX_CLEANING')
      transferSourceBalance = moneyAccountBalances.selfServiceCarWashBoxCleaning


    if (transferSourceBalance === undefined) return

    if (transferSourceBalance === 0)
      setState('notEnoughMoney')
    else 
      setState('choosingAmount')
  }, [moneyAccountBalancesFetchingState, sessionState])

  useEffect(() => {
    setError(sessionFetchingError || moneyAccountBalancesFetchingError || transferError || ensureError || null)
  }, [sessionFetchingError, moneyAccountBalancesFetchingError, transferError, ensureError])

  useEffect(() => {
    if (carWashTerminalCreditId === undefined)
      return

    setState('fetchingState')
    ensureCarWashTerminalCreditSucceeded()
  }, [carWashTerminalCreditId])

  const navigateToNoRefundPolicy = () => navigate('/popovers/no_refund_policy/back')

  useEffect(() => {
    if (creditEnsureState === 'error') {
      setState('error')
      setError(ensureError)
    }
    else if (creditEnsureState === 'success') {
      setState('success')
    }
  }, [creditEnsureState])

  useEffect(() => {
    if (transferSource === undefined || !isMoneyAccountType(transferSource))
      setError('Не верный источник перевода')
  }, [transferSource])

  const moneyAmountSelectorMax = useMemo(() => {
    console.log(transferSource)
    if (transferSource === 'CUSTOMER')
      return customer || 0
    else if (transferSource === 'BONUS_PROGRAM_FOR_RICH')
      return bonusProgramForRich || 0
    else if (transferSource === 'BONUS_PROGRAM_FOR_POOR')
      return bonusProgramForPoor || 0
    else if (transferSource === 'SELF_SERVICE_CAR_WASH_BOX_CLEANING')
      return selfServiceCarWashBoxCleaning || 0
    setError('Установлен неизвестный источник платежа')
    return 0
  }, [transferSource, customer, bonusProgramForRich])

  const submit = () => {
    if (carWashTerminalId === undefined)
      return

    execute(moneyAmount, carWashTerminalId)
  }

  const transferMore = () => window.location.reload()

  const handleContactUs = () => {
    window.location.href = CONTACT_US_PAGE
  }

  if (error !== null) {
    return <PopOver
      background='invalid'
      heading={['Не удалось', 'перевести деньги', 'на терминал!']}
      body={[error]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={handleContactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  if (state === 'notEnoughMoney') {
    return <PopOver
      background='invalid'
      heading={['Не удалось', 'перевести деньги', 'на терминал!']}
      body={['На счету 0 рублей!\n\nПополните этот счёт,\nчтобы перевести деньги\nна терминал.']}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={handleContactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  if (sessionState === 'success' && carWashTerminalId === undefined) {
    return <PopOver
      background='invalid'
      heading={['Не удалось', 'перевести деньги', 'на терминал!']}
      body={['Ошибка сессии, попробуйте перезагрузить заново']}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={handleContactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  if (state === 'loading') {
    return <PopOver
      heading={[]}
      body={[]}
    >
      <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', zIndex: -1 }}>
        <BusyIndicator size={width * 1.5} color={PRIMARY_300} />
      </View>
    </PopOver>
  }

  if (state === 'fetchingState') {
    return <PopOver
      heading={['Проверяем']}
      body={['Дошли ли деньги до терминала, подождите пару секунд :)']}
    >
      <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', zIndex: -1 }}>
        <BusyIndicator size={width * 1.5} color={PRIMARY_300} />
      </View>
    </PopOver>
  }

  if (state === 'success') {
    return <PopOver
      background='success'
      heading={['Деньги переведены']}
      body={[
        'На терминал автомойки',
        'самообслуживания в',
        `размере ${moneyAmount} рублей!`,
        '\r',
        'Приятной мойки!:)'
      ]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Назад' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={back} />
        <Button text='Перевести ещё' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={transferMore} />
      </View>
    </PopOver>
  }

  const Source: React.FC = () => {
    const label: string = useMemo(() => {
      if (transferSource === 'CUSTOMER')
        return 'Остаток'
      else if (transferSource === 'BONUS_PROGRAM_FOR_RICH')
        return 'Бонусы'
      else if (transferSource === 'BONUS_PROGRAM_FOR_POOR')
        return 'Бонусы'
      else if (transferSource === 'SELF_SERVICE_CAR_WASH_BOX_CLEANING')
        return 'Фантики'
      setError('Установлен неизвестный источник платежа')
      return 'Ошибка'
    }, [transferSource])

    const caption: string | undefined = useMemo(() => {
      if (transferSource === 'BONUS_PROGRAM_FOR_RICH')
        return '"Для богатеев"'
      else if (transferSource === 'BONUS_PROGRAM_FOR_POOR')
        return '"Для нищебродов"'
      else if (transferSource === 'SELF_SERVICE_CAR_WASH_BOX_CLEANING')
        return 'Для мойки постов'
    }, [transferSource])

    const image: string = useMemo(() => {
      if (transferSource === 'CUSTOMER')
        return 'wallet-icon.png'
      else if (transferSource === 'BONUS_PROGRAM_FOR_RICH')
        return 'bonus-icon.png'
      else if (transferSource === 'BONUS_PROGRAM_FOR_POOR')
        return 'bonus-icon.png'
      else if (transferSource === 'SELF_SERVICE_CAR_WASH_BOX_CLEANING')
        return 'bay-wash-icon.png'
      setError('Установлен неизвестный источник платежа')
      return 'wallet-icon.png'
    }, [transferSource])

    const moneyLeft: number = useMemo(() => {
      if (transferSource === 'CUSTOMER')
        return customer || -1
      else if (transferSource === 'BONUS_PROGRAM_FOR_RICH')
        return bonusProgramForRich || -1
      else if (transferSource === 'BONUS_PROGRAM_FOR_POOR')
        return bonusProgramForPoor || -1
      else if (transferSource === 'SELF_SERVICE_CAR_WASH_BOX_CLEANING')
        return selfServiceCarWashBoxCleaning || -1
      setError('Установлен неизвестный источник платежа')
      return -1
    }, [transferSource])

    return (
      <SessionAction label={label} image={image} caption={caption}>
        <Text style={{ fontSize: 20, fontWeight: '400', lineHeight: 24 }}>{moneyLeft} руб.</Text>
      </SessionAction>
    )
  }

  return (
    <View style={{
      flex: 1,
      backgroundColor: 'white',
    }}>
      <View style={{ paddingVertical: 48, paddingHorizontal: 24, gap: 32 }}>
        <MobileNavbar label='Мойка' caption={'Перевести деньги\nна терминал'} primaryButton='back' />
        <View style={{ gap: 16 }}>
          <Text style={{ fontSize: 28, fontWeight: '500', lineHeight: 32 }}>Источник</Text>
          <Source />
        </View>
      </View>
      <View style={{ marginTop: 'auto', backgroundColor: PRIMARY_500, padding: 24, paddingBottom: 48, gap: 16 }}>
        <MoneyAmountSelector maxAmount={moneyAmountSelectorMax} setMoneyAmount={setMoneyAmount} />
        <View style={{ gap: 8 }}>
          <Button text='Назад' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={back} />
          <Button text='Возврат на счёт недоступен' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={navigateToNoRefundPolicy} />
          <Button text='Перевести на терминал' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={submit} busy={busy} />
        </View>
      </View>
    </View>
  )
}

export default TransferMoneyToCarWashTerminalScreen
