import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useNavigate, redirect } from 'react-router-dom'

const NavigationStackInStorageName = 'navigationStack'

export type NavigationPage = string
export type NavigationStack = NavigationPage[]

export type UseNavigationResult = {
  currentStack: NavigationStack;
  setCurrentStack: Dispatch<SetStateAction<NavigationStack>>;
  navigate: (page: string, opts?: { replace: boolean }) => void;
  navigateHome: (bonusesPopOver?: boolean) => void;
  back: (pageCount?: number) => void;
}

export type UseNavigationProps = {
  navigateAutomatically: boolean;
}

export const useNavigation = (props?: UseNavigationProps): UseNavigationResult => {
  const { navigateAutomatically = true } = props || {}
  const routerNavigate = useNavigate()

  const [currentStack, setCurrentStack] = useState<NavigationStack>(() => {
    const stackFromStorage = localStorage.getItem(NavigationStackInStorageName)

    const isInDeepLink = window.location.pathname.startsWith('/deeplink')
    if (isInDeepLink) return [window.location.pathname]

    if (stackFromStorage === null) return []
    return JSON.parse(stackFromStorage)
  })

  const navigate = (page: NavigationPage, opts?: { replace: boolean }) => {
    const pushPage = () => setCurrentStack(stack => [...stack, page])
    const replacePage = () => setCurrentStack(stack => {
      const newStack = [...stack]
      newStack.pop()
      return [...newStack, page]
    })

    if (opts === undefined)
      return pushPage()

    if (opts.replace)
      return replacePage()
    else
      return pushPage()
  }

  const back = (pageCount: number = 1) => setCurrentStack(stack => {
    const newStack = [...stack]
    for (let i = 0; i < pageCount; i++)
      newStack.pop()
    return newStack
  })

  const navigateHome = (bonusesPopOver: boolean = false) => {
    if (bonusesPopOver)
      setCurrentStack(['/customer_home', '/popovers/bonuses'])
    else 
      setCurrentStack(['/customer_home'])
  }

  useEffect(() => {
    localStorage.setItem(NavigationStackInStorageName, JSON.stringify(currentStack))

    const currentPage = currentStack.at(-1)

    if (currentPage === undefined) {
      navigateHome()
      return
    }

    if (navigateAutomatically) {
      routerNavigate(currentPage, { replace: true })
    }
  }, [currentStack])

  return {
    currentStack,
    setCurrentStack,
    navigate,
    navigateHome,
    back,
  }
}

export const changeCurrentStack = (newStack: string[]) => {
  localStorage.setItem(NavigationStackInStorageName, JSON.stringify(newStack))
  window.location.reload()
}

export const navigateToNoConnectionScreen = () => changeCurrentStack(['/popovers/no_connection'])
export const navigateHome = () => changeCurrentStack(['/customer_home'])
