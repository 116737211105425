import React from 'react'
import { View, Text, Image } from 'react-native'
import { CircleIconButton } from '../IconButton'
import Show from '../Show'

import { useNavigation } from '../../Hooks/navigation'

import { NEUTRAL_200 } from '../../const';
import { Icon } from '../../utils/types'

type MobileNavbarProps = React.PropsWithChildren & {
  label: string;
  caption?: string;
  primaryButton?: Icon | undefined;
  primaryButtonColor?: string;
  primaryButtonIconColor?: string;
  primaryButtonOnPress?: () => void;
  textColor?: string;
}


const MobileNavbar: React.FC<MobileNavbarProps> = ({
  label,
  caption,
  primaryButton,
  primaryButtonColor = NEUTRAL_200,
  primaryButtonIconColor = 'white',
  primaryButtonOnPress,
  textColor = 'black',
  children,
}) => {
  const { back } = useNavigation()
  const defaultPrimaryButtonOnPress = () => back()

  return (
    <View style={{ width: '100%', height: 48, justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ position: 'absolute', left: 0, top: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
        <Show condition={primaryButton != undefined}>
          <CircleIconButton onPress={primaryButtonOnPress || defaultPrimaryButtonOnPress} color={primaryButtonColor}>
            <Image
              source={require(`../../assets/${primaryButton || 'close'}-icon.png`)}
              style={{ width: 20, height: 20, tintColor: primaryButtonIconColor }}
            />
          </CircleIconButton>
        </Show>
      </View>
      <View style={{ position: 'absolute', left: 0, top: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
        <Show condition={primaryButton != undefined}>
          <CircleIconButton onPress={primaryButtonOnPress || defaultPrimaryButtonOnPress} color={primaryButtonColor}>
            <Image
              source={require(`../../assets/${primaryButton || 'close'}-icon.png`)}
              style={{ width: 20, height: 20, tintColor: primaryButtonIconColor }}
            />
          </CircleIconButton>
        </Show>
      </View>
      <View style={{ alignItems: 'center' }}>
        <Text style={{ fontSize: 24, color: textColor, lineHeight: 32, textAlign: 'center' }}>{label}</Text>
        <Text style={{ fontSize: 14, color: textColor, lineHeight: 16, textAlign: 'center' }}>{caption}</Text>
      </View>
      <View style={{ position: 'absolute', right: 0, top: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
        {children}
      </View>
    </View>
  )

  // const navigate = useNavigate()

  // return (
  //   <View
  //     style={{
  //       width: '100%',
  //       flexDirection: 'column',
  //       justifyContent: 'center',
  //       alignItems: 'flex-start',
  //       gap: 8,
  //       marginBottom: 24,
  //       height: 48,
  //     }}
  //   >
  //     <Show condition={showBackButton && primaryButton === undefined}>
  //       <IconButton onPress={() => navigate(-1)} containerStyle={{ position: 'absolute', left: 0 }}><RxChevronLeft size={32} /></IconButton>
  //     </Show>
  //     <Show condition={primaryButton !== undefined}>
  //       {primaryButton}
  //     </Show>
  //     <View style={{ position: 'absolute', left: 0, right: 0 }}>
  //       <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
  //         <Text style={{ fontSize: 24, color: frontColor }}>{label}</Text>
  //         <Text style={{ fontSize: 14, textAlign: 'center', color: frontColor }}>{caption}</Text>
  //       </View>
  //     </View>
  //     <View style={{ position: 'absolute', right: 0 }}>
  //       {actions || <View></View>}
  //     </View>
  //   </View>
  // )
}

export default MobileNavbar
