import React from 'react'
import { View } from 'react-native'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'

import { useNavigation } from '../../Hooks/navigation'

import { PRIMARY_500 } from '../../const'

export const CashCarWashTerminalPopOverScreen: React.FC = () => {
  const { back } = useNavigation()

  return <PopOver
    background='success'
    heading={['Вставьте деньги']}
    body={[
      'В купюроприёмник',
      'или монетоприёмник',
      'расположенных на',
      'терминале',
    ]}
  >
    <View style={{ gap: 8 }}>
      <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
    </View>
  </PopOver>
}

export default CashCarWashTerminalPopOverScreen
