import React, { useState, useCallback } from 'react'
import { View, Text, FlatList, Dimensions, Pressable, Image } from 'react-native'
import Scaffold from '../../Components/Scaffold'
import Button from '../../Components/Button'
import Show from '../../Components/Show'
import MoneyAmountSelector from '../../Components/MoneyAmountSelector'
import { BsFillCheckCircleFill } from 'react-icons/bs'

import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '../../Hooks/useQuery'
import { useMutation } from 'urql'

import { PointCarWashTerminalsQuery } from '../../utils/queries'
import { Error, PointCarWashTerminalsQueryResult } from '../../utils/types'
import { creditCarWashTerminalMutation } from '../../utils/mutations'
import { NEUTRAL_200, PRIMARY_200, PRIMARY_300, PRIMARY_400, PRIMARY_500, PRIMARY_600, WHITE } from '../../const'
import { MobileNavbar } from '../../Components/Navbar'
import { CircleIconButton } from '../../Components/IconButton'
import { useNavigation } from '../../Hooks/navigation'
import Selector, { SelectorItemProps } from '../../Components/Selector'
import SessionAction from '../../Components/SessionAction'
import PopOver from '../../Components/PopOver'
import { contactUs } from '../../utils/helperFunctions'
import { useThemeColor } from '../../Hooks/useThemeColor'

const { width } = Dimensions.get('window')
const NUMBER_OF_COLUMNS = 2
const GRID_GAP = 8
const SCAFFOLD_PADDING = 24
const CELL_SIZE = ((width - (SCAFFOLD_PADDING * 2)) / NUMBER_OF_COLUMNS) - ((GRID_GAP * (NUMBER_OF_COLUMNS - 1)) / NUMBER_OF_COLUMNS)
const GRID_HEIGHT = NUMBER_OF_COLUMNS * CELL_SIZE + GRID_GAP * 2

export const PointCreditCarWashTerminalActionSelectTerminalScreen: React.FC = () => {
  const { navigate, back } = useNavigation()
  const { pointId } = useParams()
  const { result } = useQuery<PointCarWashTerminalsQueryResult>({
    query: PointCarWashTerminalsQuery,
    variables: { pointId: pointId }
  })
  const [selectedCarWashTerminalId, setSelectedCarWashTerminalId] = useState<string | null>(null)

  const submit = useCallback(() => {
    if (selectedCarWashTerminalId !== null)
      navigate(`${window.location.pathname}/${selectedCarWashTerminalId}`)
  }, [selectedCarWashTerminalId])

  if (pointId === undefined) {
    return (
      <Scaffold navTitle='Неизвестно' navCaption='Начислить фантики на пульт'>
        <View style={{ marginVertical: 'auto' }}>
          <Text style={{
            fontSize: 64,
            lineHeight: 72,
            fontWeight: '800',
            textAlign: 'center',
            marginBottom: 8,
          }}>404</Text>
          <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Произошла ошибка,<br />не найден ID точки!</Text>
        </View>
        <Button text='Назад' onPress={() => back()} style={{ width: '100%', marginTop: 'auto' }} />
      </Scaffold>
    )
  }

  if (result.data === undefined) {
    return (
      <Scaffold navTitle='Неизвестно' navCaption='Начислить фантики на пульт' >
        <View style={{ marginVertical: 'auto' }}>
          <Text style={{
            fontSize: 64,
            lineHeight: 72,
            fontWeight: '800',
            textAlign: 'center',
            marginBottom: 8,
          }}>404</Text>
          <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Произошла ошибка,<br />попробуйте перезагрузить страницу!</Text>
        </View>
        <Button text='Назад' onPress={() => back()} style={{ width: '100%', marginTop: 'auto' }} />
      </Scaffold>
    )
  }

  const generateSelectorItems = (): SelectorItemProps[] => {
    if (result.data === undefined || result.data === null)
      return [{ id: '1', label: 'result data unknown' }]

    return result.data.point.carWashTerminals.edges.map(({ node: carWashTerminal }) => {
      return {
        id: carWashTerminal.id,
        label: `Пост ${carWashTerminal.boxNumber}`
      }
    })
  }

  return (
    <View style={{
      flex: 1,
      backgroundColor: 'white',
      paddingVertical: 48,
      paddingHorizontal: 24,
      gap: 40,
    }}>
      <MobileNavbar label='Мойка' caption='Выберите пост' primaryButton='close' />
      <Selector
        items={generateSelectorItems()}
        selectedItemIdxChanged={setSelectedCarWashTerminalId}
      />
      <Button text='Подтвердить' style={{ marginTop: 'auto' }} onPress={submit} isActive={selectedCarWashTerminalId !== null} />
    </View>
  )
}

const DEFAULT_CREDIT_AMOUNT = 50

export const PointCreditCarWashTerminalActionScreen: React.FC = () => {
  const { back, setCurrentStack } = useNavigation()
  const { pointId, carWashTerminalId } = useParams()
  useThemeColor(PRIMARY_500, 'white')

  const [creditAmount, setCreditAmount] = useState<number>(DEFAULT_CREDIT_AMOUNT)
  const [_creditResult, creditTerminalMutation] = useMutation(creditCarWashTerminalMutation)
  const [error, setError] = useState<Error>(null)
  const [busy, setBusy] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const { result } = useQuery<PointCarWashTerminalsQueryResult>({
    query: PointCarWashTerminalsQuery,
    variables: { pointId: pointId }
  })

  const submit = () => {
    setBusy(true)
    setSuccess(false)
    creditTerminalMutation({ carWashTerminalId: carWashTerminalId, amount: creditAmount })
      .then(result => {
        setBusy(false)
        if (result.data === null) {
          setError('Произошла ошибка, попробуйте позже')
          return
        }

        setSuccess(true)
      })
  }

  if (error !== null) {
    return <PopOver
      background='invalid'
      heading={['Не удалось', 'перевести деньги', 'на терминал!']}
      body={[error]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={contactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  if (pointId === undefined) {
    return (
      <Scaffold navTitle='Неизвестно' navCaption='Начислить фантики на пульт' >
        <View style={{ marginVertical: 'auto' }}>
          <Text style={{
            fontSize: 64,
            lineHeight: 72,
            fontWeight: '800',
            textAlign: 'center',
            marginBottom: 8,
          }}>404</Text>
          <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Произошла ошибка,<br />не найден ID точки!</Text>
        </View>
        <Button text='Назад' onPress={() => back()} style={{ width: '100%', marginTop: 'auto' }} />
      </Scaffold>
    )
  }

  if (carWashTerminalId === undefined) {
    return (
      <Scaffold navTitle='Неизвестно' navCaption='Начислить фантики на пульт' >
        <View style={{ marginVertical: 'auto' }}>
          <Text style={{
            fontSize: 64,
            lineHeight: 72,
            fontWeight: '800',
            textAlign: 'center',
            marginBottom: 8,
          }}>404</Text>
          <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Произошла ошибка,<br />не найден ID пульта!</Text>
        </View>
        <Button text='Назад' onPress={() => back()} style={{ width: '100%', marginTop: 'auto' }} />
      </Scaffold>
    )
  }

  if (result.data === undefined) {
    return (<Scaffold navTitle='Неизвестно' navCaption='Начислить фантики на пульт' >
      <View style={{ marginVertical: 'auto' }}>
        <Text style={{
          fontSize: 64,
          lineHeight: 72,
          fontWeight: '800',
          textAlign: 'center',
          marginBottom: 8,
        }}>404</Text>
        <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Произошла ошибка!</Text>
      </View>
      <Button text='Назад' onPress={() => back()} style={{ width: '100%', marginTop: 'auto' }} />
    </Scaffold>
    )
  }

  const selectedTerminal = result.data!.point.carWashTerminals.edges.find(edge => edge.node.id == carWashTerminalId)

  if (selectedTerminal === undefined) {
    return (
      <Scaffold navTitle='Неизвестно' navCaption='Начислить фантики на пульт' >
        <View style={{ marginVertical: 'auto' }}>
          <Text style={{
            fontSize: 64,
            lineHeight: 72,
            fontWeight: '800',
            textAlign: 'center',
            marginBottom: 8,
          }}>404</Text>
          <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Произошла ошибка,<br />Не найден выбранный пульт.<br />Попробуйте ещё раз!</Text>
        </View>
        <Button text='Назад' onPress={() => back()} style={{ width: '100%', marginTop: 'auto' }} />
      </Scaffold>
    )
  }

  if (success) {
    return <PopOver
      background='success'
      heading={['Успех!']}
      body={[`На пост ${selectedTerminal.node.boxNumber} начислено ${creditAmount} рублей!`]}
    >
      <Button text='Перейти на страницу точки' onPress={() => back(2)} style={{ width: '100%', marginTop: 'auto', marginBottom: 8 }} backgroundColor='white' foregroundColor={PRIMARY_500} />
      <Button text='Перейти на панель сотрудника' onPress={() => setCurrentStack(['/employee_home'])} style={{ width: '100%' }} backgroundColor='white' foregroundColor={PRIMARY_500} />
    </PopOver>
  }

  return (
    <View style={{
      flex: 1,
      backgroundColor: PRIMARY_500,
    }}>
      <View style={{ paddingVertical: 48, paddingHorizontal: 24, gap: 32 }}>
        <MobileNavbar label='Мойка' caption={'Перевести деньги\nна терминал'} primaryButton='back' textColor='white' primaryButtonColor={PRIMARY_400} />
      </View>
      <View style={{ marginTop: 'auto', backgroundColor: PRIMARY_500, padding: 24, paddingBottom: 48, gap: 16 }}>
        <MoneyAmountSelector maxAmount={3000} setMoneyAmount={setCreditAmount} />
        <View style={{ gap: 8 }}>
          <Button text='Назад' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={back} />
          <Button text='Перевести на терминал' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={submit} busy={busy} />
        </View>
      </View>
    </View>
  )
}
