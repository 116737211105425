import React, { useState } from 'react'
import { View } from 'react-native'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'

import { useNavigation } from '../../Hooks/navigation'
import { useMutation } from 'urql'
import { useSession } from '../../Hooks/useSession'

import { CloseCarWashSessionMutation } from '../../utils/mutations'
import { CloseCarWashSessionMutationResult } from '../../utils/types'
import { CONTACT_US_PAGE, PRIMARY_400, PRIMARY_500 } from '../../const'

export const WashSessionClosedPopOverScreen: React.FC = () => {
  const { setCurrentStack } = useNavigation()
  const navigateHome = () => setCurrentStack(['/customer_home'])

  return <PopOver
    background='success'
    heading={['Мойка закончена']}
    body={['Приезжайте к нам ещё!']}
  >
    <View style={{ gap: 8 }}>
      <Button text='Перейти в панель клиента' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={navigateHome} />
    </View>
  </PopOver>
}

export const CloseWashSessionSubmitionPopOverScreen: React.FC = () => {
  const [error, setError] = useState<string | null>(null)

  const { navigate, back } = useNavigation()
  const { fetchSessionState } = useSession()
  const [_, sendCloseSessionMutation] = useMutation<CloseCarWashSessionMutationResult>(CloseCarWashSessionMutation)

  const closeWashSession = () => {
    sendCloseSessionMutation().then(result => {
      if (result.error) {
        if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503)
          return setError(result.error.graphQLErrors[0].message)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }
      else if (result.data === undefined)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')

      fetchSessionState()
      navigate('/popovers/wash_session_closed', { replace: true })
    })
  }
  const continueWashSession = () => back()

  const handleContactUs = () => window.location.href = CONTACT_US_PAGE

  if (error !== null) {
    return <PopOver
      background='invalid'
      heading={['Не удалось', 'закончить мойку']}
      body={[error]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={handleContactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={continueWashSession} />
      </View>
    </PopOver>
  }

  return <PopOver
    background='warning'
    heading={['Вы уверены?']}
    body={[
      'Остаток на терминале',
      'сгорит - вернуть его',
      'на счёт нельзя!',
      '\n',
      'Если на терминале',
      'остались деньги, мы',
      'настоятельно советуем',
      'Вам воспользоваться',
      'ими!',
    ]}
  >
    <View style={{ gap: 8 }}>
      <Button text='Закончить мойку' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={closeWashSession} />
      <Button text='Продолжить мойку' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={continueWashSession} />
    </View>
  </PopOver>
}

