import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

const UnknownScreen: React.FC = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Некорректная ссылка</Text>
      <Text style={styles.subtitle}>Код 404</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 48,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 32,
    opacity: 0.64,
    textAlign: 'center',
  }
})

export default UnknownScreen
