import React, { useState, useEffect } from 'react'
import ChoosePointScreen from './ChoosePoint'
import ChooseBoxScreen from './ChooseBox'
import SessionScreen from './Session'
import TransferMoneyToCarWashTerminalScreen from './TransferMoneyToCarWashTerminal'

import { useNavigation } from '../../Hooks/navigation'
import { useSession } from '../../Hooks/useSession'
import PopOver from '../../Components/PopOver'
import { View } from 'react-native'
import Button from '../../Components/Button'
import { PRIMARY_500 } from '../../const'
import { contactUs } from '../../utils/helperFunctions'

const StartWashRouter: React.FC = () => {
  const { session, state, error } = useSession()
  const [navigateToStack, setNavigateToStack] = useState<boolean>(false)
  const { navigateHome, setCurrentStack } = useNavigation({ navigateAutomatically: navigateToStack })

  useEffect(() => {
    if (state === 'loading')
      return

    if (state === 'error')
      return

    if (session === null)
      setCurrentStack(['/customer_home', '/customer_home/start_wash/choose_point'])
    else
      setCurrentStack(['/customer_home', '/customer_home/start_wash/session'])
    setNavigateToStack(true)
  }, [session, state])

  if (error !== null) {
    return <PopOver
      background='invalid'
      heading={['Ошибка']}
      body={[error || 'Не удалось получить данные о текущей сессии, попробуйте позже!']}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={contactUs} />
        <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={navigateHome} />
      </View>
    </PopOver>
  }

  return <></>
}

export { ChoosePointScreen, ChooseBoxScreen, SessionScreen, TransferMoneyToCarWashTerminalScreen, StartWashRouter }
