import React, { useState, useCallback, useEffect } from 'react'
import { View, Text, Image } from 'react-native'
import Scaffold from '../../Components/Scaffold'
import ToggleItem from '../../Components/ToggleItem'
import BusyIndicator from '../../Components/BusyIndicator'
import { CircleIconButton } from '../../Components/IconButton'
import { MobileNavbar } from '../../Components/Navbar'
import PopOver from '../../Components/PopOver'

import { useNavigation } from '../../Hooks/navigation'
import { useMutation } from 'urql'
import { useQuery } from '../../Hooks/useQuery'

import { PRIMARY_100, PRIMARY_500, PRIMARY_600 } from '../../const'
import { UpdateConfigurationMutation } from '../../utils/mutations'
import { ConfigurationQuery } from '../../utils/queries'
import { Configuration, Error } from '../../utils/types'
import Button from '../../Components/Button'
import { contactUs } from '../../utils/helperFunctions'

const ConfigurationScreen: React.FC = () => {
  const { back } = useNavigation()

  const [error, setError] = useState<Error>(null)

  const [canCreditCustomerMoneyAccountState, setCanCreditCustomerMoneyAccountState] = useState<boolean>(false)
  const [canCreditCustomerMoneyAccountToggleActive, setCanCreditCustomerMoneyAccountToggleActive] = useState<boolean>(false)

  const [canCreditBonusProgramMoneyAccountState, setCanCreditBonusProgramMoneyAccountState] = useState<boolean>(false)
  const [canCreditBonusProgramMoneyAccountToggleActive, setCanCreditBonusProgramMoneyAccountToggleActive] = useState<boolean>(false)

  const [canUseBonusProgramMoneyAccountBalanceState, setCanUseBonusProgramMoneyAccountBalanceState] = useState<boolean>(false)
  const [canUseBonusProgramMoneyAccountBalanceToggleActive, setCanUseBonusProgramMoneyAccountBalanceToggleActive] = useState<boolean>(false)

  const [canUseCustomerMoneyAccountBalanceState, setCanUseCustomerMoneyAccountBalanceState] = useState<boolean>(false)
  const [canUseCustomerMoneyAccountBalanceToggleActive, setCanUseCustomerMoneyAccountBalanceToggleActive] = useState<boolean>(false)

  const [advertiseBonusProgramsInCarWashPaymentFormState, setAdvertiseBonusProgramsInCarWashPaymentFormState] = useState<boolean>(false)
  const [advertiseBonusProgramsInCarWashPaymentFormToggleActive, setAdvertiseBonusProgramsInCarWashPaymentFormToggleActive] = useState<boolean>(false)

  const [_, updateConfiguration] = useMutation<{ updateConfiguration: Configuration }>(UpdateConfigurationMutation)
  const { result, state, refetchResult } = useQuery<Configuration>({ query: ConfigurationQuery, requestPolicy: 'cache-and-network' })

  useEffect(() => {
    const refetchInterval = setInterval(() => refetchResult(), 5000)
    return () => clearInterval(refetchInterval)
  }, [])

  useEffect(() => {
    if (state !== 'success' || result.data === undefined || result.error !== undefined)
      return

    setCanCreditCustomerMoneyAccountState(result.data!.configuration.canCreditCustomerMoneyAccount)
    setCanCreditCustomerMoneyAccountToggleActive(true)

    setCanCreditBonusProgramMoneyAccountState(result.data!.configuration.canCreditBonusProgramMoneyAccount)
    setCanCreditBonusProgramMoneyAccountToggleActive(true)

    setCanUseCustomerMoneyAccountBalanceState(result.data!.configuration.canUseCustomerMoneyAccountBalance)
    setCanUseCustomerMoneyAccountBalanceToggleActive(true)

    setCanUseBonusProgramMoneyAccountBalanceState(result.data!.configuration.canUseBonusProgramMoneyAccountBalance)
    setCanUseBonusProgramMoneyAccountBalanceToggleActive(true)

    setAdvertiseBonusProgramsInCarWashPaymentFormState(result.data!.configuration.advertiseBonusProgramsInCarWashPaymentForm)
    setAdvertiseBonusProgramsInCarWashPaymentFormToggleActive(true)
  }, [state, result])

  const updateCanCreditCustomerMoneyAccount = useCallback(() => {
    setCanCreditCustomerMoneyAccountState(false)
    const newState = !canCreditCustomerMoneyAccountState
    setCanCreditCustomerMoneyAccountToggleActive(false)

    updateConfiguration({ data: { canCreditCustomerMoneyAccount: newState } }).then(result => {
      console.log(result)
      if (result.error !== undefined) {
        setCanCreditCustomerMoneyAccountToggleActive(true)
        if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503 || result.error.graphQLErrors[0].extensions.code == 403)
          return setError(result.error.graphQLErrors[0].message)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }
      else if (result.data === undefined) {
        setCanCreditCustomerMoneyAccountToggleActive(true)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }

      setCanCreditCustomerMoneyAccountState(result.data.updateConfiguration.configuration.canCreditCustomerMoneyAccount)
      setCanCreditCustomerMoneyAccountToggleActive(true)
    })
  }, [canCreditCustomerMoneyAccountState, canCreditCustomerMoneyAccountToggleActive])

  const updateCanCreditBonusProgramMoneyAccount = useCallback(() => {
    setCanCreditBonusProgramMoneyAccountState(false)
    const newState = !canCreditBonusProgramMoneyAccountState
    setCanCreditBonusProgramMoneyAccountToggleActive(false)

    updateConfiguration({ data: { canCreditBonusProgramMoneyAccount: newState } }).then(result => {
      if (result.error) {
        setCanCreditBonusProgramMoneyAccountToggleActive(true)
        if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503 || result.error.graphQLErrors[0].extensions.code == 403)
          return setError(result.error.graphQLErrors[0].message)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }
      else if (result.data === undefined) {
        setCanCreditBonusProgramMoneyAccountToggleActive(true)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }

      setCanCreditBonusProgramMoneyAccountState(result.data.updateConfiguration.configuration.canCreditBonusProgramMoneyAccount)
      setCanCreditBonusProgramMoneyAccountToggleActive(true)
    })
  }, [canCreditBonusProgramMoneyAccountState, canCreditBonusProgramMoneyAccountToggleActive])

  const updateCanUseCustomerMoneyAccountBalance = useCallback(() => {
    setCanUseCustomerMoneyAccountBalanceState(false)
    const newState = !canUseCustomerMoneyAccountBalanceState
    setCanUseCustomerMoneyAccountBalanceToggleActive(false)

    updateConfiguration({ data: { canUseCustomerMoneyAccountBalance: newState } }).then(result => {
      if (result.error) {
        setCanUseCustomerMoneyAccountBalanceToggleActive(true)
        if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503 || result.error.graphQLErrors[0].extensions.code == 403)
          return setError(result.error.graphQLErrors[0].message)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }
      else if (result.data === undefined) {
        setCanUseCustomerMoneyAccountBalanceToggleActive(true)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }

      setCanUseCustomerMoneyAccountBalanceState(result.data.updateConfiguration.configuration.canUseCustomerMoneyAccountBalance)
      setCanUseCustomerMoneyAccountBalanceToggleActive(true)
    })
  }, [canUseCustomerMoneyAccountBalanceState, canUseCustomerMoneyAccountBalanceToggleActive])

  const updateCanUseBonusProgramMoneyAccountBalance = useCallback(() => {
    setCanUseBonusProgramMoneyAccountBalanceState(false)
    const newState = !canUseBonusProgramMoneyAccountBalanceState
    setCanUseBonusProgramMoneyAccountBalanceToggleActive(false)

    updateConfiguration({ data: { canUseBonusProgramMoneyAccountBalance: newState } }).then(result => {
      if (result.error) {
        setCanUseBonusProgramMoneyAccountBalanceToggleActive(true)
        if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503 || result.error.graphQLErrors[0].extensions.code == 403)
          return setError(result.error.graphQLErrors[0].message)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }
      else if (result.data === undefined) {
        setCanUseBonusProgramMoneyAccountBalanceToggleActive(true)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }

      setCanUseBonusProgramMoneyAccountBalanceState(result.data.updateConfiguration.configuration.canUseBonusProgramMoneyAccountBalance)
      setCanUseBonusProgramMoneyAccountBalanceToggleActive(true)
    })
  }, [canUseBonusProgramMoneyAccountBalanceState, canUseBonusProgramMoneyAccountBalanceToggleActive])

  const updateAdvertiseBonusProgramsInCarWashPaymentForm = useCallback(() => {
    setAdvertiseBonusProgramsInCarWashPaymentFormState(false)
    const newState = !advertiseBonusProgramsInCarWashPaymentFormState
    setAdvertiseBonusProgramsInCarWashPaymentFormToggleActive(false)

    updateConfiguration({ data: { advertiseBonusProgramsInCarWashPaymentForm: newState } }).then(result => {
      if (result.error) {
        setAdvertiseBonusProgramsInCarWashPaymentFormToggleActive(true)
        if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503 || result.error.graphQLErrors[0].extensions.code == 403)
          return setError(result.error.graphQLErrors[0].message)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }
      else if (result.data === undefined) {
        setAdvertiseBonusProgramsInCarWashPaymentFormToggleActive(true)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }

      setAdvertiseBonusProgramsInCarWashPaymentFormState(result.data.updateConfiguration.configuration.advertiseBonusProgramsInCarWashPaymentForm)
      setAdvertiseBonusProgramsInCarWashPaymentFormToggleActive(true)
    })
  }, [advertiseBonusProgramsInCarWashPaymentFormState, advertiseBonusProgramsInCarWashPaymentFormToggleActive])

  if (state === 'loading') return (
    <Scaffold showNavBar={false} gap={32}>
      <MobileNavbar label='Настройки' primaryButton='back' />
      <BusyIndicator />
    </Scaffold>
  )

  if (error !== null) {
    return <PopOver
      background='invalid'
      heading={['Ошибка']}
      body={[error || 'Произошла ошибка, попробуйте позже или обратитесь в тех. поддержку!']}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={contactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  return (
    <Scaffold showNavBar={false} gap={32}>
      <MobileNavbar label='Настройки' primaryButton='back'>
          <CircleIconButton color={PRIMARY_100} onPress={refetchResult} >
            <Image
              source={require(`../../assets/refresh-icon.png`)}
              style={{ width: 20, height: 20, tintColor: PRIMARY_600 }}
            />
          </CircleIconButton>
      </MobileNavbar>
      <View style={{ flex: 1, width: '100%' }}>
        <Text style={{ fontSize: 28, lineHeight: 32, fontWeight: '600', marginBottom: 16 }}>Денежные счета</Text>
        <ToggleItem label='Пополнять основной счёт' state={canCreditCustomerMoneyAccountState} onPress={updateCanCreditCustomerMoneyAccount} active={canCreditCustomerMoneyAccountToggleActive} />
        <ToggleItem label='Использовать средства основного счёта' state={canUseCustomerMoneyAccountBalanceState} onPress={updateCanUseCustomerMoneyAccountBalance} active={canUseCustomerMoneyAccountBalanceToggleActive} />
        <Text style={{ fontSize: 28, lineHeight: 32, fontWeight: '600', marginTop: 32, marginBottom: 16 }}>Бонусные программы</Text>
        <ToggleItem label='Пополнять бонусный счёт' state={canCreditBonusProgramMoneyAccountState} onPress={updateCanCreditBonusProgramMoneyAccount} active={canCreditBonusProgramMoneyAccountToggleActive} />
        <ToggleItem label='Использовать средства бонусного счёта' state={canUseBonusProgramMoneyAccountBalanceState} onPress={updateCanUseBonusProgramMoneyAccountBalance} active={canUseBonusProgramMoneyAccountBalanceToggleActive} />
        <ToggleItem label='Рекламировать бонусные программы' state={advertiseBonusProgramsInCarWashPaymentFormState} onPress={updateAdvertiseBonusProgramsInCarWashPaymentForm} active={advertiseBonusProgramsInCarWashPaymentFormToggleActive} />
      </View>
    </Scaffold>
  )
}

export default ConfigurationScreen
