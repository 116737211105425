import React, { useState } from 'react'
import { View, Text } from 'react-native'
import MobileNavbar from '../../Components/Navbar/MobileNavbar'
import Selector, { SelectorItemProps } from '../../Components/Selector'
import Button from '../../Components/Button'
import BusyIndicator from '../../Components/BusyIndicator'
import PopOver from '../../Components/PopOver'

import { useMutation } from 'urql'
import { useQuery } from '../../Hooks/useQuery'
import { useNavigation } from '../../Hooks/navigation'
import { useParams } from 'react-router-dom'
import { useSession } from '../../Hooks/useSession'

import { PointsForSessionQuery } from '../../utils/queries'
import { StartCarWashSessionMutation } from '../../utils/mutations'
import { PointsForSessionQueryResult, StartCarWashSessionMutationResult } from '../../utils/types'
import { CONTACT_US_PAGE, PRIMARY_500 } from '../../const'

const ChooseBoxScreen: React.FC = () => {
  const { navigate, back } = useNavigation()

  const [selectedCarWashTerminalId, setSelectedCarWashTerminalId] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [busy, setBusy] = useState<boolean>(false)

  const { selectedPointId } = useParams()
  const { fetchSessionState } = useSession()
  const { result, state } = useQuery<PointsForSessionQueryResult>({ query: PointsForSessionQuery })
  const [_, sendStartSessionMutation] = useMutation<StartCarWashSessionMutationResult>(StartCarWashSessionMutation)

  const submit = () => {
    if (selectedCarWashTerminalId === null)
      return

    setBusy(true)

    sendStartSessionMutation({ carWashTerminalId: selectedCarWashTerminalId }).then(result => {
      if (result.error) {
        setBusy(false)
        if (result.error.graphQLErrors[0].extensions.code == 400 || result.error.graphQLErrors[0].extensions.code == 503)
          return setError(result.error.graphQLErrors[0].message)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }
      else if (result.data === undefined) {
        setBusy(false)
        return setError('Неизвестная ошибка, попробуйте ещё раз!')
      }

      setBusy(false)
      fetchSessionState()
      navigate('/popovers/wash_started', { replace: true })
    })
  }

  const handleContactUs = () => {
    window.location.href = CONTACT_US_PAGE
  }

  if (error !== null) {
    return <PopOver
      background='invalid'
      heading={['Не удалось', 'начать мойку']}
      body={[error]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={handleContactUs} />
        <Button text='Назад' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  if (state === 'loading') {
    return <View style={{
      flex: 1,
      backgroundColor: 'white',
      paddingVertical: 48,
      paddingHorizontal: 24,
      gap: 128,
    }}>
      <MobileNavbar label='Мойка' caption='Выберите пост' primaryButton='close' />
      <View style={{ gap: 24 }}>
        <BusyIndicator />
        <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Загрузка</Text>
      </View>
    </View>
  }

  if (result.data === null || result.data === undefined) {
    return <View style={{
      flex: 1,
      backgroundColor: 'white',
      paddingVertical: 48,
      paddingHorizontal: 24,
      gap: 128,
    }}>
      <MobileNavbar label='Мойка' caption='Выберите пост' primaryButton='close' />
      <View>
        <Text style={{ fontSize: 64, lineHeight: 72, fontWeight: '800', textAlign: 'center', marginBottom: 8 }}>500</Text>
        <Text style={{ fontSize: 24, lineHeight: 24, textAlign: 'center' }}>Произошла ошибка!</Text>
      </View>
      <Button text="Связаться с нами" onPress={handleContactUs} style={{ marginTop: 'auto' }} />
    </View>
  }

  const generateSelectorItems = (): SelectorItemProps[] => {
    if (result.data === undefined || result.data === null)
      return [{ id: '1', label: 'result data unknown' }]

    const selectedPoint = result.data.points.edges.find(edge => edge.node.id === selectedPointId)
    if (selectedPoint === undefined)
      return [{ id: '1', label: 'selectedPoint unknown' }]

    return selectedPoint.node.carWashTerminals.edges.map(({ node: carWashTerminal }) => {
      return {
        id: carWashTerminal.id,
        label: `Пост ${carWashTerminal.boxNumber}`
      }
    })
  }

  return (
    <View style={{
      flex: 1,
      backgroundColor: 'white',
      paddingVertical: 48,
      paddingHorizontal: 24,
      gap: 40,
    }}>
      <MobileNavbar label='Мойка' caption='Выберите пост' primaryButton='close' />
      <Selector
        items={generateSelectorItems()}
        selectedItemIdxChanged={setSelectedCarWashTerminalId}
      />
      <Button text='Подтвердить' style={{ marginTop: 'auto' }} onPress={submit} isActive={selectedCarWashTerminalId !== null} busy={busy} />
    </View>
  )
}

export default ChooseBoxScreen
