import React, { useState, useEffect } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { useNavigation } from '../../Hooks/navigation'
import { useQuery } from '../../Hooks/useQuery'

import Button from '../../Components/Button'
import { PRIMARY_500, NEUTRAL_90, PRIMARY_100, PRIMARY_400, PRIMARY_300 } from '../../const'
import { UserAuthorizationInfoQuery } from '../../utils/queries'
import { ActivateUserAuthorizationSessionMutationResult, UserAuthorizationInfoQueryResult } from '../../utils/types'
import useRefreshingQuery from '../../Hooks/useRefreshingQuery'
import PopOver from '../../Components/PopOver'
import { useMutation } from 'urql'
import { activateUserAuthorizationSessionMutation } from '../../utils/mutations'
import BusyIndicator from '../../Components/BusyIndicator'

const VERIFICATION_DECLINED_ERROR_MSG = [
  'В данным момент',
  'производится паралельная',
  'попытка входа в ЛК по',
  'данному номеру телефона.',
  'Скорее всего это Вы!',
  '\n',
  'Пожалуйста, попробуйте',
  'чуть позже или, если это',
  'не Вы - обратитесь к нам',
  'за помощью по кнопке ниже!'
]

const VERIFICATION_FAILED_ERROR_MSG = [
  'Не удалось проверить',
  'Ваш номер телефона!',
  '\n',
  'Пожалуйста,',
  'попробуйте заного',
  'используя кнопку',
]

const CONNECTION_ERROR_MSG = [
  'Не удаётся',
  'подключиться к серверу.',
  '\n',
  'Проверьте подключение',
  'к интернету, попробуйте',
  'перезагрузить страницу',
  'или обратитесь к нам',
  'за помощью, используя',
  'кнопку ниже!'
]

const UNKNOWN_ERROR_MSG = [
  'Произошла неожиданная',
  'для нас ошибка!',
  '\n',
  'Попробуйте перезагрузить',
  'страницу - обычно это',
  'помогает. Либо обратитесь',
  'к нам за помощью',
  'по кнопке ниже!',
]

const STRANGE_RESPONSE_ERROR_MSG = [
  'Получен неожиданный',
  'ответ от сервера!',
  '\n',
  'Попробуйте перезагрузить',
  'страницу - обычно это',
  'помогает. Либо обратитесь',
  'к нам за помощью',
  'по кнопке ниже!',
]

type PUSH_STEP = 'WaitingForPush' | 'WaitingForResult' | 'WaitingForSmsOtp'

const PushScreen: React.FC = () => {
  const { navigate, back } = useNavigation()
  const [error, setError] = useState<string[] | null>(null)
  const [state, setState] = useState<PUSH_STEP>('WaitingForPush')

  const [_, activateUserAuthorizationSession] = useMutation<ActivateUserAuthorizationSessionMutationResult>(activateUserAuthorizationSessionMutation)

  const userAuthorizationInfo = useRefreshingQuery<UserAuthorizationInfoQueryResult>(
    UserAuthorizationInfoQuery, { sessionId: localStorage.getItem('userAuthorizationSessionId') }, 1, false
  )

  useEffect(() => {
    if (userAuthorizationInfo.fetching)
      return

    if (userAuthorizationInfo.data?.userAuthorizationInfo.verificationMethod == 'SMS_OTP') {
      navigate('/authentication/sms_otp', { replace: true })
    }

    switch (userAuthorizationInfo.data?.userAuthorizationInfo.status) {
      case 'VERIFICATION_DECLINED':
        setError(VERIFICATION_DECLINED_ERROR_MSG)
        break
      case 'VERIFICATION_EXPIRED':
        navigate('/authentication/phone_number', { replace: true })
        break
      case 'CREATED_EXPIRED':
        navigate('/authentication/phone_number', { replace: true })
        break
      case 'CLOSED_BY_USER':
        navigate('/authentication/phone_number', { replace: true })
        break
      case 'CLOSED_BY_ADMIN':
        navigate('/authentication/phone_number', { replace: true })
        break
      case 'VERIFICATION_FAILED':
        setError(VERIFICATION_FAILED_ERROR_MSG)
        break
    }

    if (userAuthorizationInfo.data?.userAuthorizationInfo.status == 'VERIFICATION_EXPIRED') {
      console.log('HANDLE VERIFICATION EXPIRED')
      return
    }

    if (userAuthorizationInfo.data?.userAuthorizationInfo.status == 'VERIFICATED') {
      activateUserAuthorizationSession({ sessionId: localStorage.getItem('userAuthorizationSessionId') }).then(result => {
        if (result.error) {
          if (result.error.networkError != undefined) {
            setError(CONNECTION_ERROR_MSG)
            return
          }

          setError(UNKNOWN_ERROR_MSG)
          return 
        }

        if (result.data === undefined || result.data.activateUserAuthorizationSession.accessToken === undefined || result.data?.activateUserAuthorizationSession.refreshToken === undefined) {
          setError(STRANGE_RESPONSE_ERROR_MSG)
          return 
        }

        localStorage.setItem('accessToken', result.data!.activateUserAuthorizationSession.accessToken)
        localStorage.setItem('refreshToken', result.data!.activateUserAuthorizationSession.refreshToken)
        back()
      })
      return
    }
  }, [userAuthorizationInfo])

  useEffect(() => {
    const resultTimer = setTimeout(() => {
      setState('WaitingForResult')
    }, 5000)
    const smsOtpTimer = setTimeout(() => {
      setState('WaitingForSmsOtp')
    }, 20000)
    return () => {
      clearTimeout(resultTimer)
      clearTimeout(smsOtpTimer)
    }
  }, [])

  const needHelp = () => navigate('/popovers/authentication_help')
  const getHelp = () => window.location.href = 'https://t.me/moite_sami'
  const tryAgain = () => navigate('/authentication/phone_number', { replace: true })

  if (error !== null) {
    return <PopOver
      heading={['Ошибка', 'авторизации']} 
      body={error}
      background='invalid'
    >
      <View style={{ gap: 8 }}>
        <Button text='Попробовать ещё раз' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={tryAgain} />
        <Button text='Обратиться за помощью' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={getHelp} />
      </View>
    </PopOver>
  }

  if (state == 'WaitingForPush') {
    return (
      <View style={styles.container}>
        <View style={{ gap: 16 }}>
          <View style={{ width: '100%', alignItems: 'flex-start' }}>
            <BusyIndicator size={24} />
          </View>
          <View>
            <Text style={styles.title}>Проверьте экран</Text>
            <Text style={styles.title}>Вашего телефона</Text>
          </View>
          <View>
            <Text style={styles.subtitle}>В течении 5-и секунд</Text>
            <Text style={styles.subtitle}>должнен появится интрефейс</Text>
            <Text style={styles.subtitle}>для подтверждения авторизации</Text>
            <Text>
              <Text style={styles.subtitle}>в приложении </Text>
              <Text style={[styles.subtitle, { fontWeight: "bold" }]}>"Мойте сами - ЧИСТОСТРАНА".</Text>
            </Text>
            <View style={{ height: 25 }} />
            <View>
              <Text style={styles.subtitle}>Мы отправили сообщение на</Text>
              <Text>
                <Text style={styles.subtitle}>номер </Text>
                <Text style={[styles.subtitle, { fontWeight: "bold" }]}>{localStorage.getItem('phoneNumber') || 'Ошибка'}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.form}>
          <Button text="Мне нужна помощь!" backgroundColor={PRIMARY_100} foregroundColor={PRIMARY_500} onPress={needHelp} />
        </View>
        {
          error !== null
            ? <Text style={styles.errorMessage}>{error}</Text>
            : <></>
        }
      </View>
    )
  }

  else if (state == 'WaitingForResult') {
    return (
      <View style={styles.container}>
        <View style={{ gap: 16 }}>
          <View style={{ width: '100%', alignItems: 'flex-start' }}>
            <BusyIndicator size={24} />
          </View>
          <View>
            <Text style={styles.title}>Подождите</Text>
            <Text style={styles.title}>несколько секунд</Text>
          </View>
          <View>
            <Text style={styles.subtitle}>Ждём подтверждения</Text>
            <Text style={styles.subtitle}>от сервера об успешной</Text>
            <Text style={styles.subtitle}>авторизации в приложении</Text>
            <Text style={[styles.subtitle, { fontWeight: "bold" }]}>"Мойте сами - ЧИСТОСТРАНА".</Text>
            <View style={{ height: 25 }} />
            <Text style={styles.subtitle}>Ожидание может</Text>
            <Text style={styles.subtitle}>составить до 10 секунд!</Text>
          </View>
        </View>
        <View style={styles.form}>
          <Button text="Мне нужна помощь!" backgroundColor={PRIMARY_100} foregroundColor={PRIMARY_500} onPress={needHelp} />
        </View>
      </View>
    )
  }

  else if (state == 'WaitingForSmsOtp') {
    return (
      <View style={styles.container}>
        <View style={{ gap: 16 }}>
          <View style={{ width: '100%', alignItems: 'flex-start' }}>
            <BusyIndicator size={24} />
          </View>
          <View>
            <Text style={styles.title}>Подождите</Text>
            <Text style={styles.title}>ещё чуть чуть</Text>
          </View>
          <View>
            <Text style={styles.subtitle}>Видимо, возникла ошибка.</Text>
            <View style={{ height: 16 }} />
            <Text style={styles.subtitle}>Через несколько секунд</Text>
            <Text style={styles.subtitle}>пришлём СМС с кодом авторизации</Text>
            <Text>
              <Text style={styles.subtitle}>на телефон </Text>
              <Text style={[styles.subtitle, { fontWeight: 'bold' }]}>{localStorage.getItem('phoneNumber')}</Text>
            </Text>
            <Text style={styles.subtitle}>для авторизации в приложении</Text>
            <Text style={[styles.subtitle, { fontWeight: "bold" }]}>"Мойте сами - ЧИСТОСТРАНА".</Text>
            <View style={{ height: 25 }} />
            <Text style={styles.subtitle}>Ожидание может</Text>
            <Text style={styles.subtitle}>составить до 30 секунд!</Text>
          </View>
        </View>
        <View style={styles.form}>
          <Button text="Мне нужна помощь!" backgroundColor={PRIMARY_100} foregroundColor={PRIMARY_500} onPress={needHelp} />
        </View>
      </View>
    )
  }

  return <></>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 96,
    gap: 24,
  },
  title: {
    fontSize: 36,
    lineHeight: 40,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 20,
    lineHeight: 25,
    weight: 300,
    color: NEUTRAL_90,
  },
  form: {
    gap: 8,
  },
  errorMessage: {
    color: PRIMARY_500,
    fontSize: 16,
    lineHeight: 20,
    alignText: 'center',
  },
  caption: {
    fontSize: 12,
    lineHeight: 16,
    color: NEUTRAL_90
  }
})

export default PushScreen
