export const startUserAuthorizationMutation = `
mutation StartUserAuthorization($phoneNumber: String!) {
    startUserAuthorization(phoneNumber: $phoneNumber) {
        userAuthorizationSessionId
    }
}
`

export const activateUserAuthorizationSessionMutation = `
mutation ActivateUserAuthorizationSession($sessionId: String!) {
    activateUserAuthorizationSession(userAuthorizationSessionId: $sessionId) {
        accessToken
        refreshToken
    }
}
`

export const verifySMSOTPMutation = `
mutation VerifySMSOTP($sessionId: String!, $otp: String!) {
  verifySmsOtp(userAuthorizationSessionId: $sessionId, otp: $otp) {
    success
  }
}
`

export const closeUserAuthorizationSessionMutation = `
mutation CloseUserAuthorizationSession {
	closeUserAuthorizationSession {
    success
  }
}
`

export const refreshAccessTokenMutation = `
mutation RefreshAccessToken($refreshToken: String!) {
  refreshAccessToken(refreshToken: $refreshToken) {
    accessToken
  }
}
`

export const requestOTPMutation = `
mutation RequestOTP($phoneNumber: String!) {
  requestOtp(phoneNumber: $phoneNumber) {
    phoneNumber
  }
}
`

export const verifyOTPMutation = `
mutation VerifyOTP($phoneNumber: String!, $otp: String!) {
  verifyOtp(phoneNumber: $phoneNumber, otp: $otp) {
    accessToken
    refreshToken
  }
}
`

export const openLockMutation = `
mutation OpenLock($lockId: ID!) {
  openLock(lockId: $lockId) {
    success
  }
}
`

export const initializePaymentForCarWash = `
mutation InitializePaymentForCarWash($carWashTerminalId: ID!, $amount: Int!, $email: String) {
  initializePaymentForCarwash(carWashTerminalId: $carWashTerminalId, amount: $amount, email: $email) {
    paymentFormUrl
  }
}
`

export const creditCarWashTerminalMutation = `
mutation CreditCarWashTerminal($carWashTerminalId: ID!, $amount: Int!) {
  creditCarWashTerminal(carWashTerminalId: $carWashTerminalId, amount: $amount) {
    success
  }
}`

export const updatePointMutation = `
mutation UpdatePoint($id: ID!, $data: UpdatePointInput!) {
  updatePoint(id: $id, data: $data) {
    point {
      isCashlessPaymentActive
      isYandexCarwashActive
    }
  }
}`

export const StartCarWashSessionMutation = `
mutation StartCarWashSession($carWashTerminalId: ID!) {
  startCarWashSession(carWashTerminalId: $carWashTerminalId) {
    session {
      id
      carWashTerminal {
        id
        boxNumber
      }
    }
  }
}`

export const CloseCarWashSessionMutation = `
mutation CloseCarWashSession {
  closeCarWashSession {
    session {
      id
      carWashTerminal {
        id
        boxNumber
      }
    }
  }
}`

export const TransferMoneyToCarWashTerminalMutation = `
mutation TransferMoneyToCarWashTerminal($moneyAccountId: ID!, $amount: Int!, $carWashTerminalId: ID!) {
	transferMoneyToCarWashTerminal(
    moneyAccountId: $moneyAccountId
    amount: $amount
    carWashTerminalId: $carWashTerminalId
  ) {
    carWashTerminalCredit {
      id
    }
  }
}`

export const CreditCustomerMoneyAccountMutation = `
mutation CreditCustomerMoneyAccount($amount: Int!, $successUrl: String) {
  creditCustomerMoneyAccount(amount: $amount, successUrl: $successUrl) {
    paymentFormUrl
  }
}`

export const SetUserNotifiedAboutMoneyAccountChangesMutation = `
mutation SetUserNotifiedAboutMoneyAccountChanges($moneyAccountIds: [ID]!) {
  setUserNotifiedAboutMoneyAccountChanges(moneyAccountIds: $moneyAccountIds) {
    success
  }
}`

export const UpdateConfigurationMutation = `
mutation UpdateConfiguration($data: ConfigurationNodeInput) {
  updateConfiguration(data: $data) {
    configuration {
      canCreditCustomerMoneyAccount
      canCreditBonusProgramMoneyAccount
      canUseCustomerMoneyAccountBalance
      canUseBonusProgramMoneyAccountBalance
      advertiseBonusProgramsInCarWashPaymentForm
    }
  }
}`
