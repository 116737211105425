import React from 'react'
import { View, Text, Image } from 'react-native'
import Widget from '../Components/Widget'
import BusyIndicator from '../Components/BusyIndicator'
import Button from '../Components/Button'
import { CircleIconButton } from '../Components/IconButton'
import { NEUTRAL_200 } from '../const'

import { useQuery } from '../Hooks/useQuery'
import { useNavigation } from '../Hooks/navigation'

import { AnalyticsWidgetDataQuery } from '../utils/queries'
import { AnalyticsWidgetDataQueryResult } from '../utils/types'

const AnalyticsWidget: React.FC = () => {
  const { navigate } = useNavigation()

  const dateToISO = (date: Date): string => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })}-${date.getDate().toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })}`
  }

  const getToday = () => dateToISO(new Date())
  const getYesterday = () => {
    const result = new Date()
    result.setDate(result.getDate() - 1)
    return dateToISO(result)
  }

  const { state, error, result, refetchResult } = useQuery<AnalyticsWidgetDataQueryResult>({
    query: AnalyticsWidgetDataQuery,
    variables: {
      yesterdayTimestamp_Gte: getYesterday() + 'T00:00:00+03:00',
      yesterdayTimestamp_Lte: getYesterday() + 'T23:59:59+03:00',
      todayTimestamp_Gte: getToday() + 'T00:00:00+03:00',
      todayTimestamp_Lte: getToday() + 'T23:59:59+03:00',
    }
  })

  const refresh = () => refetchResult({ requestPolicy: 'network-only' })

  if (state === 'loading') {
    return (
      <Widget>
        <Text style={{ fontSize: 24, lineHeight: 24, fontWeight: '500' }}></Text>
        <BusyIndicator />
      </Widget>
    )
  }

  if (state === 'error' || result.data == null) {
    return (
      <Widget>
        <Text style={{ fontSize: 24, lineHeight: 24, fontWeight: '500' }}>Оплата картой</Text>
        <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '300' }}>Не удалось загрузить данные</Text>
        <Button text='Попробовать ещё раз' onPress={refetchResult} />
      </Widget>
    )
  }

  return (
    <Widget onPress={() => navigate('/employee_home/analytics')}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text style={{ fontSize: 24, lineHeight: 24, fontWeight: '500' }}>Оплата картой</Text>
        <CircleIconButton color={NEUTRAL_200} onPress={refresh} >
          <Image
            source={require(`../assets/refresh-icon.png`)}
            style={{ width: 20, height: 20, tintColor: 'white' }}
          />
        </CircleIconButton>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>За сегодня:</Text>
          <View style={{ height: 8 }} />
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>Сумма: {result.data.todayAnalytics.totalAmount || 0} р.</Text>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>С/А: {result.data.todayAnalytics.averageAmount || 0} р.</Text>
          <View style={{ height: 8 }} />
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>QR-Код: {result.data.todayAnalytics.purposeRatio.creditCarWashTerminal || 0}%</Text>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>Сайт: {result.data.todayAnalytics.purposeRatio.creditCustomerMoneyAccount || 0}%</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>За вчера:</Text>
          <View style={{ height: 8 }} />
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>Сумма: {result.data.yesterdayAnalytics.totalAmount || 0} р.</Text>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>С/А: {result.data.yesterdayAnalytics.averageAmount || 0} р.</Text>
          <View style={{ height: 8 }} />
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>QR-Код: {result.data.yesterdayAnalytics.purposeRatio.creditCarWashTerminal || 0}%</Text>
          <Text style={{ fontSize: 20, lineHeight: 24, fontWeight: '400' }}>Сайт: {result.data.yesterdayAnalytics.purposeRatio.creditCustomerMoneyAccount || 0}%</Text>
        </View>
      </View>
    </Widget>
  )
}

export default AnalyticsWidget
