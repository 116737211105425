import React, { useState, useEffect } from 'react'
import { Dimensions, View } from 'react-native'
import Button from '../../Components/Button'
import PopOver from '../../Components/PopOver'

import { useNavigation } from '../../Hooks/navigation'

import { PRIMARY_300, PRIMARY_400, PRIMARY_500 } from '../../const'
import { useBonusProgramsConfiguration, useEnsureCarWashTerminalCreditSucceeded, useMoneyAccountBalances, useTransferMoneyToCarWashTerminal } from '../../Hooks/moneyAccounts'
import BusyIndicator from '../../Components/BusyIndicator'
import { contactUs } from '../../utils/helperFunctions'
import { Error } from '../../utils/types'
import { useSession } from '../../Hooks/useSession'

const { width } = Dimensions.get('window')

export const BonusProgramForPoorPopOverScreen: React.FC = () => {
  const { back } = useNavigation()
  const {
    bonusProgramsConfiguration,
    fetchBonusProgramsConfiguration,
    state: bonusProgramConfigurationFetchingState,
    error: bonusProgramConfigurationFetchingError,
  } = useBonusProgramsConfiguration()
  const {
    session,
    fetchSessionState,
    state: sessionFetchingState,
    error: sessionFetchingError,
  } = useSession()
  const { moneyAccountBalances } = useMoneyAccountBalances()
  const { bonusProgramForPoor } = moneyAccountBalances

  const [state, setState] = useState<'error' | 'loading' | 'notPermitted' | 'warning' | 'success' | 'hasNoFreeWash'>('loading')
  const [error, setError] = useState<Error>(null)
  const [carWashTerminalCreditId, setCarWashTerminalCreditId] = useState<string | undefined>()

  const [ensureCarWashTerminalCreditSucceeded, creditEnsureState, ensureError] = useEnsureCarWashTerminalCreditSucceeded(carWashTerminalCreditId)
  const [transferMoney, isTransferingMoney, transferError] = useTransferMoneyToCarWashTerminal((result) => {
    setCarWashTerminalCreditId(result.transferMoneyToCarWashTerminal.carWashTerminalCredit.id)
  }, 'BONUS_PROGRAM_FOR_POOR')

  useEffect(() => {
    console.log(carWashTerminalCreditId, transferError)
    if (carWashTerminalCreditId === undefined || transferError !== null)
      return

    setState('loading')
    ensureCarWashTerminalCreditSucceeded()
  }, [carWashTerminalCreditId])

  useEffect(() => {
    console.log('creditEnsureState', creditEnsureState)
    console.log('ensureError', ensureError)
    if (creditEnsureState === 'error') {
      setError(ensureError)
      setState('error')
    }
    else if (creditEnsureState === 'success') {
      setState('success')
    }
  }, [creditEnsureState, ensureError])

  useEffect(() => {
    setError(transferError)
    setState('error')
  }, [transferError])

  useEffect(() => {
    fetchBonusProgramsConfiguration()
    fetchSessionState()
  }, [])

  useEffect(() => {
    if (
      bonusProgramConfigurationFetchingState === 'loading' || bonusProgramConfigurationFetchingState === 'none'
      || sessionFetchingState === 'loading' || sessionFetchingState === 'none'
    ) {
      setState('loading')
      return
    }

    if (bonusProgramConfigurationFetchingState === 'error' || sessionFetchingState === 'error') {
      setError(bonusProgramConfigurationFetchingError || sessionFetchingState)
      return
    }

    permitBonusProgramForPoorUsage()
  }, [bonusProgramsConfiguration, bonusProgramConfigurationFetchingState, sessionFetchingState, sessionFetchingError])

  const permitBonusProgramForPoorUsage = () => {
    if (bonusProgramsConfiguration === undefined || bonusProgramForPoor === undefined) return
    
    if (bonusProgramForPoor < 1) {
      setState('hasNoFreeWash')
      return
    }

    // const currentHour = new Date().getHours()
    // const permitted = currentHour < bonusProgramsConfiguration.bonusProgramForPoor.activeTo && currentHour >= bonusProgramsConfiguration.bonusProgramForPoor.activeFrom
    const permitted = true
    setState(() => permitted ? 'warning' : 'notPermitted')
  }

  const submitBonusProgramForPoorUsage = () => {
    if (session === undefined || bonusProgramsConfiguration === undefined) return
    transferMoney(bonusProgramsConfiguration.bonusProgramForPoor.initialCreditAmount, session.carWashTerminal.id)
  }

  if (state === 'error') {
    return <PopOver
      background='invalid'
      heading={['Ошибка!']}
      body={[error || 'Произошла ошибка, попробуйте позже или обратитесь в тех. поддержку!']}
    >
      <View style={{ gap: 8 }}>
        <Button text='Назад' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={back} />
        <Button text='Техническая поддержка' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={contactUs} />
        <Button text='Попробовать ещё раз' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={() => window.location.reload()} />
      </View>
    </PopOver>
  }

  if (state === 'hasNoFreeWash') {
    return <PopOver
      background='bonus'
      heading={['Бесплатная', 'ночная мойка!']}
      body={[
        'Пополните счёт',
        'на 200 рублей или',
        'больше в течении суток',
        'и получите бесплатную',
        'ночную мойку!*',
      ]}
      caption={[
        '*По правилам данной бонусной программы',
        'для одной стандартной мойки на терминал',
        'начисляется 200 бонусных рублей.',
      ]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  if (state === 'loading') {
    return <PopOver
      heading={[]}
      body={[]}
    >
      <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', zIndex: -1 }}>
        <BusyIndicator size={width * 1.5} color={PRIMARY_300} />
      </View>
    </PopOver>
  }

  if (state === 'notPermitted') {
    return <PopOver
      background='invalid'
      heading={['Бесплатно', 'только ночью!']}
      body={[
        'Использовать',
        'бесплатную мойку по',
        'бонусной программе',
        '“Для нищебродов”',
        'можно только ночью -',
        `с ${bonusProgramsConfiguration?.bonusProgramForPoor.activeFrom.toLocaleString('ru-RU', { minimumIntegerDigits: 2 })}:00 до ${bonusProgramsConfiguration?.bonusProgramForPoor.activeTo.toLocaleString('ru-RU', { minimumIntegerDigits: 2 })}:00`,
      ]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  if (state === 'warning') {
    return <PopOver
      background='warning'
      heading={['Возврат на счёт', 'не предусмотрен!']}
      body={[
        'После активации',
        'бонусной мойки на',
        'терминале, отменить',
        'операцию или сохранить',
        'остаток на счёт будет',
        'невозможно*!',
      ]}
      caption={[
        '*По правилам данной бонусной программы',
        'для одной стандартной мойки на терминал',
        'начисляется 200 бонусных рублей.',
      ]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Отмена' backgroundColor={PRIMARY_400} foregroundColor='white' onPress={back} />
        <Button text='Использовать бесплатную мойку' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={submitBonusProgramForPoorUsage} busy={isTransferingMoney} />
      </View>
    </PopOver>
  }

  if (state === 'success') {
    return <PopOver
      background='bonus'
      heading={['Бесплатная мойка', 'активирована!']}
      body={[
        'На терминал',
        'зачислено 200 рублей.',
        'Приятной мойки!',
      ]}
    >
      <View style={{ gap: 8 }}>
        <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
      </View>
    </PopOver>
  }

  return <></>
}

export const BonusesForPoorOnlyAtNightPopOverScreen: React.FC = () => {
  const { back, navigateHome } = useNavigation()
  const { bonusProgramsConfiguration, state, error } = useBonusProgramsConfiguration()

  if (state === 'loading') {
    return <PopOver
      heading={[]}
      body={[]}
    >
      <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', zIndex: -1 }}>
        <BusyIndicator size={width * 1.5} color={PRIMARY_300} />
      </View>
    </PopOver>
  }

  if (error !== null) {
    return <PopOver
      background='invalid'
      heading={['Ошибка']}
      body={[error || 'Не удалось получить данные о текущей сессии, попробуйте позже!']}
    >
      <View style={{ gap: 8 }}>
        <Button text='Техническая поддержка' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={contactUs} />
        <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={navigateHome} />
      </View>
    </PopOver>
  }

  return <PopOver
    background='invalid'
    heading={['Бесплатно', 'только ночью!']}
    body={[
      'Использовать',
      'бесплатную мойку по',
      'бонусной программе',
      '“Для нищебродов”',
      'можно только ночью -',
      `с ${bonusProgramsConfiguration?.bonusProgramForPoor.activeFrom.toLocaleString('ru-RU', { minimumIntegerDigits: 2 })}:00 до ${bonusProgramsConfiguration?.bonusProgramForPoor.activeTo.toLocaleString('ru-RU', { minimumIntegerDigits: 2 })}:00`,
    ]}
  >
    <View style={{ gap: 8 }}>
      <Button text='Понятно' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={back} />
    </View>
  </PopOver>
}


export const BonusesPopOverScreen: React.FC = () => {
  const { navigate, back } = useNavigation()

  const navigateToCustomerHome = () => back()
  const navigateToBonusList = () => navigate('/customer_home/bonus_list', { replace: true })

  return <PopOver
    background='bonus'
    heading={['Бонусные', 'Программы']}
    body={[
      'В нашем приложении',
      'есть бонусные программы,',
      'позвольте нам рассказать',
      'о них.',
    ]}
    onPressNavButton={navigateToCustomerHome}
  >
    <View style={{ gap: 8 }}>
      <Button text='Перейти в панель клиента' backgroundColor={PRIMARY_400} onPress={navigateToCustomerHome} />
      <Button text='Узнать о них' backgroundColor='white' foregroundColor={PRIMARY_500} onPress={navigateToBonusList} />
    </View>
  </PopOver>
}

