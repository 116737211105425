import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'urql'

const openGateQuery = `
mutation OpenGate($gateId: Int!) {
  openGate(gateId: $gateId) {
    fakeAuthorizationTime
  }
}
`

type UseUnlockPassageState = {
  gateId: string | undefined;
  state: 'authorizing' | 'success' | 'error';
  error: undefined | { title: string; subtitle: string };
}

export const useUnlockPasssage = (): UseUnlockPassageState => {
  const { gateId } = useParams()
  console.log(gateId)
  const [hasSuccess, setHasSuccess] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorTitle, setErrorTitle] = useState('')
  const [errorSubtitle, setErrorSubtitle] = useState('')

  const handleIncorrectPassageId = useCallback(() => {
    setHasError(true)
    setErrorTitle("Некорректная ссылка!")
    setErrorSubtitle("Въезд не найден.")
  }, [setHasError, setErrorTitle, setErrorSubtitle])

  const handleServerError = useCallback(() => {
    setHasError(true)
    setErrorTitle("Ошибка сервера!")
    setErrorSubtitle("Свяжитесь с нами, используя кнопку ниже!")
  }, [setHasError, setErrorTitle, setErrorSubtitle])

  useEffect(() => {
    /// Ensure, that gateId is defined and has only numeric characters
    if (gateId === undefined || /[^\d]/.test(gateId)) handleIncorrectPassageId()
  }, [gateId, handleIncorrectPassageId])

  // Make request to the HozyainAPI to unlock gate
  const [_, requestOpenGate] = useMutation(openGateQuery)
  useEffect(() => {
    const variables = { gateId }
    requestOpenGate(variables).then(result => {
      if (result.error) {
        handleServerError()
        return
      }

      setTimeout(() => {
        if (hasError) return
        setHasSuccess(true)
      }, result.data.openGate.fakeAuthorizationTime * 1000)
    })
  }, [requestOpenGate, hasError, gateId, handleServerError])

  let state: 'authorizing' | 'success' | 'error' = 'authorizing'
  if (hasError) state = 'error'
  else if (hasSuccess) state = 'success'
  else state = 'authorizing'

  return {
    gateId,
    state,
    error: hasError ? { title: errorTitle, subtitle: errorSubtitle } : undefined
  }
}
