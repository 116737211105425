import React from 'react'
import { View, Text } from 'react-native';
import { styles } from '.';

interface ErrorScreenProps {
  title: string;
  subtitle: string;
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({ title, subtitle }) => {
  return (
    <View style={[styles.textPart, { flex: 1 }]}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.subtitle}>{subtitle}</Text>
    </View>
  )
}

export default ErrorScreen
