export const PRIMARY_600: string = "#FF153A"
export const PRIMARY_500: string = "#D90023"
export const PRIMARY_400: string = "#E71A3B"
export const PRIMARY_300: string = "#FC617A"
export const PRIMARY_200: string = "#FC8397"
export const PRIMARY_100: string = "#FFDEE3"
export const PRIMARY_100_OPACITY: string = "rgb(252, 131, 151, 0.5)";
export const GREEN_500: string = "#6ce965"
export const WHITE: string = "#ffffff"
export const NEUTRAL_90: string = "#191919"
export const NEUTRAL_100: string = "#F2F2F2"
export const NEUTRAL_200: string = "#E0E0E0"
export const NEUTRAL_300: string = "#A3A3A3"

export const CONTACT_US_PAGE: string = 'http://чистострана.рф/contact/'

export const BONUS_PROGRAMS: { label: string[]; caption: string[]; image: any; body: string[]; }[] = [
  {
    label: [
      'Каждая вторая',
      'мойка - БЕСПЛАТНА!',
    ],
    caption: ['Программа ЭКОНОМ: "Для нищебродов":)'],
    image: { uri: 'https://russian7.ru/wp-content/uploads/2018/03/44002-768x512.jpg' },
    body: [
      'Помойте свою машину на сумму 200',
      'рублей или более, используя оплату',
      'картой, и получите БЕСПЛАТНУЮ',
      'ночную МОЙКУ!',
    ],
  },
  {
    label: [
      'Получите 100% -',
      'не годовых, а секундных!',
    ],
    caption: ['Программа БИЗНЕС: "Для богатеев":)'],
    image: { uri: 'https://assets.avtocod.ru/storage/images/articles/tnVfaDJBGjosFRVJvchCCqH0Wp5ukNgeqkSu41Sh.jpg' },
    body: [
      'Один бонус равен одному рублю!',
      '',
      'Пополните счёт в нашем приложении',
      'на 400 рублей и получите 50%',
      'бонусами - 200 бонусов!',
      '',
      'Пополните счёт в нашем приложении ',
      'на 1000 рублей и получите 70%',
      'бонусами - 700 бонусов!',
      '',
      'Пополните счёт в нашем приложении ',
      'на 3000 рублей и получите 100%',
      'бонусами - 3000 бонусов!',
    ]
  },
]

export const MONTHS = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

export const MONTHS_NORMALIZE = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const CALENDAR_WEEKS = ["В", "П", "В", "С", "Ч", "П", "С"];

export const PERIOD_TYPES = [
  "За этот день",
  "За эту неделю",
  "За этот месяц",
  "За этот год",
  "Свой период",
];
