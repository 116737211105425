import { useState, useEffect } from 'react'
import { useQuery } from './useQuery'

import { CurrentSessionQuery } from '../utils/queries'
import { CurrentSessionQueryResult, Error, RequestState } from '../utils/types'

export type UseSessionResponse = {
  session: CurrentSessionQueryResult['viewer']['activeSession'];
  state: RequestState;
  fetchSessionState: () => void;
  error: Error;
}

export const useSession = (forceFetch: boolean = false, pause: boolean = false): UseSessionResponse => {
  const [fetch, setFetch] = useState<boolean>(forceFetch)
  const { result, refetchResult, state, error } = useQuery<CurrentSessionQueryResult>({
    query: CurrentSessionQuery,
    requestPolicy: fetch ? 'network-only' : 'cache-first',
    pause
  })

  useEffect(() => {
    if (state !== 'loading')
      setFetch(false)
  }, [state])

  const fetchSessionState = () => {
    refetchResult({ requestPolicy: 'network-only' })
  }

  return {
    session: result.data?.viewer.activeSession,
    state,
    fetchSessionState,
    error,
  }
}
