import React from 'react'
import TextInput from './TextField'
import { usePhoneNumberInput } from '../Hooks/usePhoneNumberInput'

type Props = Omit<React.ComponentProps<typeof TextInput>, "label"> & {
  onPhoneNumberChange?: (newPhoneNumber: string) => void;
}

const PhoneTextField: React.FC<Props> = ({ onPhoneNumberChange, ...rest }) => {
  const { phoneNumber, handlePhoneNumberInput, handlePhoneNumberInputKeyPress } = usePhoneNumberInput(onPhoneNumberChange)

  return (
    <TextInput
      {...rest}
      label='Номер телефона'
      placeholder='+7 (123) 456-78-90'
      onChangeText={handlePhoneNumberInput}
      onKeyPress={handlePhoneNumberInputKeyPress}
      keyboardType='phone-pad'
      value={phoneNumber}
    />
  )
}

export default PhoneTextField
