import React, { useMemo, useCallback } from 'react'
import { StyleSheet, Text, TouchableOpacity, ViewStyle } from 'react-native'
import { PRIMARY_100, PRIMARY_500, PRIMARY_600, WHITE } from '../const'
import BusyIndicator from './BusyIndicator';
import Show from './Show';

const INACTIVE_OPACITY = 0.5

type ButtonProps = {
  text: string;
  onPress?: () => void;
  style?: ViewStyle;
  busy?: boolean;
  variant?: 'filled' | 'tinted';
  isActive?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
}

const Button: React.FC<ButtonProps> = ({ text, onPress, style, busy = false, variant = 'filled', isActive = true, backgroundColor, foregroundColor }) => {
  const BACKGROUND_COLOR = useMemo(() => {
    if (backgroundColor !== undefined) return backgroundColor
    switch (variant) {
      case 'filled':
        return PRIMARY_500
      case 'tinted':
        return PRIMARY_100
    }
  }, [variant, backgroundColor])

  const FOREGROUND_COLOR = useMemo(() => {
    if (foregroundColor !== undefined) return foregroundColor
    switch (variant) {
      case 'filled':
        return WHITE
      case 'tinted':
        return PRIMARY_600
    }
  }, [variant, foregroundColor])

  const pressCallback = useCallback(() => {
    if (isActive && onPress !== undefined)
      onPress()
  }, [isActive, onPress])

  return (
    <TouchableOpacity
      style={[styles.container, { backgroundColor: BACKGROUND_COLOR, opacity: isActive ? 1 : INACTIVE_OPACITY }, style]}
      onPress={pressCallback}
      activeOpacity={isActive ? 0.2 : INACTIVE_OPACITY}
    >
      <Show condition={busy}>
        <BusyIndicator size={24} color={FOREGROUND_COLOR} />
      </Show>
      <Show condition={!busy}>
        <Text style={[styles.text, { color: FOREGROUND_COLOR }]}>
          {text}
        </Text>
      </Show>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
  }
})

export default Button
