import { useState } from 'react'

type UsePhoneNumberReturnValue = {
  phoneNumber: string;
  handlePhoneNumberInput: (input: string) => void;
  handlePhoneNumberInputKeyPress: (e: any) => void;
}

export const usePhoneNumberInput = (
  onPhoneNumberChange?: (newPhoneNumber: string) => void
): UsePhoneNumberReturnValue => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const getInputNumbersValue = (input: string): string => {
    return input.trim().replace(/\D/g, "")
  }

  const handlePhoneNumberInput = (input: string) => {
    let inputNumbersValue = getInputNumbersValue(input)
    let formattedPhoneNumber = ''

    if (inputNumbersValue.length === 0) {
      setPhoneNumber("")
      return
    }

    if (['7', '8', '9'].includes(inputNumbersValue[0])) {
      // Russian number
      if (inputNumbersValue[0] == '9') inputNumbersValue = `7${inputNumbersValue}`
      let firstCharacters = '+7'
      formattedPhoneNumber = firstCharacters + ' '

      if (inputNumbersValue.length > 1) {
        formattedPhoneNumber += '(' + inputNumbersValue.substring(1, 4)
      }
      if (inputNumbersValue.length >= 5) {
        formattedPhoneNumber += ') ' + inputNumbersValue.substring(4, 7)
      }
      if (inputNumbersValue.length >= 8) {
        formattedPhoneNumber += '-' + inputNumbersValue.substring(7, 9)
      }
      if (inputNumbersValue.length >= 10) {
        formattedPhoneNumber += '-' + inputNumbersValue.substring(9, 11)
      }
    } else {
      formattedPhoneNumber = `+${inputNumbersValue.substring(0, 16)}`
    }

    setPhoneNumber(formattedPhoneNumber)
    onPhoneNumberChange && onPhoneNumberChange(formattedPhoneNumber)
  }


  const handlePhoneNumberInputKeyPress = (e: any) => {
    if (e.keyCode == 8 && getInputNumbersValue(e.target.value).length == 1) {
      setPhoneNumber("")
    }
  }

  return { phoneNumber, handlePhoneNumberInput, handlePhoneNumberInputKeyPress }
}
