import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from './useQuery'

import { ViewerQuery } from '../utils/queries'
import { ViewerQueryResult } from '../utils/types'

import { useNavigation } from './navigation'


export const currentlyAuthenticated = (): boolean => {
  return localStorage.getItem('refreshToken') !== null
}


type UseAuthenticationReturnType = {
  authenticated: boolean;
  viewer: ViewerQueryResult['viewer'] | null;
  navigateAuthenticationPageArguments: any;
  updateResult: () => void;
}

export const useAuthentication = (): UseAuthenticationReturnType => {
  const { result, refetchResult } = useQuery<ViewerQueryResult>({ query: ViewerQuery })
  const authenticated = localStorage.getItem('refreshToken') != null
  const location = useLocation()

  const updateResult = () => {
    refetchResult({ requestPolicy: 'network-only' })
  }

  return {
    authenticated,
    viewer: result.data?.viewer || null,
    navigateAuthenticationPageArguments: {
      to: '/authentication/phone_number',
      state: { from: location },
      replace: true,
    },
    updateResult,
  }
}

type UseLogoutReturnType = () => void
export const useLogout = (): UseLogoutReturnType => {
  const [navigateToStack, setNavigateToStack] = useState<boolean>(false)
  const { setCurrentStack } = useNavigation({ navigateAutomatically: navigateToStack })

 return () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    setCurrentStack(['/authentication/phone_number'])
    setNavigateToStack(true)
  }
}

