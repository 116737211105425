import React from 'react'
import { View, Text } from 'react-native'
import Scaffold from '../../Components/Scaffold'
import ListItem from '../../Components/ListItem'
import BusyIndicator from '../../Components/BusyIndicator'

import { PointsListItem, PointsList } from '../../utils/types'
import { useNavigation } from '../../Hooks/navigation'
import { useQuery } from '../../Hooks/useQuery'
import { PointsListQuery } from '../../utils/queries'
import { MobileNavbar } from '../../Components/Navbar'

const PointsListScreen: React.FC = () => {
  const { navigate } = useNavigation()
  const { state, error, result } = useQuery<{ points: PointsList }>({ query: PointsListQuery })

  const handlePressOnPoint = (point: PointsListItem) => {
    navigate(`/employee_home/points/${point.id}`)
  }

  const navTitle = 'Точки'

  if (state === 'loading') {
    return <Scaffold showNavBar={false} gap={32}>
      <MobileNavbar label={navTitle} primaryButton='back' />
      <BusyIndicator />
    </Scaffold>
  }

  if (state === 'error' || result.data == undefined) {
    return <Scaffold showNavBar={false} gap={32}>
      <MobileNavbar label={navTitle} primaryButton='back' />
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text>Произошла ошибка.</Text>
        <Text>{error}</Text>
      </View>
    </Scaffold>
  }

  return <Scaffold showNavBar={false} gap={32}>
    <MobileNavbar label={navTitle} primaryButton='back' />
    {result.data.points.edges.map(({ node }) => {
      return <ListItem
        key={node.id}
        label={`Мойте сами ${node.name.toLowerCase()}`}
        caption={node.address}
        onPress={() => handlePressOnPoint(node)}
      />
    })}
  </Scaffold>
}

export default PointsListScreen
